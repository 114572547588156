import {
  Document, Font, Image, Page, StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import moment from "moment";
import helvetica from "../../../assets/fonts/HelveticaFont/OpenSans-Regular.ttf";
import facebook from "../../../assets/pics/facebook.png";
import instagram from "../../../assets/pics/instagram.png";
import logo from "../../../assets/pics/logo.png";
import mail from "../../../assets/pics/mail.png";
import phoneCall from "../../../assets/pics/phone-call.png";
import smartphone from "../../../assets/pics/smartphone.png";
import web from "../../../assets/pics/web.png";
// import * as React from "react";
// http://fonts.gstatic.com/s/sairacondensed/v11/EJROQgErUN8XuHNEtX81i9TmEkrfpeFE-IyCrw.ttf
Font.register({
  family: "Helvetica",
  format: "truetype",
  src: helvetica,
});
Font.register(
  {
    family: "Open Sans",
    fonts: [
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      },
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
        fontWeight: 600,
      },
    ],
  }
  // {
  //   family: "Font",
  //   fonts: [
  //     {
  //       src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
  //     },
  //   ],
  // }
);

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    margin: 10,
    fontFamily: "Open Sans",
  },
  section: {
    margin: 10,
    padding: 10,
    // flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
  },
  logo: {
    objectFit: "contain",
    width: "120px",
    height: "70px",
  },
  text: {
    fontSize: 10,
  },
  title: {
    paddingVertical: 15,
    fontSize: 14,
  },
  thead: {
    flexDirection: "row",
    width: "80%",
  },
  tbody: {
    flexDirection: "row",
    width: "80%",
  },
  Lcell: {
    flexBasis: "33.33%",
    borderWidth: 1,
    borderRightWidth: 0,
    alignItems: "center",
  },
  Mcell: {
    flexBasis: "33.33%",
    borderWidth: 1,
    borderRightWidth: 0,
    alignItems: "center",
  },
  Rcell: {
    flexBasis: "33.33%",
    borderWidth: 1,
    alignItems: "center",
  },
  CellText: {
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  EmptyBox: {
    width: 15,
    height: 15,
    borderWidth: 1,
    borderRadius: 2,
    marginRight: 5,
  },
  CheckContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexBasis: "33.33%",
  },
});

// Create Document Component
const DecPdf = ({ formik }) => (
  <Document>
    <Page
      size="A4"
      style={styles.page}
      children={<DecContent formik={formik} />}
    />
  </Document>
);

const DecContent = ({ formik }) => {
  if (formik?.values?._id !== "")
    return (
      <>
        <View
          style={{
            alignItems: "center",
          }}
        >
          <Image src={logo} style={styles.logo} />
        </View>
        <Text style={styles.title}>Leopoldstr. 11a 90439 Nürnberg</Text>
        <View style={styles.section}>
          <Text style={{ ...styles.text, fontWeight: "600" }}>
            Kunden-Nr.:
            <Text style={{ fontWeight: 400 }}>
              {formik?.values?.user?.code || " "}
            </Text>
          </Text>
          <Text style={{ ...styles.text, fontWeight: "600" }}>
            Datum:{" "}
            <Text style={{ fontWeight: 400 }}>
              {moment(formik?.values?.createdAt || " ").format("DD/MM/YYYY")}
            </Text>
          </Text>
        </View>
        <TablePdf formik={formik} />
        <View
          style={{
            width: "80%",
            marginTop: "10pt",
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Text
            style={{
              ...styles.text,
              fontWeight: "800",
              fontFamily: "Helvetica",
              // alignSelf: "flex-start",
            }}
          >
            Zollinhaltserklarung (für Privatpersonen)
          </Text>
          <Text
            style={{
              ...styles.text,
              // fontWeight: "600",
              fontFamily: "Helvetica",

              // alignSelf: "flex-start",
            }}
          >
            Déclaration douanière (pour particuliers)
          </Text>
        </View>
        <PriceTable formik={formik} />
        <View style={{ width: "80%", marginTop: "10pt" }}>
          <Text
            style={{
              ...styles.text,
              fontWeight: "600",
              alignSelf: "flex-start",
            }}
          >
            Anzahl abgegebener Pakete/ Sendungen:
            <Text style={{ fontWeight: 400 }}>
              {formik?.values?.numofpackages || " "}
            </Text>
          </Text>
        </View>
        {/* <Checkboxs formik={formik} /> */}
        <RulesTable />
        <View style={{ width: "80%", marginTop: "20pt" }}>
          <Text
            style={{
              fontSize: 10,
              alignSelf: "flex-start",
              fontWeight: 800,
            }}
          >
            Unterschrift des Absenders/ signature de l ́expéditeur:
          </Text>
        </View>
        <SocialMedia />
        <View style={{ width: "80%", marginTop: "15pt" }}>
          <Text style={{ fontSize: 8, alignSelf: "flex-start" }}>
            - Es gelten die AGB´s der KAL&ROK GmbH in ihrer aktuellsten Fassung,
            insbesondere die Beförderungsbedingungen.
          </Text>
        </View>
      </>
    );
  else
    return (
      <View>
        <Text>Pdf Error</Text>
      </View>
    );
};

const SocialMedia = () => {
  return (
    <>
      <View
        style={{
          width: "80%",
          marginTop: "35pt",
          borderTopWidth: 1,
          borderColor: "red",
          paddingTop: "15pt",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            //   justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <View style={styles.CheckContainer}>
            <Image
              src={web}
              style={{
                width: 15,
                height: 15,
                objectFit: "contain",
                margin: 5,
              }}
            />
            <Text style={styles.text}>www.kal-rok.de</Text>
          </View>
          <View style={styles.CheckContainer}>
            <Image
              src={mail}
              style={{
                width: 15,
                height: 15,
                objectFit: "contain",
                margin: 5,
              }}
            />
            <Text style={styles.text}>info@kal-rok.de</Text>
          </View>
          <View style={styles.CheckContainer}>
            <Image
              src={facebook}
              style={{
                width: 15,
                height: 15,
                objectFit: "contain",
                margin: 5,
              }}
            />
            <Text style={styles.text}>kal&Rok GmbH</Text>
          </View>
        </View>
      </View>

      <View style={{ width: "80%", marginTop: "10pt" }}>
        <View
          style={{
            flexDirection: "row",
            //   justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <View style={styles.CheckContainer}>
            <Image
              src={phoneCall}
              style={{
                width: 15,
                height: 15,
                objectFit: "contain",
                margin: 5,
              }}
            />
            <Text style={styles.text}>+49 911 25391310</Text>
          </View>
          <View style={styles.CheckContainer}>
            <Image
              src={smartphone}
              style={{
                width: 15,
                height: 15,
                objectFit: "contain",
                margin: 5,
              }}
            />
            <Text style={styles.text}>+49 162 8020285</Text>
          </View>
          <View style={styles.CheckContainer}>
            <Image
              src={instagram}
              style={{
                width: 15,
                height: 15,
                objectFit: "contain",
                margin: 5,
              }}
            />
            <Text style={styles.text}>@kalrokgmbh</Text>
          </View>
        </View>
      </View>
    </>
  );
};

const PriceTable = ({ formik }) => {
  return (
    <>
      <View style={{ ...styles.thead, marginTop: "15pt" }}>
        <View style={styles.Lcell}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Artikelbezeichnung
          </Text>
        </View>
        <View style={styles.Lcell}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>Anzahl</Text>
        </View>
        <View style={styles.Rcell}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>Wert</Text>
        </View>
      </View>
      {formik?.values?.declarationConten?.map((c, index) => (
        <View style={styles.tbody} key={index}>
          <View style={{ ...styles.Lcell, borderTopWidth: 0 }}>
            <Text style={{ ...styles.CellText }}>{c?.desc || " "}</Text>
          </View>
          <View style={{ ...styles.Mcell, borderTopWidth: 0 }}>
            <Text style={styles.CellText}>{c?.legal || " "}</Text>
          </View>
          <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
            <Text style={styles.CellText}>{c?.notice || " "}</Text>
          </View>
        </View>
      ))}
      {/* <View style={styles.tbody}>
        <View
          style={{
            ...styles.Lcell,
            borderTopWidth: 0,
            flexBasis: "66.66%",
            alignItems: "flex-start",
          }}
        >
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Weitere Berechnung
          </Text>
        </View>
        <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>{formik?.values?.priceSub || " "}</Text>
        </View>
      </View>
      <View style={styles.tbody}>
        <View
          style={{
            ...styles.Lcell,
            borderTopWidth: 0,
            flexBasis: "66.66%",
            alignItems: "flex-start",
          }}
        >
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Gesamtbetrag
          </Text>
        </View>
        <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.priceTotal || " "}
          </Text>
        </View>
      </View> */}
    </>
  );
};
const RulesTable = () => {
  return (
    <>
      <View style={{ ...styles.thead, marginTop: "25pt" }}>
        <View style={{ ...styles.Lcell, flexBasis: "50%" }}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Rechtliche Hinweise
          </Text>
        </View>
        <View style={{ ...styles.Rcell, flexBasis: "50%" }}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Mentions légales
          </Text>
        </View>
      </View>

      <View style={styles.tbody}>
        <View style={{ ...styles.Lcell, borderTopWidth: 0, flexBasis: "50%" }}>
          <Text style={{ ...styles.CellText, textAlign: "left", color: "red" }}>
            Mit meiner Unterschrift bestätige ich, dass
          </Text>
          <Text style={{ ...styles.CellText, textAlign: "left" }}>
            {
              "-die Daten in der vorliegenden Zollinhaltserklärung korrekt sind.  \n-die Sendung keine gefährlichen, gesetzlich oder aufgrund  postalischer oder zollrechtlicher Regelung verbotenen Gegenstande  enthält \n– insbesondere keine Güter, deren Versand, Beförderung oder  Lagerung gemäß den AGB ausgeschlossen ist. \n-der Inhalt der Sendung  mein persönliches und rechtmäßiges Eigentum ist. \n-der Inhaltes  meiner Sendung insgesamt einen Wert von 200 € nicht übersteigt. \n-der  Sendungsinhalt die haushaltsüblichen Mengen nicht überschreitet, nur  für den Eigenbedarf bestimmt ist, und es wird damit keinen Handel  beabsichtigt. Auftragnehmer: KAL&ROK GmbH. Es gelten die AGB der  KAL&ROK GmbH in der zum Zeitpunkt der Einlieferung gültigen Fassung."
            }
          </Text>
        </View>

        <View style={{ ...styles.Rcell, borderTopWidth: 0, flexBasis: "50%" }}>
          <Text style={{ ...styles.CellText, textAlign: "left", color: "red" }}>
            Par ma signature, je certifie en outre:
          </Text>
          <Text style={{ ...styles.CellText, textAlign: "left" }}>
            {
              "- l'exactitude des données de cette déclaration douanière,\n- que mon envoi ne contient aucun objet dangereux, matière interdite ou soumise à règlementation particulière, notamment les objets dont l’expédition, l’acheminement ou l’emmagasinage sont exclus selon des conditions générales,\n- que je suis le propriétaire légitime du contenu de l’envoi, \n- que la valeur de mon envoi est négligeable et reste inferieure à 200 €,\n- que les seuils quantitatifs maximaux sont respectés et que mon envoi est non commercial et est adressé de particulier à particulier, - avoir pris connaissance et approuvé les  Conditions Générales de KAL&ROK GmbH en vigueur lors de l’enregistrement de mon envoi. "
            }
          </Text>
        </View>
      </View>
    </>
  );
};

const TablePdf = ({ formik }) => {
  return (
    <>
      <View style={styles.thead}>
        <View style={{ flexBasis: "33.33%" }} />
        <View style={styles.Lcell}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Absender in DE
          </Text>
        </View>
        <View style={styles.Rcell}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Empfänger in MA
          </Text>
        </View>
      </View>
      <View style={styles.tbody}>
        <View style={{ ...styles.Lcell }}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Vor-u. Nachname
          </Text>
        </View>
        <View style={{ ...styles.Mcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.user?.name || " "}
          </Text>
        </View>
        <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.recipient?.name || " "}
          </Text>
        </View>
      </View>
      <View style={styles.tbody}>
        <View style={{ ...styles.Lcell, borderTopWidth: 0 }}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Telefon-Nr.
          </Text>
        </View>
        <View style={{ ...styles.Mcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.user?.phone || " "}
          </Text>
        </View>
        <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.recipient?.phone || " "}
          </Text>
        </View>
      </View>
      <View style={styles.tbody}>
        <View style={{ ...styles.Lcell, borderTopWidth: 0 }}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>Stadt</Text>
        </View>
        <View style={{ ...styles.Mcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.user?.address || " "}
          </Text>
        </View>
        <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.recipient?.address || " "}
          </Text>
        </View>
      </View>
    </>
  );
};

export default DecPdf;
