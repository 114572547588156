import { Box } from "@mui/material";
import React from "react";
import logo from "../assets/pics/logo.png";


const Loading = () => {
    return (
      <Box
        sx={classes.loadingRoot}
        display={"grid"}
        placeItems="center"
        width="100%"
        height="100vh"
      >
        <img src={logo} alt="logo" style={classes.logo}  className="beat"/>
      </Box>
    );
  };
  
  const classes = {
    logo: {
      width: "clamp(6rem, 50%,15rem)",
    },
    loadingRoot: {
      display: "grid",
      placeItems: "center",
      height: "100vh",
      width: "100vw",
      position:"absolute",
      top:0,
      backgroundColor:"rgb(0,0,0,.2)",
      backdropFilter:"blur(4px)",
      zIndex:"1311"
    },
  };
  
  export default Loading;