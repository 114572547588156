import {
  Close,
  Download,
  EmailOutlined,
  FacebookOutlined,
  Instagram,
  LanguageRounded,
  LocalPhoneOutlined,
  PhoneAndroidOutlined,
  PreviewRounded,
  Save,
  Send,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import { Button, Checkbox, IconButton, Stack, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { PDFDownloadLink, PDFViewer, BlobProvider } from "@react-pdf/renderer";
import { useFormik } from "formik";
import moment from "moment";
import * as React from "react";
import { useNavigate, useParams } from "react-router";
import maxios, { CommandsUrl, MessagesUrl } from "../../../api";
import { StatusContext } from "../../../App";
import logo from "../../../assets/pics/logo.png";
import TicketPdf from "./TicketPdf";
// import TicketPdf from "./TicketPdf";
// import TikTable from "./TikTable";
// Create styles
export const TikContext = React.createContext();
export default function TicketGen() {
  const { dispatch } = React.useContext(StatusContext);
  const [TikData, setTikData] = React.useState({});
  const [preview, setPreview] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let { tik_id } = useParams();

  const defaultvalues = {
    _id: "",
    recipient: {
      name: "",
      phone: "",
      address: "",
    },
    user: {
      _id: "",
      name: "",
      phone: "",
      address: "",
      photo: "no-photo",
      zipcode: "",
      cityId: "",
      Tname: "",
      code: "",
    },
    wights: 0,
    numofpackages: 0,
    history: false,
    finish: false,
    verified: false,
    withTicket: false,
    withBill: false,
    withDeclar: false,
    commandMode: "facturation",
    priceTotal: "",
    priceSub: "",
    type: 1,
    payment: 1,
    createdAt: "",
    billCode: "",
    __v: 0,
  };

  const formik = useFormik({
    initialValues: defaultvalues,
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  const handleChange = (e) => {
    // e.preventDefault();
    formik.handleChange(e);
  };

  // const PushToFormik=()

  const GetTikData = async () => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const res = await maxios.get(`${CommandsUrl}?_id=${tik_id}`);
      if (res?.data?.data[0]) {
        setTikData({ ...res?.data?.data[0] });
        formik.setValues({
          ...defaultvalues,
          ...res?.data?.data[0],
          user: {
            ...res?.data?.data[0].user,
            name: `${res?.data?.data[0].user.firstName} ${res?.data?.data[0].user.lastName}`,
          },
          recipient: {
            ...res?.data?.data[0].recipient,
            name: `${res?.data?.data[0].recipient.firstName} ${res?.data?.data[0].recipient.lastName}`,
          },
        });
      }

      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: "Tik Fetched" });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Tik not Found" });
    }
  };

  const UpdateOrder = async () => {
    try {
      dispatch({ type: "showLoading", payload: true });
      await maxios.put(`${CommandsUrl}/${formik.values._id}`, {
        ...formik.values,
        withTicket: true,
      });
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: `Tik Saved` });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };

  const sendFileMessage = async (blob, id) => {
    try {
      const name = `${TikData.rechnr ?? ""}_${TikData.user.firstName ?? ""}_${
        TikData.user.lastName ?? ""
      }_${moment(TikData.createdAt).format("DD-MM-YYYY") ?? ""}_Ticket.pdf`;
      dispatch({ type: "showLoading", payload: true });
      var file = new File([blob], name, { type: blob.type });
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", file.type);
      formData.append("recieverId", id);
      const send = await maxios.post(MessagesUrl, formData);
      // if (send.data) HandleLivechat({ ...Livechat });
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: `Bill Sent` });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: `Server Error` });
    }
  };

  React.useEffect(() => {
    if (tik_id) GetTikData();
  }, [tik_id]);

  return (
    <TikContext.Provider
      value={{
        preview,
        setPreview,
        formik,
        handleChange,
        UpdateOrder,
        sendFileMessage,
      }}
    >
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Tik />
        </div>
      </Modal>
    </TikContext.Provider>
  );
}

const Tik = () => {
  const {
    preview,
    setPreview,
    formik,
    handleChange,
    UpdateOrder,
    sendFileMessage,
  } = React.useContext(TikContext);
  const navigation = useNavigate();

  return (
    <Box sx={classes.root}>
      {preview ? (
        <PdfPreview />
      ) : (
        <>
          <Stack direction={"column"} spacing={5} alignItems="center">
            <IconButton
              onClick={() => navigation("../")}
              sx={{ alignSelf: "flex-end" }}
            >
              <Close />
            </IconButton>
            <InfosTable />
            {/* <TikTable /> */}
            <Box width={"100%"} display="flex" justifyContent={"center"}>
              <TextField
                variant="outlined"
                label="Anzahl der Pakete/Sendungen"
                sx={{ width: "40%" }}
                name="numofpackages"
                value={formik.values.numofpackages}
                onChange={handleChange}
              />
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            spacing={4}
            justifyContent="flex-end"
            sx={{ mt: 4, mb: 4 }}
          >
            <Button
              onClick={UpdateOrder}
              variant="contained"
              color="success"
              startIcon={<Save />}
            >
              Speichern
            </Button>
            <Button
              variant="contained"
              color="info"
              startIcon={<PreviewRounded />}
              onClick={() => setPreview(true)}
            >
              Vorschau
            </Button>
            <BlobProvider document={<TicketPdf formik={formik} />}>
                {({ blob, url, loading, error }) => (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Send />}
                    onClick={() => sendFileMessage(blob, formik.values.user._id)}
                  >
                    {loading ? "Loading Pdf" : "Senden an Kunden"}
                  </Button>
                )}
              </BlobProvider>
            <PDFDownloadLink
                document={<TicketPdf formik={formik} />}
                fileName={`${formik.values?.rechnr ?? ""}_${
                  formik.values.user.name ?? ""
                }_${
                  moment(formik.values.createdAt).format("DD-MM-YYYY") ?? ""
                }_Ticket.pdf`}
                style={{ textDecoration: "none" }}
              >
                {({ blob, url, loading, error }) => (
                  <Button
                    variant="contained"
                    color="warning"
                    startIcon={<Download />}
                    disabled={loading}
                  >
                    {loading ? "Loading Pdf" : "Download"}
                  </Button>
                )}
              </PDFDownloadLink>
          </Stack>
        </>
      )}
    </Box>
  );
};

const PdfPreview = () => {
  const { setPreview, formik } = React.useContext(TikContext);
  return (
    <Stack
      style={{ width: "100%", height: "100vh" }}
      direction="column"
      justifyContent={"flex-end"}
    >
      <IconButton
        onClick={() => setPreview(false)}
        sx={{ alignSelf: "flex-end" }}
      >
        <Close />
      </IconButton>
      <PDFViewer style={{ width: "100%", height: "95%" }}>
        <TicketPdf formik={formik} />
      </PDFViewer>
    </Stack>
  );
};

const InfosTable = () => {
  const { formik, handleChange } = React.useContext(TikContext);

  return (
    <Stack
      direction={"row"}
      spacing={5}
      justifyContent="space-between"
      width={"50%"}
    >
      <Stack direction={"column"} width={"100%"} spacing={3}>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "sans-serif",
            fontWeight: "bold",
          }}
        >
          Absender in DE
        </Typography>
        <TextField
          id="Vor-u. Nachname"
          label="Vor-u. Nachname"
          variant="outlined"
          sx={{ flexGrow: 1 }}
          value={formik.values.user.name ?? ""}
          onChange={handleChange}
          name="user.name"
        />
        <TextField
          id="Telefon-Nr."
          label="Telefon-Nr."
          variant="outlined"
          sx={{ flexGrow: 1 }}
          value={formik.values.user.phone ?? ""}
          onChange={handleChange}
          name="user.phone"
        />
        <TextField
          id="Stadt"
          label="Stadt"
          variant="outlined"
          sx={{ flexGrow: 1 }}
          value={formik.values.user.address ?? ""}
          onChange={handleChange}
          name="user.address"
        />
      </Stack>
      <Stack direction={"column"} width={"100%"} spacing={3}>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "sans-serif",
            fontWeight: "bold",
          }}
        >
          Empfänger in MA
        </Typography>
        <TextField
          id="Vor-u. Nachname"
          label="Vor-u. Nachname"
          variant="outlined"
          sx={{ flexGrow: 1 }}
          value={formik.values.recipient.name ?? ""}
          onChange={handleChange}
          name="recipient.name"
        />
        <TextField
          id="Telefon-Nr."
          label="Telefon-Nr."
          variant="outlined"
          sx={{ flexGrow: 1 }}
          value={formik.values.recipient.phone ?? ""}
          onChange={handleChange}
          name="recipient.phone"
        />
        <TextField
          id="Stadt"
          label="Stadt"
          variant="outlined"
          sx={{ flexGrow: 1 }}
          value={formik.values.recipient.address ?? ""}
          onChange={handleChange}
          name="recipient.address"
        />
      </Stack>
    </Stack>
  );
};

const classes = {
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    minHeight: "100vh",
    height: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  },
  logo: {
    objectFit: "contain",
    width: 200,
    height: 180,
  },
  title: {
    fontWeight: 300,
  },
};
