import { useEffect, useState } from "react";

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState("");
  const [audioBlob, setAudioBlob] = useState({});
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);

  useEffect(() => {
    try {
      if (recorder === null) {
        if (isRecording) {
          requestRecorder().then(setRecorder, console.error);
        }
        return;
      }
      if (isRecording) {
        recorder && recorder?.start();
      } else {
        recorder && recorder?.stop();
      }
    } catch (error) {
      console.log(error);
      setAudioURL("");
      setAudioBlob({});
    }

    const handleData = (e) => {
      setAudioBlob(e.data);
      setAudioURL(URL.createObjectURL(e.data));
    };

    recorder.addEventListener("dataavailable", handleData);
    return () => recorder.removeEventListener("dataavailable", handleData);
  }, [recorder, isRecording]);

  const deleteRecording = () => {
    setAudioURL("");
    setAudioBlob({});
  };

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  return [
    audioURL,
    audioBlob,
    isRecording,
    startRecording,
    stopRecording,
    deleteRecording,
  ];
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}
export default useRecorder;
