import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import * as React from "react";
import { useNavigate } from "react-router";
import maxios, { CommandsUrl } from "../../../api";
import { LogginContext, StatusContext } from "../../../App";
import DocTable from "./DocTable";
export const DocContext = React.createContext();

export default function Doc() {
  const [DocData, setDocData] = React.useState([]);
  const { dispatch } = React.useContext(StatusContext);
  const { MasterData, setMasterData } = React.useContext(LogginContext);

  const getDoc = async () => {
    try {
      dispatch({ type: "showLoading", payload: true });
      // console.log(init);
      // const axios = init();
      const res = await maxios.get(`${CommandsUrl}?withDeclar=true`);
      setDocData([...res.data.data]);
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: "Doc Fetched" });
    } catch (error) {
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
      dispatch({ type: "showLoading", payload: false });
    }
  };

  const DeleteDoc = async (id) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const res = await maxios.delete(`${CommandsUrl}/${id}`);
      console.log(res);
      setMasterData({
        ...MasterData,
        Doc: [...MasterData.Doc.filter((e) => e._id !== id)],
      });
      dispatch({
        type: "showSuccess",
        payload: `Doc Deleted`,
      });
      dispatch({ type: "showLoading", payload: false });
      // handleClose();
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };

  React.useEffect(() => {
    getDoc();
  }, []);
  const navigate = useNavigate();
  const HandelAdd = () => {
    navigate("addDoc");
  };
  return (
    <DocContext.Provider value={{ DeleteDoc , getDoc}}>
      {/* <AddNsearchButton
        label={"add Rule"}
        func={HandelAdd}
        noSearch={true}
        gridSize={{ ...gridSize, md: 6 }}
      /> */}
      <DocTable Data={DocData} />
      {/* <DocPreview /> */}
      {/* <Routes>
        <Route path=":Doc_id" element={<AddDocDialog />} />
      </Routes> */}
    </DocContext.Provider>
  );
}

const DocPreview = () => {
  return (
    <Stack direction="column" spacing={2}>
      {/* <DocPaper /> */}
    </Stack>
  );
};

const DocPaper = () => {
  const { MasterData } = React.useContext(LogginContext);

  return (
    // <Paper sx={classes.paperroot}>
    MasterData.Doc.map((elem, index) => (
      <Accordion sx={classes.acord} defaultExpanded={index === 0} key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor: "text.lightBg",
            borderRadius: "12px 12px 0 0",
          }}
        >
          <Typography sx={classes.title}>
            {index + 1} - {elem.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={classes.desc}>{elem.description}</Typography>
          <Buttons index={index} id={elem._id} />
        </AccordionDetails>
      </Accordion>
    ))
  );
};

const Buttons = ({ index, id }) => {
  const navigate = useNavigate();
  const { DeleteDoc } = React.useContext(DocContext);
  const IndexingCategorieEdit = () => {
    navigate(`${id}`);
  };
  return (
    <>
      <Box sx={classes.boxadd} onClick={IndexingCategorieEdit}>
        <EditOutlinedIcon sx={classes.add} />
      </Box>
      {/* <Box sx={classes.boxdelete} onClick={() => DeleteDoc(id)}>
        <DeleteOutlineOutlinedIcon sx={classes.add} />
      </Box> */}
    </>
  );
};

const classes = {
  acord: {
    boxShadow:
      "rgb(136 165 191 / 48%) 6px 2px 16px 0px, rgb(255 255 255 / 80%) -6px -2px 16px 0px",
    "&.MuiAccordion-root:before": {
      height: 0,
    },
    "&.MuiAccordion-root": {
      borderRadius: "12px",
    },
  },

  paperroot: {
    p: 2,
    borderRadius: "12px",
    boxShadow:
      "rgb(136 165 191 / 48%) 6px 2px 16px 0px, rgb(255 255 255 / 80%) -6px -2px 16px 0px",
  },
  title: {
    fontFamily: "Cairo",
    fontWeight: "600",
  },
  desc: {
    fontFamily: "Roboto",
    fontWeight: "400",
  },
  moreButton: {
    width: "24px",
    opacity: ".5",
    "&:hover, &:active": {
      opacity: 1,
    },
  },

  add: {
    width: "35px",
    height: "35px",
  },
  boxadd: {
    cursor: "pointer",
    border: "1px dashed",
    p: 1,
    mt: 2,
    opacity: ".3",
    borderRadius: "8px",
    display: "grid",
    transition: "all .2s ease",
    placeItems: "center",
    "&:hover": {
      opacity: "1 !important",
    },
  },
  boxdelete: {
    color: "primary.main",
    cursor: "pointer",
    border: "1px dashed",
    p: 1,
    mt: 2,
    opacity: ".3",
    borderRadius: "8px",
    display: "grid",
    transition: "all .2s ease",
    placeItems: "center",
    "&:hover": {
      opacity: "1 !important",
    },
  },
};

const gridSize = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 6,
  xl: 6,
};
