import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import helvetica from "../../../assets/fonts/HelveticaFont/OpenSans-Regular.ttf";
import facebook from "../../../assets/pics/facebook.png";
import instagram from "../../../assets/pics/instagram.png";
import logo from "../../../assets/pics/logo.png";
import mail from "../../../assets/pics/mail.png";
import phoneCall from "../../../assets/pics/phone-call.png";
import smartphone from "../../../assets/pics/smartphone.png";
import web from "../../../assets/pics/web.png";
// import * as React from "react";
// http://fonts.gstatic.com/s/sairacondensed/v11/EJROQgErUN8XuHNEtX81i9TmEkrfpeFE-IyCrw.ttf
Font.register({
  family: "Helvetica",
  format: "truetype",
  src: helvetica,
});
Font.register(
  {
    family: "Open Sans",
    fonts: [
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      },
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
        fontWeight: 600,
      },
    ],
  }
  // {
  //   family: "Font",
  //   fonts: [
  //     {
  //       src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
  //     },
  //   ],
  // }
);

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    justifyContent:"center",
    // marginTop: 10,
    fontFamily: "Open Sans",
  },
  logo: {
    objectFit: "contain",
    width: "120px",
    height: "70px",
  },
  text: {
    fontSize: 10,
  },
  title: {
    paddingVertical: 15,
    fontSize: 14,
  },
  thead: {
    flexDirection: "row",
    width: "90%",
  },
  tbody: {
    flexDirection: "row",
    width: "90%",
  },
  Lcell: {
    flexBasis: "50%",
  },
  Mcell: {
    flexBasis: "50%",
  },
  Rcell: {
    flexBasis: "50%",
  },
  CellText: {
    padding: 5,
    fontSize: 5,
  },
  EmptyBox: {
    width: 15,
    height: 15,
    borderWidth: 1,
    borderRadius: 2,
    marginRight: 5,
  },
  CheckContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexBasis: "33.33%",
  },
});

// Create Document Component
const TicketPdf = ({ formik }) => (
  <Document>
    <Page
      size={[100, 150]}
      style={styles.page}
      children={<DecContent formik={formik} />}
    />
  </Document>
);

const DecContent = ({ formik }) => {
  if (formik?.values?._id !== "")
    return (
      <>
        <TablePdf formik={formik} />
      </>
    );
  else
    return (
      <View>
        <Text>Pdf Error</Text>
      </View>
    );
};

const TablePdf = ({ formik }) => {
  return (
    <>
      <View
        style={{
          borderWidth: 1,
          borderBottom: 0,
          display: "flex",
          width: "90%",
        }}
      >
        <Text
          style={{
            ...styles.CellText,
            fontWeight: "400",
            alignSelf: "center",
            paddingBottom: "10pt",
          }}
        >
          Destinataire/Empfänger MA
        </Text>
        <View style={styles.thead}>
          <View style={styles.Lcell}>
            <Text style={{ ...styles.CellText, fontWeight: "800" }}>
              {formik?.values?.recipient?.name || " "}
            </Text>
          </View>
          <View style={styles.Rcell}>
            <Text style={{ ...styles.CellText, fontWeight: "800" }}>
              {formik?.values?.recipient?.phone || " "}
            </Text>
          </View>
        </View>
        <View style={styles.tbody}>
          <View style={{ ...styles.Mcell, borderTopWidth: 0 }}>
            <Text style={styles.CellText}>
              {formik?.values?.numofpackages || "0"} colis
            </Text>
          </View>
          <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
            <Text style={styles.CellText}>
              {formik?.values?.recipient?.address || " "}
            </Text>
          </View>
        </View>
      </View>

      <View style={{ borderWidth: 1 }}>
        <Text
          style={{
            ...styles.CellText,
            fontWeight: "400",
            alignSelf: "center",
            paddingBottom: "10pt",
          }}
        >
          Expéditeur/Absender DE
        </Text>
        <View style={styles.thead}>
          <View style={styles.Lcell}>
            <Text style={{ ...styles.CellText, fontWeight: "800" }}>
              {formik?.values?.user?.name || " "}
            </Text>
          </View>
          <View style={styles.Rcell}>
            <Text style={{ ...styles.CellText, fontWeight: "800" }}>
              {formik?.values?.user?.phone || " "}
            </Text>
          </View>
        </View>
        <View style={styles.tbody}>
          <View style={{ ...styles.Mcell, borderTopWidth: 0 }}>
            <Text style={styles.CellText}>
              {formik?.values?.numofpackages || "0"} Sendungen
            </Text>
          </View>
          <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
            <Text style={styles.CellText}>
              {formik?.values?.user?.address || " "}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

export default TicketPdf;
