import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Button,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import maxios, { ReqPwdUrl, ResetPswdUrl } from "../api";
import { StatusContext } from "../App";
import letter from "../assets/pics/mailbox.svg";
import { LoginStyledField } from "./LoginForm";
const PwdContext = createContext({});
export default function ForgotPassword(props) {
  const [steps, setsteps] = useState(1);
  const [Code, setCode] = useState("");
  const [pwd, setpwd] = useState("");
  const [rpwd, setrpwd] = useState("");
  const [phone, setPhone] = useState("+49");
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { dispatch } = useContext(StatusContext);
  const ReqPasswordRecovery = async () => {
    try {
      dispatch({ type: "showLoading", payload: true });

      const res = await maxios.post(ReqPwdUrl, {
        phone: phone,
      });
      dispatch({ type: "showLoading", payload: false });
      if (res?.data) setsteps(2);
      else dispatch({ type: "showError", payload: "No User with this Phone" });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });

      dispatch({ type: "showError", payload: "Server Error" });
    }
  };

  const Close = () => {
    props.setFdialog(false);
    setsteps(1);
  };

  const verifyCode = async () => {
    try {
      dispatch({ type: "showLoading", payload: true });

      const res = await maxios.post(`${ResetPswdUrl}/${Code}`, {
        password: pwd,
      });
      dispatch({ type: "showLoading", payload: false });

      if (res?.data) {
        Close();

        dispatch({ type: "showSuccess", payload: "Password Changed" });
      } else
        dispatch({ type: "showError", payload: "Check Verification Code" });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      console.log(error);
      dispatch({ type: "showError", payload: "Server  Error" });
    }
  };

  return (
    <PwdContext.Provider
      value={{
        Code,
        setCode,
        pwd,
        setpwd,
        rpwd,
        setrpwd,
        phone,
        setPhone,
        ReqPasswordRecovery,
        verifyCode,
      }}
    >
      <Dialog
        open={props.open}
        maxWidth="xs"
        fullScreen={matches}
        //   sx={{ "& .MuiDialog-paper": { minHeight: "90vh" } }}
      >
        <Header
          steps={steps}
          setsteps={setsteps}
          setFdialog={props.setFdialog}
        />
        <Slide
          in={steps === 1}
          direction="right"
          mountOnEnter
          unmountOnExit
          timeout={{ appear: 1000, enter: 1000, exit: 0 }}
        >
          <div>
            <EnterNum setsteps={setsteps} />
          </div>
        </Slide>
        <Slide
          in={steps === 2}
          direction="left"
          mountOnEnter
          unmountOnExit
          timeout={{ appear: 1000, enter: 1000, exit: 0 }}
        >
          <div>
            <VerifyCode setsteps={setsteps} />
          </div>
        </Slide>
        <Slide
          in={steps === 3}
          direction="left"
          mountOnEnter
          unmountOnExit
          timeout={{ appear: 1000, enter: 1000, exit: 0 }}
        >
          <div>
            <EditPwd setsteps={setsteps} />
          </div>
        </Slide>
      </Dialog>
    </PwdContext.Provider>
  );
}

const Header = (props) => {
  const Back = () => {
    props.setsteps(props.steps - 1);
  };
  const Close = () => {
    props.setFdialog(false);
    props.setsteps(1);
  };
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ py: 1, px: 1 }}
      justifyContent={"space-between"}
    >
      {props.steps > 1 ? (
        <IconButton onClick={Back}>
          <ArrowBackRoundedIcon />
        </IconButton>
      ) : (
        false
      )}
      <IconButton sx={{ marginLeft: "auto" }} onClick={Close}>
        <CancelOutlinedIcon />
      </IconButton>
    </Stack>
  );
};

const VerifyCode = ({ setsteps }) => {
  const { Code, setCode } = useContext(PwdContext);
  return (
    <Stack direction="column" sx={{ p: 2 }} spacing={4} alignItems="center">
      <Typography sx={classes.title}>Check Your Phone</Typography>
      <img src={letter} style={classes.img} alt="letter" />
      <Typography sx={classes.desc}>
        Check your phone We have sent a password recover Code to your messages.
      </Typography>
      <ReactInputVerificationCode length={6} value={Code} onChange={setCode} />
      <Button
        onClick={() => setsteps(3)}
        fullWidth
        variant="contained"
        sx={{
          height: "45px",
          borderRadius: "10px",
        }}
      >
        Verify
      </Button>
    </Stack>
  );
};

const EditPwd = ({ setsteps }) => {
  const { pwd, setpwd, rpwd, setrpwd, verifyCode } = useContext(PwdContext);

  const handlePwd = (e) => {
    setpwd(e.target.value);
  };
  const handleRPwd = (e) => {
    setrpwd(e.target.value);
  };

  return (
    <Stack direction="column" sx={{ p: 2 }} spacing={4}>
      <Stack direction="column" spacing={0}>
        <Typography sx={classes.title}>Create new password</Typography>
        <Typography sx={classes.desc}>
          Your new password must be different from previous used passwords.
          Password
        </Typography>
      </Stack>
      <LoginStyledField
        value={pwd}
        onChange={handlePwd}
        name="password"
        label="Password"
        type={"password"}
        helperText="Must be at least 8 characters"
      />
      <LoginStyledField
        value={rpwd}
        onChange={handleRPwd}
        name="rpassword"
        label="Confirm Password"
        type={"password"}
        helperText="Both passwords must match. "
      />
      <Button
        fullWidth
        variant="contained"
        sx={{
          height: "45px",
          borderRadius: "10px",
        }}
        onClick={verifyCode}
      >
        Reset Password
      </Button>
    </Stack>
  );
};

const EnterNum = ({ setsteps }) => {
  const { phone, setPhone, ReqPasswordRecovery } = useContext(PwdContext);

  const CheckForNumbers = (e) => {
    const re = /^[+\d](?:.*\d)?$/;
    if (e.target.value === "" || re.test(e.target.value))
      setPhone(e.target.value);
  };

  return (
    <Stack direction="column" sx={{ p: 2 }} spacing={4}>
      <Typography sx={classes.title}>Reset password</Typography>
      <Typography sx={classes.desc}>
        Enter the email associated with your account and well send an email with
        instructions to reset your password. Email address
      </Typography>
      <LoginStyledField
        value={phone}
        onChange={CheckForNumbers}
        name="phone"
        label="Phone Number"
      />
      <Button
        fullWidth
        variant="contained"
        sx={{
          height: "45px",
          borderRadius: "10px",
        }}
        onClick={ReqPasswordRecovery}
      >
        Send
      </Button>
    </Stack>
  );
};

const classes = {
  title: {
    fontSize: "1.5rem",
    fontFamily: "Roboto",
    fontWeight: 900,
    mb: 1,
  },
  desc: {
    fontFamily: "Cairo",
    fontWeight: 400,
    mb: 2,
  },
  img: {
    width: "50%",
    objectFit: "cover",
    // height:"30px"
  },
};
