import { DeleteOutlineRounded } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, Stack, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useContext } from "react";
import { BillContext } from "./BillGenerator";
export default function PriceTable() {
  // const [rowsInputs, setrowsInputs] = React.useState([]);
  const { formik, handleChange } = useContext(BillContext);

  // React.useEffect(() => {
  //   setrowsInputs([...rows]);
  // }, []);

  const AddRow = () => {
    formik.setFieldValue("content", [
      ...formik.values.content,
      { desc: "", qty: "", price: "" },
    ]);
  };
  const DeleteRow = (rindex) => {
    const res = formik.values.content.filter((e, index) => index !== rindex);
    formik.setFieldValue("content", [...(res ?? [])]);
  };

  return (
    <Table sx={{ minWidth: 500, width: "80%" }} aria-label="spanning table">
      <TableHead>
        <TableRow sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}>
          <TableCell sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>
            Sendung(en)
          </TableCell>
          <TableCell align="left">Gewicht in Kg</TableCell>
          <TableCell
            align="left"
            sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
          >
            Betrag
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <RowsMap AddRow={AddRow} DeleteRow={DeleteRow} />
        <TableRow>
          <TableCell rowSpan={3} sx={{ borderBottom: "0" }} />
          <TableCell
            colSpan={1}
            sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
          >
            Weitere Berechnung
          </TableCell>
          <TableCell
            align="left"
            sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
          >
            <TextField
              variant="standard"
              value={formik.values?.priceSub}
              name="priceSub"
              onChange={handleChange}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={1}
            sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
          >
            Gesamtbetrag
          </TableCell>
          <TableCell
            align="left"
            sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
          >
            <TextField
              variant="standard"
              value={formik.values.priceTotal}
              name="priceTotal"
              onChange={handleChange}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

const RowsMap = ({ AddRow, DeleteRow }) => {
  const { formik, handleChange } = useContext(BillContext);
  return formik.values?.content?.map((row, index) => (
    //   <Stack direction={"row"} sx={{width:"100%"}}>
    <TableRow key={index} sx={{ flexGrow: 1 }}>
      <TableCell sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>
        <TextField
          variant="standard"
          value={formik.values.content[index].desc}
          name={`content[${index}].desc`}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell align="left">
        <TextField
          variant="standard"
          value={formik.values.content[index].qty}
          name={`content[${index}].qty`}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell
        align="left"
        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
      >
        <TextField
          variant="standard"
          value={formik.values.content[index].price}
          name={`content[${index}].price`}
          onChange={handleChange}
        />
      </TableCell>

      {/* <TableCell align="left" sx={{ border: "none" }}> */}
      <Stack
        direction={"row"}
        sx={{ position: "absolute", marginLeft: 2 }}
        spacing={2}
      >
        {index < formik.values.content.length - 1 ? (
          false
        ) : (
          <IconButton color="success" onClick={AddRow}>
            <AddIcon />
          </IconButton>
        )}
        {formik.values.content.length === 1 ? (
          false
        ) : (
          <IconButton color="primary" onClick={() => DeleteRow(index)}>
            <DeleteOutlineRounded />
          </IconButton>
        )}
      </Stack>
      {/* </TableCell> */}
    </TableRow>
  ));
};
