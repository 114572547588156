import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import moment from "moment";
import "moment/locale/de";
import React, { useEffect } from "react";
import { BaseUrl, FilesUrl } from "../../../api";
import { Gifschat } from "../../../assets/MasterData";
import pdf from "../../../assets/pics/pdf.png";
const Types = {
  image: "image",
  text: "text",
  pdf: "application/pdf",
  video: "video",
  audio: "audio",
  gif: "gif",
  link: "link",
};
export default function MessageHandler({ message, isRight }) {
  useEffect(() => {
    moment.locale("de");
  }, []);

  function forceDownload(blob, filename) {
    var a = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  function downloadResource(url, filename) {
    if (!filename) filename = url.split("\\").pop().split("/").pop();
    fetch(url, {
      // headers: new Headers({
      //   'Origin': location.origin
      // }),
      // mode: 'cors'
    })
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        forceDownload(blobUrl, filename);
      })
      .catch((e) => console.error(e));
  }
  switch (message.message.type) {
    case Types.image:
      return (
        <Box sx={isRight ? classes.imgLContainer : classes.imgRContainer}>
          <Box
            component={"img"}
            sx={classes.image}
            src={`${BaseUrl}${FilesUrl}/${message.message.content}`}
            crossOrigin="anonymous"
          />
          <Box
            sx={classes.hoverClick}
            component="a"
            onClick={() =>
              downloadResource(
                `${BaseUrl}${FilesUrl}/${message.message.content}`,
                message.message.content
              )
            }
          >
            <UploadFileIcon
              sx={{ color: "rgba(255,255,255,.8)", fontSize: "150%" }}
            />
          </Box>
          <Typography sx={classes.Time} variant="span">
            {moment(message?.createdAt).calendar()}
          </Typography>
        </Box>
      );
    case Types.pdf:
      return (
        <Box sx={isRight ? classes.imgLContainer : classes.imgRContainer}>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <IconButton
              onClick={() =>
                downloadResource(
                  `${BaseUrl}${FilesUrl}/${message.message.content}`,
                  message.message.content
                )
              }
            >
              <img src={pdf} style={{ objectFit: "contain", width: "68px" }} />
            </IconButton>
          </Stack>
          <Typography  variant="span">
            {message.message.content}
          </Typography>
          <Typography sx={classes.Time} variant="span">
            {moment(message?.createdAt).calendar()}
          </Typography>
        </Box>
      );
    case Types.video:
      return (
        <Box sx={isRight ? classes.imgLContainer : classes.imgRContainer}>
          <Box
            component={"video"}
            sx={classes.image}
            crossOrigin="anonymous"
            controls
          >
            <source
              src={`${BaseUrl}${FilesUrl}/${message.message.content}`}
              type="video/mp4"
              crossOrigin="anonymous"
            ></source>
          </Box>
          <Typography sx={classes.Time} variant="span">
            {moment(message?.createdAt).calendar()}
          </Typography>
        </Box>
      );
    case Types.audio:
      return (
        <Box sx={isRight ? classes.imgLContainer : classes.imgRContainer}>
          <audio
            src={`${BaseUrl}${FilesUrl}/${message.message.content}`}
            controls
            crossOrigin="anonymous"
            style={{ flexGrow: 1, marginBottom: "16px" }}
          />

          <Typography sx={classes.Time} variant="span">
            {moment(message?.createdAt).calendar()}
          </Typography>
        </Box>
      );
    case Types.gif:
      return (
        <Box sx={isRight ? classes.imgLContainer : classes.imgRContainer}>
          <Box
            component={"img"}
            src={
              Gifschat.filter((e) => e.name === message?.message?.content)?.[0]
                ?.source
            }
            sx={{
              width: 100,
              height: 100,
              objectFit: "contain",
            }}
          />
          <Typography sx={classes.Time} variant="span">
            {moment(message?.createdAt).calendar()}
          </Typography>
        </Box>
      );
    case Types.link: {
      const link =
        message?.message?.content.includes("http://") ||
        message?.message?.content.includes("https://")
          ? message?.message?.content
          : `http://${message?.message?.content}`;
      return (
        <Typography
          sx={isRight ? classes.ChatMessageLeft : classes.ChatMessageRight}
        >
          <a href={link} target="_blank">
            {message?.message?.content}
          </a>
          <Typography sx={classes.Time} variant="span">
            {moment(message?.createdAt).calendar()}
          </Typography>
        </Typography>
      );
    }
    default:
      return (
        <Typography
          sx={isRight ? classes.ChatMessageLeft : classes.ChatMessageRight}
        >
          {message?.message?.content}
          <Typography sx={classes.Time} variant="span">
            {moment(message?.createdAt).calendar()}
          </Typography>
        </Typography>
      );
  }
}

const classes = {
  Time: {
    fontSize: ".8rem",
    alignSelf: "flex-end",
    paddingTop: "8px",
    opacity: ".6",
  },
  hoverClick: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: "#0000004a",
    margin: "8px",
    marginBottom: "32px",
    display: "grid",
    placeItems: "center",
    transition: "all ease 200ms",
    cursor: "pointer",
    opacity: 0,
    "&:hover": {
      opacity: 1,
    },
  },
  image: {
    objectFit: "contain",
    width: "100%",
    paddingBottom: "16px",
  },
  imgLContainer: {
    maxWidth: "374px",
    padding: "8px",
    backgroundColor: "#F6F7F9",
    borderRadius: "8px",
    alignSelf: "baseline",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginLeft: "15px !important",
    marginBottom: "8px !important",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    "&:after": {
      content: "''",
      position: "absolute",
      left: "0",
      top: "0",
      width: "0",
      height: "0",
      border: "20px solid transparent",
      borderRightColor: "#F6F7F9",
      borderLeft: "0",
      borderTop: "0",
      marginTop: "0",
      marginLeft: "-15px",
    },
  },
  imgRContainer: {
    marginBottom: "8px !important",
    maxWidth: "374px",
    padding: "8px",
    backgroundColor: "#dcf8c6",
    color: "#000000",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginLeft: "2px !important",
    marginRight: "15px !important",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    borderRadius: "8px",
    "&:after": {
      content: "''",
      position: "absolute",
      right: "0",
      top: "0",
      width: "0",
      height: "0",
      border: "20px solid transparent",
      borderLeftColor: "#dcf8c6",
      borderRight: "0",
      borderTop: "0",
      marginRight: "-12px",
    },
  },
  ChatMessageLeft: {
    fontFamily: "Helvetica",
    maxWidth: "374px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    // paddingRight: "80px",
    // paddingBottom:"30px",
    backgroundColor: "#F6F7F9",
    color: "#000000",
    borderRadius: "8px",
    // lineBreak: "anywhere",
    alignSelf: "baseline",
    position: "relative",
    marginLeft: "15px !important",
    marginBottom: "8px !important",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    "&:after": {
      content: "''",
      position: "absolute",
      left: "0",
      top: "0",
      width: "0",
      height: "0",
      border: "20px solid transparent",
      borderRightColor: "#F6F7F9",
      borderLeft: "0",
      borderTop: "0",
      marginTop: "0",
      marginLeft: "-15px",
    },
  },
  ChatMessageRight: {
    fontFamily: "Helvetica",
    fontSize: "1rem",
    // lineBreak: "anywhere",

    marginBottom: "8px !important",
    maxWidth: "374px",
    padding: "8px",
    // paddingRight: "80px",
    backgroundColor: "#dcf8c6",
    color: "#000000",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    // marginLeft: "auto !important",
    // paddingBottom:"30px",
    marginRight: "15px !important",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    borderRadius: "8px",
    "&:after": {
      content: "''",
      position: "absolute",
      right: "0",
      top: "0",
      width: "0",
      height: "0",
      border: "20px solid transparent",
      borderLeftColor: "#dcf8c6",
      borderRight: "0",
      borderTop: "0",
      marginRight: "-12px",
    },
  },
};
