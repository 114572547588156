import { Stack } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import maxios, { CommandsUrl } from "../../api";
import { StatusContext } from "../../App";
import { CreateGroupButton } from "../Routes/Groups/GroupsRoot";
import TableOrders from "../Routes/Groups/TableOrders";
import BillChecker from "./BillChecker";

import { Route, Routes, useNavigate } from "react-router";
import MsgsDialog from "../Routes/Users/MsgsDialog";

export const GroupAContext = createContext();
export default function ToDu({ Switchgroups }) {
  const { dispatch } = useContext(StatusContext);
  const [GroupData, setGroupAData] = useState([]);
  const [isSwitched, setisSwitched] = useState(false);
  const [CreateGroupStatus, setCreateGroup] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const navigate = useNavigate();
  const fetchGroupA = async () => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const res = await maxios.get(`/api/v1/trips?name=B`);
      if (res.data.count > 0) {
        // const id = res.data.data[0]._id;
        const cmds = await maxios.get(`/api/v1/commands?tripGroup=B`);
        setGroupAData([...cmds.data.data]);
        dispatch({ type: "showLoading", payload: false });
        dispatch({
          type: "showSuccess",
          payload: "CommandsTo Morocco Fetched",
        });
      } else {
        dispatch({ type: "showLoading", payload: false });
        dispatch({ type: "showSuccess", payload: "Create Group" });
        setCreateGroup(true);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };

  const DeleteCommands = async (users) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      await users.map(async (e) => {
        await maxios.delete(`${CommandsUrl}/${e}`);
      });
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: `Commands Deleted` });
      setisSwitched(!isSwitched);
    } catch (error) {
      console.log(error);
      setisSwitched(!isSwitched);
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };
  const openChat = () => {
    navigate("sendMessages");
  };
  React.useEffect(() => {
    const fetch = async () => {
      await fetchGroupA();
    };
    fetch();
  }, [CreateGroupStatus, isSwitched]);

  return (
    <GroupAContext.Provider value={{ GroupData, setGroupAData }}>
      {CreateGroupStatus === true ? (
        <CreateGroupButton name="B" setCreateGroup={setCreateGroup} />
      ) : (
        <>
          <Stack direction="column" spacing={3}>
            {/* <TableTimeLine Context={GroupAContext} /> */}
            <TableOrders
              Context={GroupAContext}
              Data={GroupData}
              DeleteCommands={DeleteCommands}
              label={{ label: "From Morocco", type: "ToDu" }}
              gname={"A"}
              toname={"B"}
              selected={selected}
              setSelected={setSelected}
              openChat={openChat}
            />
          </Stack>
          <Routes>
            <Route
              path="/cin/:cin_id"
              element={<BillChecker Data={GroupData} />}
            />
             <Route
              path="/sendMessages"
              element={
                <MsgsDialog selected={selected} userStates={GroupData} />
              }
            />
          </Routes>
        </>
      )}
    </GroupAContext.Provider>
  );
}
