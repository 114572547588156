import React from "react";
// import { styled } from "@mui/system";
import { styled } from "@mui/material/styles";

import { TextField, Stack, Grid, Button, IconButton } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Switch from "@mui/material/Switch";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export const StextField = styled(TextField)({
  "& fieldset": {
    borderRadius: "12px",
  },
});

export const SearchField = styled(TextField)(({ theme }) => ({
  borderRadius: "12px",
  backgroundColor: "#FFFFFF",
  "& fieldset": {
    borderRadius: "12px",
    borderColor: `rgba(99, 115, 129, 0.5) !important`,
  },
  "& .MuiOutlinedInput-root:hover > fieldset": {
    borderColor: `rgba(99, 115, 129, 1) !important`,
  },
}));

export const AddNsearchButton = ({
  label,
  func,
  gridSize,
  noSearch,
  OnchangeFunc,
}) => {
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{ marginBottom: 4, position: "relative", minHeight: "56px" }}
    >
      {noSearch ? (
        false
      ) : (
        <SearchStack gridSize={gridSize} OnchangeFunc={OnchangeFunc} />
      )}
      <Button
        variant="contained"
        startIcon={<AddRoundedIcon />}
        sx={classes.addButton}
        onClick={func}
      >
        {label}
      </Button>
    </Stack>
  );
};

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 22 / 2,
  flexGrow: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 22 / 2,
    backgroundColor: "#faaf00",
  },
}));

export const SearchStack = ({ gridSize, OnchangeFunc }) => {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={gridSize?.xs}
        md={gridSize?.md}
        lg={gridSize?.lg}
        xl={gridSize?.xl}
      >
        <SearchField
          fullWidth
          onChange={OnchangeFunc ? OnchangeFunc : () => {}}
          placeholder="Search"
          InputProps={{
            endAdornment: (
              <IconButton position="end">
                <SearchRoundedIcon sx={{ color: "text.primary" }} />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export const Android12Switch = styled(Switch)(({ theme, checked }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
    ...(checked && {
      backgroundColor: "#43c54a6e !important",
      opacity: "1 !important",
    }),
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const classes = {
  addButton: {
    width: { xs: "100%", md: "auto" },
    borderRadius: "12px",
    color: "#FFFFFF",
    borderColor: "text.primary",
    "&:hover": {
      backgroundColor: "text.buttonBg",
    },
    backgroundColor: "text.primary",
    position: { xs: "relative", md: "absolute" },
    right: "0",
    height: "56px",
  },
};
