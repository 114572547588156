import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";
import { useMemo, useState } from "react";
export const ConfirmationDialog = (props) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [text, setText] = useState("");
  const canSubmit = useMemo(() => {
    return !props.payload.field?.required || text?.length > 0;
  }, [text, props.payload.field]);

  const color =
    props.payload.type === "negative"
      ? "error.main"
      : props.payload.type === "info"
      ? "info.main"
      : "success.main";
  const Bcolor =
    props.payload.type === "negative"
      ? "error"
      : props.payload.type === "info"
      ? "info"
      : "success";

  const handleSubmit = () => {
    if (canSubmit) {
      const enteredText = text;
      props.handleClose();
      props.payload.callback(enteredText);
      setText("");
    }
  };

  const handleClose = () => {
    props.handleClose();
    setText("");
  };
  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      sx={{
        "&.MuiDialog-root": {
          backdropFilter: "blur(1px)",
        },
      }}
      fullScreen={matches}
    >
      <DialogTitle id="alert-dialog-title" sx={{ paddingTop: 0 }}>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={classes.title}>{props.payload.title}</Typography>
          </Box>
          <Box style={classes.validbox} sx={{ backgroundColor: color }}>
            {props.payload.icon ?? <HandlIcons status={props.payload.type} />}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={classes.body} id="alert-dialog-description">
          {props.payload.message}
        </DialogContentText>
        {!!props.payload.field ? (
          <TextField
            multiline
            margin="dense"
            id="text"
            label={props.payload?.field?.label ?? "label"}
            value={text}
            onChange={(e) => setText(e.target.value)}
            error={!canSubmit}
            fullWidth
            variant="standard"
            color="secondary"
          />
        ) : (
          false
        )}
      </DialogContent>
      <DialogActions>
        <Box
          sx={{ display: "flex", alignContent: "flex-end" }}
          marginLeft={0}
          padding={1}
        >
          <Button
            variant="contained"
            style={classes.Cancel}
            onClick={handleClose}
            fullWidth
          >
            Annuler
          </Button>
          <Button
            autoFocus
            fullWidth
            disabled={!canSubmit}
            variant="contained"
            color={Bcolor}
            style={{ height: " 48px", width: "150px" }}
            onClick={handleSubmit}
          >
            {props.payload.buttonLabel ?? "Valider"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const HandlIcons = ({ status }) => {
  if (status === "negative")
    return <HighlightOffRoundedIcon sx={classes.icon} />;
  else if (status === "info") return <InfoOutlinedIcon sx={classes.icon} />;
  else return <CheckCircleOutlineRoundedIcon sx={classes.icon} />;
};

const classes = {
  title: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "24px",
    textAlign: "left",
    alignItems: "center",
  },
  icon: { fontSize: "2.5rem", color: "white.main" },
  validbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "61px",
    width: "49.91px",
    borderRadius: "0 0 4px 4px",
  },
  body: {
    color: "#9EA4B3",
    fontFamily: "Roboto",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "19px",
    textAlign: "left",
  },
  Cancel: {
    height: "48px",
    width: "130px",
    borderRadius: "4px",
    backgroundColor: "#F1F3F8",
    color: "#9EA4B3",
    marginRight: "12px",
  },
};
