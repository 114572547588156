import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Paper, Stack } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import step1 from "../../../assets/pics/agency.svg";
import step2 from "../../../assets/pics/loadingup.svg";
import step3 from "../../../assets/pics/spain.svg";
import step4 from "../../../assets/pics/france.svg";
import step5 from "../../../assets/pics/costum.svg";
import step6 from "../../../assets/pics/Marok.svg";
import step7 from "../../../assets/pics/Delivred.svg";
import SvgIcon from "@mui/material/SvgIcon";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import axios, { GroupsUrl } from "../../../api";
import { StatusContext } from "../../../App";

export default function TableTimeLine({ Context }) {
  const { GroupData, setGroupAData, setisSwitched, isSwitched } =
    React.useContext(Context);
  const [activeStep, setActiveStep] = React.useState(0);
  const [TripData, setTripData] = React.useState({});
  const { dispatch } = React.useContext(StatusContext);

  const GetTrip = async () => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const trip = await axios.get(`/api/v1/trips/A`);
      setTripData({ ...trip.data.data[0] });
      setActiveStep(trip.data.data[0].status);
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: "Tracking Updated" });
      // setGroupAData({ ...res.data.data, commands: [...cmds.data.data] });
    } catch (error) {
      console.log(error);
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };

  React.useEffect(() => {
    GetTrip();
  }, []);

  const UpdateStep = async (data) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const res = await axios.put(`${GroupsUrl}/${TripData._id}`, data);
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: "Tracking Updated" });
      // setGroupAData({ ...res.data.data, commands: [...cmds.data.data] });
      setActiveStep(data.status);
    } catch (error) {
      console.log(error);
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };

  const GoToStep = (step) => {
    dispatch({
      type: "showDialog",
      payload: {
        title: "Update Tracking ?",
        message: ``,
        type: "positive",
        buttonLabel: "Update",
        callback: () => UpdateStep({ status: step }),
      },
    });
  };

  const FisnishTracking = (step) => {
    dispatch({
      type: "showDialog",
      payload: {
        title: "Finish Tracking ?",
        message: `By clicking Finish all Orders will be Deleted from this Group`,
        type: "positive",
        buttonLabel: "Finish",
        callback: () => {
          UpdateStep({ finish: true, status: 7 });
          setisSwitched(!isSwitched);
        },
      },
    });
  };

  const handleReset = () => {
    dispatch({
      type: "showDialog",
      payload: {
        title: "Restart Tracking ?",
        message: `By clicking Restart Tracking Will return to parcel deposit`,
        type: "positive",
        buttonLabel: "Restart",
        callback: () => UpdateStep({ finish: false, status: 0 }),
      },
    });

    // setActiveStep(0);
  };

  return (
    <Paper sx={classes.paper}>
      <Typography paragraph sx={classes.Title}>
        Tracking
      </Typography>
      <Stepper
        activeStep={activeStep}
        connector={<Connector />}
        alternativeLabel
      >
        {steps.map((item, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step {...stepProps} key={index}>
              <StepLabel
                sx={{ height: "10rem", justifyContent: "space-between" }}
                {...labelProps}
                StepIconComponent={StepHandler}
                StepIconProps={{
                  index: index,
                  item: item,
                  GoToStep: GoToStep,
                }}
              >
                <Stack
                  direction="column"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <span>{item.label}</span>
                  <HandlingIcons activeStep={activeStep} index={index} />
                </Stack>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Stack
        direction="row"
        justifyContent={{ xs: "flex-start", md: "flex-end" }}
        spacing={2}
        sx={{ pt: 3 }}
      >
        <Button onClick={handleReset} sx={classes.restartButton}>
          Restart
        </Button>
        <Button onClick={() => FisnishTracking(7)} sx={classes.finishButton}>
          Finish
        </Button>
      </Stack>
    </Paper>
  );
}

const HandlingIcons = ({ activeStep, index }) => {
  if (activeStep < index) return <HourglassEmptyRoundedIcon />;
  else if (activeStep === index) return <HourglassBottomRoundedIcon />;
  else if (activeStep > steps.length - 1)
    return <DoneAllRoundedIcon sx={{ color: "primary.main" }} />;
  else if (activeStep > index)
    return <DoneRoundedIcon sx={{ color: "primary.main" }} />;
  else return false;
};

const StepHandler = ({ item, index, active, completed, GoToStep }) => {
  return (
    <Box sx={classes.stepsBox}>
      <IconImg
        src={item.icon}
        active={active ? "true" : undefined}
        completed={completed ? "true" : undefined}
        onClick={() => GoToStep(index)}
        m={item.m}
      />
      <SvgIcon
        sx={{
          ...classes.floor,
          top: item.t,
          ...(active && {
            transform: "scale(1.2)",
            fill: "#FF56564f",
          }),
          ...(completed && {
            fill: "#FF5656",
          }),
        }}
        viewBox={item.viewBox}
      >
        {item.floor}
      </SvgIcon>
    </Box>
  );
};

const IconImg = styled("img")(({ theme, active, completed, m }) => ({
  width: "6em",
  height: "6em",
  marginTop: m,
  cursor: "pointer",
  backgroundColor: "transparent",
  transition: "all .1s ease",
  ...(active && {
    transform: "scale(1.2)",
  }),
}));

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: "2em",
    zIndex: 1,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.bg,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.primary.lightBg,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const classes = {
  stepsBox: {
    position: "relative",
    zIndex: 2,
  },
  paper: {
    py: "2rem",
    px: 3,
    // width: "100%",
    borderRadius: "12px",
    boxShadow:
      "rgb(136 165 191 / 48%) 6px 2px 16px 0px, rgb(255 255 255 / 80%) -6px -2px 16px 0px",
    overflow: "auto",
  },
  steps: {
    width: "4em",
    height: "4em",
    zIndex: 2,
    border: "3px solid",
    borderColor: "#FF56564f",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
  },
  Title: {
    fontFamily: "Cairo",
    fontWeight: "600",
    fontSize: "1.5rem",
    pb: 4,
  },
  finishButton: {
    width: { xs: "100px", md: "150px" },
    borderRadius: "12px",
    color: "#FFFFFF",
    borderColor: "text.primary",
    "&:hover": {
      backgroundColor: "text.buttonBg",
    },
    backgroundColor: "text.primary",
    height: "56px",
  },
  restartButton: {
    width: { xs: "100px", md: "150px" },
    borderRadius: "12px",
    color: "text.primary",
    border: "4px solid",
    borderColor: "text.primary",
    "&:hover": {
      backgroundColor: "text.lightBg",
    },
    height: "56px",
  },
  floor: {
    right: "0px",
    zIndex: "-1",
    width: "4em",
    height: "4em",
    position: "absolute",
    transition: "all .1s ease",
    fill: "#FF565612",
  },
};

const floor1 = (
  <path d="m11.64 1143.14c16.61-24.28 43.77-36.9 81.47-37.85l628.95-433.37c80.18-69.55 155.36-69.55 225.52 0l681.57 374.52c27.25 30.66 30.92-4.88 41.21 13.38c36.01 63.91 22.27 110.28-41.21 139.11l-623.94 454.78c-90.21 48.15-180.42 48.15-270.62 0l-666.54-353.12c-51.06-27.05-69.87-79.53-56.42-157.44z" />
);
const floor2 = (
  <path d="m12.06 599.09c18.16-24.86 47.84-37.78 89.05-38.75l687.43-443.68c87.64-71.21 169.8-71.21 246.49 0l744.94 383.42c29.78 31.39 33.8-4.99 45.04 13.69c39.36 65.43 24.34 112.9-45.04 142.41l-681.95 465.59c-98.59 49.3-197.19 49.3-295.78 0l-728.51-361.51c-55.81-27.7-76.37-81.42-61.66-161.18z" />
);
const floor3 = (
  <path d="m11.72 758.89c16.61-22.75 43.77-34.56 81.47-35.45l628.95-405.94c80.18-65.15 155.36-65.15 225.52 0l681.57 350.81c27.25 28.72 30.92-4.57 41.21 12.53c36.01 59.86 22.27 103.29-41.21 130.3l-623.94 425.98c-90.21 45.1-180.42 45.1-270.62 0l-666.54-330.76c-51.06-25.34-69.87-74.5-56.42-147.47z" />
);
const floor4 = (
  <path d="m11.72 821.89c16.61-22.75 43.77-34.56 81.47-35.45l628.95-405.94c80.18-65.15 155.36-65.15 225.52 0l681.57 350.81c27.25 28.72 30.92-4.57 41.21 12.53c36.01 59.86 22.27 103.29-41.21 130.3l-623.94 425.98c-90.21 45.1-180.42 45.1-270.62 0l-666.54-330.76c-51.06-25.34-69.87-74.5-56.42-147.47z" />
);
const floor5 = (
  <path d="m11.64 636.9c16.61-22.75 43.77-34.56 81.47-35.45l628.95-405.94c80.18-65.15 155.36-65.15 225.52 0l681.57 350.81c27.25 28.72 30.92-4.57 41.21 12.53c36.01 59.86 22.27 103.29-41.21 130.3l-623.94 425.98c-90.21 45.1-180.42 45.1-270.62 0l-666.54-330.76c-51.06-25.34-69.87-74.5-56.42-147.47z" />
);
const floor6 = (
  <path d="m11.72 804.89c16.61-22.75 43.77-34.56 81.47-35.45l628.95-405.94c80.18-65.15 155.36-65.15 225.52 0l681.57 350.81c27.25 28.72 30.92-4.57 41.21 12.53c36.01 59.86 22.27 103.29-41.21 130.3l-623.94 425.98c-90.21 45.1-180.42 45.1-270.62 0l-666.54-330.76c-51.06-25.34-69.87-74.5-56.42-147.47z" />
);
const floor7 = (
  <path d="m11.64 547.78c16.61-22.75 43.77-34.56 81.47-35.45l628.95-405.94c80.18-65.15 155.36-65.15 225.52 0l681.57 350.81c27.25 28.72 30.92-4.57 41.21 12.53c36.01 59.86 22.27 103.29-41.21 130.3l-623.94 425.98c-90.21 45.1-180.42 45.1-270.62 0l-666.54-330.76c-51.06-25.34-69.87-74.5-56.42-147.47z" />
);

const steps = [
  {
    label: "Parcel deposit",
    icon: step1,
    m: "-1.9em",
    t: "-1.15em",
    floor: floor1,
    viewBox: "0 0 1690 1690",
  },
  {
    label: "Parcel fire",
    icon: step2,
    m: "-1em",
    t: "-.6em",
    floor: floor2,
    viewBox: "0 0 1847 1159",
  },

  {
    label: "Crossing in France",
    icon: step4,
    m: "-1.4em",
    t: "-.85em",
    floor: floor4,
    viewBox: "0 0 1690 1334",
  },
  {
    label: "Crossing in Spain",
    icon: step3,
    m: "-1.4em",
    t: "-.85em",
    floor: floor3,
    viewBox: "0 0 1690 1271",
  },
  {
    label: "Parcel clearance",
    icon: step5,
    m: "-1em",
    t: "-.6em",
    floor: floor5,
    viewBox: "0 0 1690 1149",
  },
  {
    label: "Crossing in Maroc",
    icon: step6,
    m: "-1.5em",
    t: "-.9em",
    floor: floor6,
    viewBox: "0 0 1690 1317",
  },
  {
    label: "Distribution",
    icon: step7,
    m: "-1.2em",
    t: "-.7em",
    floor: floor7,
    viewBox: "0 0 1690 1060",
  },
];
