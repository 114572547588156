import React from "react";
import AppDrawer from "./Drawer/Drawer";
export default function Dashborad() {
  // const AppDrawer = React.lazy(async () => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => resolve(import("./Drawer/Drawer")), 0);
  //   });

  // });
  return (
    // <Suspense fallback={<Loading />}>
        <AppDrawer />
    // </Suspense>
  );
}
