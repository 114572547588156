import {
  DeleteOutlined
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import maxios, { BaseUrl, CommandsUrl, FilesUrl } from "../../../api";
import { StatusContext } from "../../../App";
import np from "../../../assets/pics/no-photo.png";
import noOrders from "../../../assets/pics/NoOrders.svg";
import NoData from "../../../Tools/NoData";
import { TicketContext } from "./Tickets";

const StyledTableRow = styled(TableRow)(({ theme, selected }) => ({
  ...(selected && {
    "&.Mui-selected": {
      backgroundColor: theme.palette.text.lightBg,
      "&:hover": {
        backgroundColor: theme.palette.text.bg,
      },
    },
  }),
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Company-key",
    numeric: false,
    disablePadding: false,
    label: "Company-key",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name des Absende",
  },
  {
    id: "Ruf-Nr.",
    numeric: false,
    disablePadding: false,
    label: "Ruf-Nr.",
  },
  {
    id: "Plz.",
    numeric: false,
    disablePadding: false,
    label: "Plz.",
  },
  {
    id: "Stadt",
    numeric: false,
    disablePadding: false,
    label: "Stadt",
  },
  {
    id: "Tour",
    numeric: false,
    disablePadding: false,
    label: "Tour",
  },
  {
    id: "andemeldet für ",
    numeric: false,
    disablePadding: false,
    label: "andemeldet für",
  },
  {
    id: "Name des Empfängers",
    numeric: false,
    disablePadding: false,
    label: "Name des Empfängers",
  },
  {
    id: "Ruf-Nr.",
    numeric: false,
    disablePadding: false,
    label: "Ruf-Nr.",
  },
  {
    id: "Destination",
    numeric: false,
    disablePadding: false,
    label: "Destination",
  },
  {
    id: "Anzahl\nder\nSendungen",
    numeric: false,
    disablePadding: false,
    label: "Az.-S.",
  },
  {
    id: "Eingangsdatum",
    numeric: false,
    disablePadding: false,
    label: "Eingangsdatum",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={classes.HeadRow}>
        {/* <TableCell
          padding="checkbox"
          sx={{
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            paddingRight: 0.7,
          }}
        >
          <Checkbox
            color="default"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}

        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              borderRight: "1px solid rgba(224, 224, 224, 1)",
              borderTop: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ whiteSpace: "pre" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
            {/* <Divider orientation="vertical" flexItem /> */}
          </TableCell>
        ))}
        {/* </Box> */}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        borderRadius: "12px 12px 0 0",
        height: "6rem",
        ...(numSelected > 0 && {
          bgcolor: (theme) => theme.palette.text.lightBg,
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%", fontFamily: "Cairo", fontWeight: 600 }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={classes.Title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {"Ticket Table"}
        </Typography>
      )}

      {numSelected > 0 ? <ToolBarSwitch {...props} /> : false}
    </Toolbar>
  );
};

const ToolBarSwitch = (props) => {
  return (
    <Stack direction="row" spacing={0}>
      {/* <Tooltip title={`Send to Warehouse`} color="success">
        <IconButton
          onClick={() => props.Switchgroups(props.selected, "facturation")}
        >
          <WarehouseOutlined />
        </IconButton>
      </Tooltip> */}
      <Tooltip title="Delete" color="primary">
        <IconButton onClick={() => props.DeleteCommands(props.selected)}>
          <DeleteOutlined />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function TicketsTable({
  Data,
  Context,
  Switchgroups,
  gname,
  toname,
  DeleteCommands,
  Verifygroups,
}) {
  const [usersData, setusersData] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { dispatch } = React.useContext(StatusContext);
  const { getTicket } = React.useContext(TicketContext);

  const navigation = useNavigate();

  // const GeneateTicket = (id) => {
  //   navigation(`declaration/${id}`);
  // };
  // const GeneateDec = (id) => {
  //   navigation(`declaration/${id}`);
  // };
  // const GeneateTicket = (id) => {
  //   navigation(`ticket/${id}`);
  // };
  useEffect(() => {
    if (Data) setusersData([...Data]);
    // console.log(Data);
  }, [Data]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = usersData.map((n, index) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const UpdateOrder = async (id) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      await maxios.put(`${CommandsUrl}/${id}`, {
        withDeclar: false,
      });
      getTicket();
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: `Bill Deleted` });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };

  const DeleteBill = (companyCode, id) => {
    dispatch({
      type: "showDialog",
      payload: {
        title: "Delete Declaration ?",
        message: `Company code : ${companyCode}`,
        type: "negative",
        buttonLabel: "Delete",
        callback: () => UpdateOrder(id),
      },
    });
  };



  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersData.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          Switchgroups={Switchgroups}
          gname={gname}
          toname={toname}
          DeleteCommands={DeleteCommands}
          Verifygroups={Verifygroups}
        />
        {usersData && usersData.length > 0 ? (
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={usersData.length}
              />
              <TableBody>
                {stableSort(usersData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <StyledTableRow
                        hover
                        onContextMenu={(event) => {
                          event.preventDefault();
                          DeleteBill(row.user.code, row._id);
                        }}
                        onClick={(event) =>
                            navigation(`/ToMa/warehouse/ticket/${row._id}`)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="default"
                            onClick={(event) => handleClick(event, row._id)}
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell> */}
                        <TableCell align="left">
                          <Typography>{row?.user?.code ?? "none"}</Typography>
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <img
                              alt="img"
                              src={
                                row?.user?.photo == "no-photo"
                                  ? np
                                  : `${BaseUrl}${FilesUrl}/${row?.user?.photo}`
                              }
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: 20,
                              }}
                              crossOrigin="anonymous"
                            />
                            <Typography variant="span">{`${
                              row.user?.firstName ?? "undifined"
                            } ${
                              row.user?.lastName ?? "undifined"
                            }`}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {row.user?.phone ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {row.user?.zipcode ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {row.user?.address ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {row.user?.tour?.Tname ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {row.user?.SubsCribedCity ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="span">{`${
                            row.recipient?.firstName ?? "undifined"
                          } ${
                            row.recipient?.lastName ?? "undifined"
                          }`}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="span">
                            {row.recipient?.phone ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="span">
                            {row.recipient?.address ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {row.numofpackages ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {moment(row?.createdAt).format("DD/MM/YY")}
                          </Typography>
                        </TableCell>
                        {/* {label.type === "warehouse" ? (
                          <>
                            <TableCell align="left">
                              <Typography>
                                {row.verified === true ? "Yes" : "No"}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Stack direction={"row"}>
                                <Tooltip title={`Generate Ticket`} color="info">
                                  <span>
                                    <IconButton
                                      onClick={() => GeneateTicket(row._id)}
                                      disabled={row.verified !== true}
                                      color="info"
                                    >
                                      <InventoryRounded />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={`Generate Ticketumentaion`}
                                  color="info"
                                  onClick={() => GeneateDec(row._id)}
                                >
                                  <span>
                                    <IconButton
                                      disabled={row.verified !== true}
                                      color="info"
                                    >
                                      <DescriptionOutlined />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                <Tooltip title={`Generate Ticket`} color="info">
                                  <span>
                                    <IconButton
                                      disabled={row.verified !== true}
                                      color="info"
                                      onClick={() => GeneateTicket(row._id)}
                                    >
                                      <ConfirmationNumberOutlined />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Stack>
                            </TableCell>
                          </>
                        ) : null} */}
                      </StyledTableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoData bg={noOrders} />
        )}
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            25,
            { value: usersData.length, label: "All" },
          ]}
          component="div"
          count={usersData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

const classes = {
  paper: {
    width: "100%",
    mb: 2,
    borderRadius: "12px",
    boxShadow:
      "rgb(136 165 191 / 48%) 6px 2px 16px 0px, rgb(255 255 255 / 80%) -6px -2px 16px 0px",
  },
  Title: {
    fontFamily: "Cairo",
    fontWeight: "600",
    fontSize: "1.5rem",
    flex: "1 1 100%",
  },
};
