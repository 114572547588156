import { Alert, Slide } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { createContext, useEffect, useReducer, useState } from "react";
import { HashRouter } from "react-router-dom";
import maxios, { BaseUrl, MeUrl } from "./api";
import Dashborad from "./Dashboard/Dashborad";
import LoginForm from "./LoginForm/LoginForm";
import { ConfirmationDialog } from "./Tools/confirmationDialog";
import io from "socket.io-client";

export const StatusContext = createContext({});
const theme = createTheme({
  palette: {
    primary: {
      main: "#FF5656",
      bg: "#FF56564f",
      lightBg: "#FF565612",
    },
    secondary: {
      main: "#20AAE2",
      bg: "#20AAE24f",
      lightBg: "#20AAE212",
    },
    third: {
      main: "#5F00BA",
      bg: "#5F00BA4f",
    },
    white: {
      main: "#FFFFFF",
      bg: "#FFFFFF4f",
    },
    forth: {
      main: "#FB9300",
      bg: "#FB93004f",
    },
    success: {
      main: "#43c54a",
      bg: "#43c54a4f",
      lightBg: "#43c54a12",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#FF5656",
      contrastText: "#FFFFFF",
    },
    text: {
      main: "rgb(99, 115, 129)",
      primary: "rgb(99, 115, 129)",
      secondary: "rgb(99, 115, 129)",
      bg: "#6373814f",
      lightBg: "#63738112",
      buttonBg: "rgb(99 115 129 / 73%)",
    },
    action: {
      active: "rgb(99, 115, 129)",
    },
  },
});

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}
const Alerts = (props) => {
  return (
    <>
      <Snackbar
        open={props.payload.open}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={TransitionDown}
        onClose={props.handleClose}
      >
        <Alert
          onClose={props.handleClose}
          severity={props.payload.type}
          sx={{ width: "100%", zIndex: 1400 }}
          variant="filled"
        >
          {props.payload.message ?? "Hello there!"}
        </Alert>
      </Snackbar>
    </>
  );
};

export const LogginContext = React.createContext({});
function App() {
  const [Logged, setLogged] = useState(
    localStorage.getItem("logged") === "true" ? true : false ?? false
  );
  const [NotifArray, setNotifArray] = useState([]);
  const [socket, setSocket] = useState(null);
  const [UserData, setUserData] = useState({});
  const [MsgNotif, setMsgNotif] = useState(0);
  const [connectedUser, setConnnectedUsers] = useState([]);

  const [MasterData, setMasterData] = useState({
    agb: [],
    impressum: [],
    rules: [],
    faqs: [],
    tours: [],
    prices: [],
    weightPrice: { weigthPrice: "0" },
  });
  const [CountData, setCountData] = useState({
    agb: 0,
    impressum: 0,
    rules: 0,
    faqs: 0,
    users: 0,
    tours: 0,
    prices: 0,
    weightPrice: 0,
  });

  useEffect(() => {
    const id = localStorage.getItem("id");
    const Basesocket = io(BaseUrl);
    Basesocket.on("UsersId", (data) => {
      // setsdata(data);
      Basesocket.emit("userdata", [UserData._id ?? id, "admin"]);
    });

    setSocket(Basesocket);

    // return () => Basesocket.close();
  }, [setSocket, Logged]);

  useEffect(() => {
    socket?.on("conv", (data) => {
      if (data?.conversation?.length === 0) setMsgNotif(0);
      else setMsgNotif(data?.conversations?.length ?? 0);
      setNotifArray([...data?.conversations ?? []]);
    });
  }, [setNotifArray, socket]);

useEffect(() => {
  socket?.on("onlineUsers", (data) => {
    setConnnectedUsers([...data]);
  });
}, [socket, setConnnectedUsers])

  const CheckMe = async () => {
    if (localStorage.getItem("token"))
      try {
        maxios.get(MeUrl);
      } catch (error) {
        Logout();
        console.log("error", error.response);
      }
    else Logout();
  };

  useEffect(() => {
    CheckMe();
  }, []);

  const Loggin = (token) => {
    setLogged(true);
    localStorage.setItem("logged", true);
    localStorage.setItem("token", token);
  };
  const Logout = () => {
    setLogged(false);
    localStorage.clear();
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "showLoading":
        return { ...state, loading: action.payload };
      case "showSuccess":
        return {
          ...state,
          snackbar: { open: true, type: "success", message: action.payload },
        };
      case "showInfo":
        return {
          ...state,
          snackbar: { open: true, type: "info", message: action.payload },
        };
      case "showError":
        return {
          ...state,
          snackbar: { open: true, type: "error", message: action.payload },
        };
      case "hideSnackbar":
        return { ...state, snackbar: { ...state.snackbar, open: false } };
      case "showDialog":
        return {
          ...state,
          confirmationDialog: { ...action.payload, open: true },
        };
      case "closeDialog":
        return {
          ...state,
          confirmationDialog: { ...state.confirmationDialog, open: false },
        };
      default:
        throw new Error();
    }
  };
  const [uiState, dispatch] = useReducer(reducer, {
    loading: false,
    snackbar: {
      open: false,
      message: "a message",
      type: "error", // success | error
    },
    confirmationDialog: {
      open: false,
      title: "a title",
      message: "a message",
      callback: () => {},
      type: "positive", // positive | negative
      buttonLabel: "valider",
    },
  });
  const handleClose = (event) => {
    dispatch({ type: "hideSnackbar" });
  };

  return (
    <StatusContext.Provider value={{ dispatch }}>
      <ThemeProvider theme={theme}>
        <Alerts payload={uiState.snackbar} handleClose={handleClose} />
        <Backdrop
          sx={{ color: "red", zIndex: 1400, backdropFilter: "blur(4px)" }}
          open={uiState.loading}
        >
          <CircularProgress color={"inherit"} />
        </Backdrop>
        <ConfirmationDialog
          open={uiState.confirmationDialog.open}
          handleClose={() => dispatch({ type: "closeDialog" })}
          payload={uiState.confirmationDialog}
        />
        <LogginContext.Provider
          value={{
            Loggin,
            Logout,
            UserData,
            setUserData,
            MasterData,
            setMasterData,
            CountData,
            setCountData,
            setMsgNotif,
            MsgNotif,
            socket,
            setSocket,
            NotifArray,
            setNotifArray,
            setConnnectedUsers,
            connectedUser
          }}
        >
          <HashRouter>
            {Logged === true ? <Dashborad /> : <LoginForm />}
          </HashRouter>
        </LogginContext.Provider>
      </ThemeProvider>
    </StatusContext.Provider>
  );
}

export default App;
