import {
  ChatBubbleOutline,
  CheckRounded,
  ConfirmationNumberOutlined,
  DeleteOutlined,
  DescriptionOutlined,
  InventoryRounded,
  LocalShippingOutlined,
  WarehouseOutlined,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import maxios, { BaseUrl, CommandsUrl, FilesUrl, UsersUrl } from "../../../api";
import np from "../../../assets/pics/no-photo.png";
import noOrders from "../../../assets/pics/NoOrders.svg";
import NoData from "../../../Tools/NoData";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import { StatusContext } from "../../../App";

// 
import Sms from '../../../assets/pics/sms.svg';
// import Ctmsms from '../../../assets/pics/ctm.svg';
import Ctmsms from '../../../assets/pics/logo_ctm-1.png';
import { useContext } from "react";

const StyledTableRow = styled(TableRow)(({ theme, selected }) => ({
  ...(selected && {
    "&.Mui-selected": {
      backgroundColor: theme.palette.text.lightBg,
      "&:hover": {
        backgroundColor: theme.palette.text.bg,
      },
    },
  }),
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Company-key",
    numeric: false,
    disablePadding: false,
    label: "Company-key",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name des Absende",
  },
  {
    id: "Ruf-Nr.",
    numeric: false,
    disablePadding: false,
    label: "Ruf-Nr.",
  },
  {
    id: "Plz.",
    numeric: false,
    disablePadding: false,
    label: "Plz.",
  },
  {
    id: "Stadt",
    numeric: false,
    disablePadding: false,
    label: "Stadt",
  },
  {
    id: "Tour",
    numeric: false,
    disablePadding: false,
    label: "Tour",
  },
  {
    id: "andemeldet für ",
    numeric: false,
    disablePadding: false,
    label: "andemeldet für",
  },
  {
    id: "Name des Empfängers",
    numeric: false,
    disablePadding: false,
    label: "Name des Empfängers",
  },
  {
    id: "Ruf-Nr.",
    numeric: false,
    disablePadding: false,
    label: "Ruf-Nr.",
  },
  {
    id: "Destination",
    numeric: false,
    disablePadding: false,
    label: "Destination",
  },
  {
    id: "Anzahl\nder\nSendungen",
    numeric: false,
    disablePadding: false,
    label: "Az.-S.",
  },
  {
    id: "Eingangsdatum",
    numeric: false,
    disablePadding: false,
    label: "Eingangsdatum",
  },
];
const warehouseCells = [
  {
    id: "Actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
  {
    id: "Company-key",
    numeric: false,
    disablePadding: false,
    label: "Company-key",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name des Absende",
  },
  {
    id: "Ruf-Nr.",
    numeric: false,
    disablePadding: false,
    label: "Ruf-Nr.",
  },
  {
    id: "Plz.",
    numeric: false,
    disablePadding: false,
    label: "Plz.",
  },
  {
    id: "Stadt",
    numeric: false,
    disablePadding: false,
    label: "Stadt",
  },
  {
    id: "Tour",
    numeric: false,
    disablePadding: false,
    label: "Tour",
  },
  {
    id: "andemeldet für ",
    numeric: false,
    disablePadding: false,
    label: "andemeldet für",
  },
  {
    id: "Name des Empfängers",
    numeric: false,
    disablePadding: false,
    label: "Name des Empfängers",
  },
  {
    id: "Ruf-Nr.",
    numeric: false,
    disablePadding: false,
    label: "Ruf-Nr.",
  },
  {
    id: "Destination",
    numeric: false,
    disablePadding: false,
    label: "Destination",
  },
  {
    id: "Anzahl\nder\nSendungen",
    numeric: false,
    disablePadding: false,
    label: "Items",
  },
  {
    id: "Eingangsdatum",
    numeric: false,
    disablePadding: false,
    label: "Eingangsdatum",
  },
  {
    id: "Bestätigt",
    numeric: false,
    disablePadding: false,
    label: "Bestätigt",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    type,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={classes.HeadRow}>
        <TableCell
          padding="checkbox"
          sx={{
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            paddingRight: 0.7,
          }}
        >
          <Checkbox
            color="default"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>

        {(type === "warehouse" ? warehouseCells : headCells).map(
          (headCell, index) => (
            <TableCell
              key={index}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderTop: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{ whiteSpace: "pre" }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
              {/* <Divider orientation="vertical" flexItem /> */}
            </TableCell>
          )
        )}
        {/* </Box> */}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, label } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        borderRadius: "12px 12px 0 0",
        height: "6rem",
        ...(numSelected > 0 && {
          bgcolor: (theme) => theme.palette.text.lightBg,
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%", fontFamily: "Cairo", fontWeight: 600 }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={classes.Title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {label ? label.label : "Orders Table"}
        </Typography>
      )}

      {numSelected > 0 ? (
        <ToolBarSwitch type={label?.type} {...props} />
      ) : (
        false
      )}
    </Toolbar>
  );
};
const ToolBarSwitch = (props) => {
  const { dispatch } = useContext(StatusContext);

  const sendSms = async (data, sms) => {
    // 
    console.log (data);
    console.log("Send Sms");
    // 
    dispatch({ type: "showLoading", payload: true });
    const res = await maxios.post(`${BaseUrl}${UsersUrl}/${sms}`, {
      ids: data
    })
    if (res.status === 200) {
    dispatch({ type: "showLoading", payload: false });
    dispatch({
        type: "showSuccess",
        payload: "Sms Sent Successfully",
      });

    }else {
      dispatch({
        type: "showError",
        payload: "Server Error Please Reload",
      });
    }
  };

  // const sendSmsCtm = async (data) => {
  //   console.log (data);
  //   console.log("Send Sms");
  //   dispatch({ type: "showLoading", payload: true });
  //   const res = await maxios.post(`${BaseUrl}${UsersUrl}/smsctm`, {
  //     ids: data
  //   })
  //   if (res.status === 200) {
  //   dispatch({ type: "showLoading", payload: false });
  //   dispatch({
  //       type: "showSuccess",
  //       payload: "Sms Sent Successfully",
  //     });

  //   }else {
  //     dispatch({
  //       type: "showError",
  //       payload: "Server Error Please Reload",
  //     });
  //   }
  // };

  switch (props.type) {
    case "validation":
      return (
        <Stack direction="row" spacing={0}>
          <Tooltip title="Message All" color="success">
            <IconButton onClick={() => props.openChat(props.selected)}>
              <QuestionAnswerRoundedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Send to Warehouse`} color="success">
            <IconButton
              onClick={() => props.Switchgroups(props.selected, "facturation")}
            >
              <WarehouseOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" color="primary">
            <IconButton onClick={() => props.DeleteCommands(props.selected)}>
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
      );

    case "warehouse":
      return (
        <Stack direction="row" spacing={0}>
          <Tooltip title="Message All" color="success">
            <IconButton onClick={() => props.openChat(props.selected)}>
              <QuestionAnswerRoundedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Validate Order" color="success">
            <IconButton onClick={() => props.Verifygroups(props.selected)}>
              <CheckRounded />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" color="primary">
            <IconButton onClick={() => props.DeleteCommands(props.selected)}>
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Send to Tracking`} color="info">
            <IconButton
              onClick={() => props.Switchgroups(props.selected, "tracking")}
            >
              <LocalShippingOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    case "ToDu":
      return (
        <Stack direction="row" spacing={0}>
          <Tooltip title="Message All" color="success">
            <IconButton onClick={() => props.openChat(props.selected)}>
              <QuestionAnswerRoundedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => props.DeleteCommands(props.selected)}>
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    default:
      return (
        <Stack direction="row" spacing={2}>
          {/* Add Send SMS Function Here !!! */}
          {/* <Tooltip title="Message All" color="success">
            <IconButton onClick={() => props.openChat(props.selected)}>
            </IconButton>
          </Tooltip> */}


          {/* Ctm Sms */}
          <Tooltip title="Send SMS CTM" color="success">
            <div
            onClick={() => sendSms(props.selected, 'smsctm')} 
            style={{color: 'blue', display: 'flex', justifyItems: 'center', alignItems: 'center', cursor: 'pointer', marginRight: '20px'}}>
              <img src={Ctmsms} alt="sms" style={{ width: '65px', height: '20px'}} />
              {/* <img src={Ctmsms} alt="sms" style={{ width: '24px', height: '24px'}} /> */}
            </div>
          </Tooltip>


          {/* Sms Du */}
          <Tooltip title="Send SMS DU" color="success">
            <div
            onClick={() => sendSms(props.selected, 'smsdu')} 
            style={{color: 'blue', display: 'flex', justifyItems: 'center', alignItems: 'center', cursor: 'pointer', marginRight: '20px'}}>
              <img src={Sms} alt="sms" style={{ width: '24px', height: '24px'}} />
            </div>
          </Tooltip>
          

          <Tooltip title="Message All" color="success">
            <IconButton onClick={() => props.openChat(props.selected)}>
              <QuestionAnswerRoundedIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      );
  }
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function TableOrders({
  Data,
  Context,
  Switchgroups,
  gname,
  toname,
  DeleteCommands,
  label,
  Verifygroups,
  selected,
  setSelected,
  openChat,
}) {
  const [usersData, setusersData] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const { dispatch } = React.useContext(StatusContext);
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const navigation = useNavigate();

  const SendSmsMa = async (id) => {
    try {
      dispatch({
        type: "showLoading",
        payload: true,
      });
      const res = await maxios.put(`${CommandsUrl}/${id}`, { smsToMa: true });
      dispatch({
        type: "showLoading",
        payload: false,
      });
      const updatedData = Data.map((cmd) => {
        if (cmd._id === id) return {...cmd, smsToMa: true};
        else return cmd;
      });
      setusersData([...updatedData]);
    } catch (error) {
      dispatch({
        type: "showLoading",
        payload: false,
      });
      console.log(error);
    }
  };

  const GeneateBill = (id) => {
    navigation(`bill/${id}`);
  };
  const GeneateDec = (id) => {
    navigation(`declaration/${id}`);
  };
  const GeneateTicket = (id) => {
    navigation(`ticket/${id}`);
  };
  useEffect(() => {
    if (Data) setusersData([...Data]);
    // console.log(Data);
  }, [Data]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = usersData.map((n, index) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersData.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          Switchgroups={Switchgroups}
          gname={gname}
          toname={toname}
          DeleteCommands={DeleteCommands}
          label={label}
          Verifygroups={Verifygroups}
          openChat={openChat}
        />
        {usersData && usersData.length > 0 ? (
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={usersData.length}
                type={label.type}
              />
              <TableBody>
                {stableSort(usersData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <StyledTableRow
                        hover
                        // onClick={(event) => handleClick(event, row._id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="default"
                            onClick={(event) => handleClick(event, row._id)}
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>

                        {label.type === "warehouse" ? (
                          <>
                            <TableCell>
                              <Stack direction={"row"}>
                                <Tooltip title={`Generate SMS`} color="info">
                                  <span>
                                    <IconButton
                                      disabled={row.smsToMa}
                                      sx={{ position: "relative" }}
                                      color="error"
                                      onClick={() => SendSmsMa(row._id)}
                                    >
                                      <ChatBubbleOutline />
                                      <Typography
                                        variant="span"
                                        sx={{
                                          position: "absolute",
                                          fontSize: "10px",
                                          top: "35%",
                                          color: "secondary.main",
                                        }}
                                      >
                                        M
                                      </Typography>
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                <Tooltip title={`Generate Bill`} color="info">
                                  <span>
                                    <IconButton
                                      onClick={() => GeneateBill(row._id)}
                                      disabled={row.verified !== true}
                                      color="info"
                                    >
                                      <InventoryRounded />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={`Generate Documentaion`}
                                  color="info"
                                  onClick={() => GeneateDec(row._id)}
                                >
                                  <span>
                                    <IconButton
                                      // disabled={row.verified !== true}
                                      color="info"
                                    >
                                      <DescriptionOutlined />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                <Tooltip title={`Generate Ticket`} color="info">
                                  <span>
                                    <IconButton
                                      // disabled={row.verified !== true}
                                      color="info"
                                      onClick={() => GeneateTicket(row._id)}
                                    >
                                      <ConfirmationNumberOutlined />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Stack>
                            </TableCell>
                          </>
                        ) : null}
                        <TableCell align="left">
                          <Typography>{row?.user?.code ?? "none"}</Typography>
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <img
                              alt="img"
                              src={
                                row?.user?.photo == "no-photo"
                                  ? np
                                  : `${BaseUrl}${FilesUrl}/${row?.user?.photo}`
                              }
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: 20,
                              }}
                              crossOrigin="anonymous"
                            />
                            <Typography variant="span">{`${
                              row.user?.firstName ?? "undifined"
                            } ${
                              row.user?.lastName ?? "undifined"
                            }`}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {row.user?.phone ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {row.user?.zipcode ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {row.user?.address ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {row.user?.tour?.Tname ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {row.user?.cityName ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="span">{`${
                            row.recipient?.firstName ?? "undifined"
                          } ${
                            row.recipient?.lastName ?? "undifined"
                          }`}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="span">
                            {row.recipient?.phone ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="span">
                            {row.recipient?.address ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {row.numofpackages ?? "undifined"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>
                            {moment(row?.createdAt).format("DD/MM/YY")}
                          </Typography>
                        </TableCell>
                        {label.type === "warehouse" ? (
                          <>
                            <TableCell align="left">
                              <Typography>
                                {row.verified === true ? "Yes" : "No"}
                              </Typography>
                            </TableCell>
                          </>
                        ) : null}
                        {label.type === "ToDu" ? (
                          <TableCell>
                            <Tooltip title={`Check Id`} color="info">
                              <span>
                                <IconButton
                                  onClick={() => navigation(`cin/${row._id}`)}
                                  disabled={
                                    row.faceId === "no-file" ||
                                    row.backId === "no-file"
                                  }
                                  color="info"
                                >
                                  <CreditCardRoundedIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </TableCell>
                        ) : null}
                      </StyledTableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoData bg={noOrders} />
        )}
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            25,
            { value: usersData.length, label: "All" },
          ]}
          component="div"
          count={usersData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

const classes = {
  paper: {
    width: "100%",
    mb: 2,
    borderRadius: "12px",
    boxShadow:
      "rgb(136 165 191 / 48%) 6px 2px 16px 0px, rgb(255 255 255 / 80%) -6px -2px 16px 0px",
  },
  Title: {
    fontFamily: "Cairo",
    fontWeight: "600",
    fontSize: "1.5rem",
    flex: "1 1 100%",
  },
};
