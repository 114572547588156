import man from "./pics/man.jpeg";
import A from "./pics/A.mp4";
import gif from "./pics/testgif.gif";
import gifchat from "./pics/gifchat.gif";
import gifchat2 from "./pics/gifchat2.gif";
import gifchat3 from "./pics/gifchat3.gif";
import gifchat4 from "./pics/gifchat4.gif";
import gifchat5 from "./pics/gifchat5.gif";
import gifchat6 from "./pics/gifchat6.gif";
import gifchat7 from "./pics/gifchat7.gif";
import gifchat8 from "./pics/gifchat8.gif";

export const Gifschat = [
  {
    source: gifchat,
    name: "1",
  },
  {
    source: gifchat2,
    name: "2",
  },
  {
    source: gifchat3,
    name: "3",
  },
  {
    source: gifchat4,
    name: "4",
  },
  {
    source: gifchat5,
    name: "5",
  },
  {
    source: gifchat6,
    name: "6",
  },
  {
    source: gifchat7,
    name: "7",
  },
  {
    source: gifchat8,
    name: "8",
  },
];

export const default_appointments = {
  toMa: "mid-january",
  toDu: "mid-summer",
};

export const default_stars = [
  {
    label: "5 star",
    value: "fiveStar",
  },
  {
    label: "4 star",
    value: "fourStar",
  },
  {
    label: "3 star",
    value: "threeStar",
  },
  {
    label: "2 star",
    value: "twoStar",
  },
  {
    label: "1 star",
    value: "oneStar",
  },
];

export const default_all_users = [
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
  {
    name: "user username",
    pic: man,
  },
];

export const default_all_reviews = {
  total: 6,
  reviews: [
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum. Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 2,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum. Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 2,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
  ],
};

export const default_reviews = {
  total: 6,
  reviews: [
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum. Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 2,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
    {
      name: "user name",
      date: "14 jan 2022 9:41 AM",
      comment:
        "Ea laboris aliqua cillum ipsum aute ipsum veniam adipisicing. Nisi commodo ut ad ex laborum.",
      stars: 5,
      pic: man,
    },
  ],
};

export const defaultAdsData = [
  {
    imgs: [
      {
        src: man,
        type: "img",
      },
      {
        src: gif,
        type: "img",
      },
    ],
    isActive: false,
  },
  {
    imgs: [
      {
        src: man,
        type: "img",
      },
      {
        src: gif,
        type: "img",
      },
    ],
    isActive: false,
  },
  {
    imgs: [
      {
        src: gif,
        type: "img",
      },
      {
        src: man,
        type: "img",
      },
    ],
    isActive: true,
  },
];

export const itemData = [
  {
    imgs: [
      {
        src: A,
        type: "video/mp4",
      },
      {
        src: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
        type: "img",
      },
      {
        src: A,
        type: "video/mp4",
      },
      {
        src: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
        type: "img",
      },
    ],
    title: "Breakfast",
    desc: "Officia laborum ut proident mollit veniam minim deserunt do amet officia do nisi non.",
  },
  {
    imgs: [
      {
        src: man,
        type: "img",
      },
      {
        src: man,
        type: "img",
      },
    ],
    title: "Breakfast",
    desc: "Officia laborum ut proident mollit veniam minim deserunt do amet officia do nisi non.",
  },
  {
    imgs: [
      {
        src: A,
        type: "video/mp4",
      },
      {
        src: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
        type: "img",
      },
      {
        src: A,
        type: "video/mp4",
      },
      {
        src: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
        type: "img",
      },
    ],
    title: "Breakfast",
    desc: "Officia laborum ut proident mollit veniam minim deserunt do amet officia do nisi non.",
  },
];

export const prices = {
  weightPrice: 2.5,
  categories: [
    {
      Cname: "Kategorie A",
      price: "2.5",
      desc: "Dolor aliqua in id nulla ipsum occaecat sit in non ex elit deserunt eu commodo. Non irure sint culpa sit commodo cillum magna enim quis. Anim ea duis dolore magna incididunt. Ipsum irure qui elit ad sint sint et do aute qui. Et est et nulla est dolore fugiat laboris amet ea aliquip.",
      subName: "ipsum occaecat",
    },
    {
      Cname: "Kategorie A",
      price: "",
      desc: "Dolor aliqua in id nulla ipsum occaecarure sint culpa sit commodo cillum magna enim quis. Anim ea duis dolore magna incididunt. Ipsum irure qui elit ad sint sint et do aute qui. Et est et nulla est dolore fugiat laboris amet ea aliquip.",
      subName: "ipsum occaecat",
    },
    {
      Cname: "Kategorie A",
      price: "",
      desc: "Dolor aliqua in id nulla ipsum occaecat sit in non ex elit deserunt eu commodo. Non irure sint culpa sit commodo cillum magna enim quis. Anim ea duis dolore magna incididunt. Ipsum irure qui elit ad sint sint et do aute qui. Et est et nulla est dolore fugiat laboris amet ea aliquip.",
      subName: "ipsum occaecat",
    },
    {
      Cname: "Kategorie A",
      price: "",
      desc: "Dolor aliqua in id nulla ipsum occaecat sit incommodo cillum magna enim quis. Anim ea duis dolore magna incididunt. Ipsum irure qui elit ad sint sint et do aute qui. Et est et nulla est dolore fugiat laboris amet ea aliquip.",
      subName: "ipsum occaecat",
    },
  ],
};

export const ImpDATA = [
  {
    title: "Angaben gemäß §5 TMG",
    desc: "KAL & ROK GmbH\nAbdelhafid El Kouzoudi\nLeopoldstraße 11a\n90439 Nürnberg\nSteuer ID: DE344144227",
  },
  {
    title: "Kontakt",
    desc: "Telefon: 0911 / 25391310\nE-Mail: info@kal-rok.de",
  },
  {
    title: "Verbraucherstreitbeilegung/Universalschlichtungsstelle",
    desc: "Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer\nVerbraucherschlichtungsstelle teilzunehmen.",
  },
];

export const FAQDATA = [
  {
    title: "Geschäftsbedingungen regeln das Vertragsverhältnis ?",
    desc: "nachstehende allgemeine Geschäftsbedingungen regeln das Vertragsverhältnis zwischen dem Transport-und Logist",
  },
  {
    title: "Geschäftsbedingungen regeln das Vertragsverhältnis ?",
    desc: "nachstehende allgemeine Geschäftsbedingungen regeln das Vertragsverhältnis zwischen dem Transport-und Logist",
  },
  {
    title: "Geschäftsbedingungen regeln das Vertragsverhältnis ?",
    desc: "nachstehende allgemeine Geschäftsbedingungen regeln das Vertragsverhältnis zwischen dem Transport-und Logist",
  },
  {
    title: "Geschäftsbedingungen regeln das Vertragsverhältnis ?",
    desc: "nachstehende allgemeine Geschäftsbedingungen regeln das Vertragsverhältnis zwischen dem Transport-und Logist",
  },
  {
    title: "Geschäftsbedingungen regeln das Vertragsverhältnis ?",
    desc: "nachstehende allgemeine Geschäftsbedingungen regeln das Vertragsverhältnis zwischen dem Transport-und Logist",
  },
  {
    title: "Geschäftsbedingungen regeln das Vertragsverhältnis ?",
    desc: "nachstehende allgemeine Geschäftsbedingungen regeln das Vertragsverhältnis zwischen dem Transport-und Logist",
  },
];

export const AGBDATA = [
  {
    title: "Geltungsbereich",
    desc: "nachstehende allgemeine Geschäftsbedingungen regeln das Vertragsverhältnis zwischen dem Transport-und Logistikdienstleister, KAL&ROK GmbH, als Auftragnehmer und seinen Kunden, kurz Absender bzw. Auftraggeber genannt. Und sofern nichts Abweichendes vereinbart wurde, gelten die nachstehend formulierten AGB’s. Mit der Erteilung des Auftrags erklärt sich der Auftraggeber mit diesen Bedingungen einverstanden. ",
  },
  {
    title: "Angebot und Vertragsschluss: ",
    desc: "Ein Vertrag kommt dadurch zustande, wenn der Auftraggeber die Auftragsbestätigung unterzeichnet. Lediglich schriftliche Angebote des Auftragnehmers sind bindend, telefonische oder Angebote per E-Mail sind nicht bindend. ",
  },
  {
    title: "Form von Erklärungen:",
    desc: "Rechtserhebliche Erklärungen und Anzeigen, die der Auftraggeber gegenüber dem Auftragnehmer oder einem Dritten abzugeben hat, bedürfen der Schriftform.",
  },
  {
    title: "Verpackungsbedingungen",
    desc: "Eine sichere Verpackung ist für alle Sendungen unabhängig vom Inhalt unerlässlich, außer für Gegenstände, die sich unverpackt befördert lassen.     Die Verpackung muss so beschaffen sein, dass sie dem Inhalt der Sendung genügend Schutz bietet, und vor Verlust oder Beschädigung schützt.     Aus Rücksicht auf die Gesundheit unserer Mitarbeiter ist eine besondere Kennzeichnung der Pakete mit einem Gewicht von über 20 Kg erforderlich.    Flaschen und Behälter mit flüssigem Inhalt müssen eine Dichtigkeit aufweisen, sodass ein Austritten der Flüssigkeit ausgeschlossen sein muss. Somit wird verhindert, dass Sendungen anderer Kunden beschädigt oder beschmiert werden. ",
  },
  {
    title: "Unfreier Versand:",
    desc: "Sendungen können unfrei zum Versend abgegeben werden und die Zahlung des Entgelts wird beim Empfänger geltend gemacht. Verweigert der Empfänger jedoch die Zahlung und somit auch die Annahme des Paketes, wird das Paket an den Absender zurückgesandt und die Versandkosten werden ihm dann in Rechnung gestellt.",
  },
  {
    title: "Hinweise zum Datenschutz",
    desc: "Angaben des Absenders und Empfängers werden von der KAL&ROK GmbH ausschließlich zur Erfüllung des Transportvertrages und zur Zollabwicklung auf der Grundlage der gesetzlich geltenden Vorschriften genutzt. Im Rahmen dieses Transportvertrages werden diese Angaben den Zollbehörden im Bestimmungsland sowie den kooperierenden Zustellpartnern zur Verfügung gestellt. Eine Übermittlung der Angaben kann in Papier- oder in elektronischer Form erfolgen.",
  },
  {
    title: "Ausschluss und Verbotsgüter:",
    desc: "Der Absender ist verpflichtet bei der Abgabe seiner Sendung zu klären, ob es sich bei dem Inhalt der abgegebenen Sendung um die nachstehend näher   bestimmten Verbotsgüter handelt: \n    Pakete, deren Inhalt, Beförderung oder Lagerung gegen ein gesetzliches oder behördliches Verbot, insbesondere gegen Ein-, Ausfuhr oder zollrechtliche Bestimmungen des Einlieferungs-, Durchgangs- oder Bestimmungslandes verstoßen. Hierzu gehören auch \n Pakete bzw. Güter, deren Beförderung nach dem Weltpostvertrag nicht zugelassen ist.     \n Pakete mit temperaturgeführtem Gut, dessen Beförderung besondere Einrichtungen, Sicherheitsvorkehrungen oder Genehmigungen erfordern.     \n Pakete, deren Inhalt auch bei gewöhnlichem Transportverlauf Sachschäden verursachen oder Personen verletzen könnten.     \n Pakete mit lebenden Tieren, Tierkadavern, Körperteilen oder sterblichen Überresten von Menschen.     \n Pakete, die Betäubungs-, Rauschmittel oder verbotene Substanze enthalten.     \n Pakete, die Geld, Edelmetalle, Schecks, Kreditkarten, gültige Briefmarken, Wertpapiere oder andere Zahlungsmittel enthalten.     \n Pakete, deren Inhalt gegen gesetzliche Vorschriften zum Schutz geistigen Eigentums verstößt, einschließlich gefälschter oder nicht lizenzierter Kopien von Produkten (Markenpiraterie aller Arten).     \n Pakete, die Waffen, insbesondere Schusswaffen, oder Teile davon, Waffenimitate oder Munition enthalten.     Die KAL&ROK GmbH ist zwar zur Prüfung der \n Pakete auf das Vorliegen von Beförderungsausschlüssen nicht verpflichtet; jedoch ist sie bei Verdacht auf solche Ausschlüsse zur Öffnung und Überprüfung der \n Pakete berechtigt. Der Absender ist und bleibt Eigentümer der Sendung und folgerichtig verantwortlich für den Inhalt seiner Sendung.     Eine Kündigung des Beförderungsvertrages durch den Absender nach Abgabe der Sendung in die Obhut der KAL&ROK GmbH ist ausgeschlossen. ",
  },
  {
    title: "Geltungsbereich",
    desc: "nachstehende allgemeine Geschäftsbedingungen regeln das Vertragsverhältnis zwischen dem Transport-und Logistikdienstleister, KAL&ROK GmbH, als Auftragnehmer und seinen Kunden, kurz Absender bzw. Auftraggeber genannt. Und sofern nichts Abweichendes vereinbart wurde, gelten die nachstehend formulierten AGB’s. Mit der Erteilung des Auftrags erklärt sich der Auftraggeber mit diesen Bedingungen einverstanden. ",
  },
  {
    title: "Geltungsbereich",
    desc: "nachstehende allgemeine Geschäftsbedingungen regeln das Vertragsverhältnis zwischen dem Transport-und Logistikdienstleister, KAL&ROK GmbH, als Auftragnehmer und seinen Kunden, kurz Absender bzw. Auftraggeber genannt. Und sofern nichts Abweichendes vereinbart wurde, gelten die nachstehend formulierten AGB’s. Mit der Erteilung des Auftrags erklärt sich der Auftraggeber mit diesen Bedingungen einverstanden. ",
  },
  {
    title: "Geltungsbereich",
    desc: "nachstehende allgemeine Geschäftsbedingungen regeln das Vertragsverhältnis zwischen dem Transport-und Logistikdienstleister, KAL&ROK GmbH, als Auftragnehmer und seinen Kunden, kurz Absender bzw. Auftraggeber genannt. Und sofern nichts Abweichendes vereinbart wurde, gelten die nachstehend formulierten AGB’s. Mit der Erteilung des Auftrags erklärt sich der Auftraggeber mit diesen Bedingungen einverstanden. ",
  },
  {
    title: "Geltungsbereich",
    desc: "nachstehende allgemeine Geschäftsbedingungen regeln das Vertragsverhältnis zwischen dem Transport-und Logistikdienstleister, KAL&ROK GmbH, als Auftragnehmer und seinen Kunden, kurz Absender bzw. Auftraggeber genannt. Und sofern nichts Abweichendes vereinbart wurde, gelten die nachstehend formulierten AGB’s. Mit der Erteilung des Auftrags erklärt sich der Auftraggeber mit diesen Bedingungen einverstanden. ",
  },
  {
    title: "Geltungsbereich",
    desc: "nachstehende allgemeine Geschäftsbedingungen regeln das Vertragsverhältnis zwischen dem Transport-und Logistikdienstleister, KAL&ROK GmbH, als Auftragnehmer und seinen Kunden, kurz Absender bzw. Auftraggeber genannt. Und sofern nichts Abweichendes vereinbart wurde, gelten die nachstehend formulierten AGB’s. Mit der Erteilung des Auftrags erklärt sich der Auftraggeber mit diesen Bedingungen einverstanden. ",
  },
];

export const DefaultTours = [
  {
    Tname: "A",
    pic: gif,
    Cities: [
      "Neumarkt",
      "Regensburg",
      "Landshut",
      "Ferising",
      "Munchen",
      "Ingolstadt",
    ],
    status: false,
    startDate: new Date(),
    endDate: new Date(),
  },
  {
    pic: gif,
    Tname: "B",
    Cities: [
      "Neumarkt",
      "Regensburg",
      "Landshut",
      "Ferising",
      "Munchen",
      "Ingolstadt",
    ],
    status: true,
    startDate: new Date(),
    endDate: new Date(),
  },
  {
    pic: gif,
    Tname: "C",
    Cities: [
      "Neumarkt",
      "Regensburg",
      "Landshut",
      "Ferising",
      "Munchen",
      "Ingolstadt",
    ],
    status: false,
    startDate: new Date(),
    endDate: new Date(),
  },
  {
    Tname: "D",
    pic: gif,
    Cities: [
      "Neumarkt",
      "Regensburg",
      "Landshut",
      "Ferising",
      "Munchen",
      "Ferising",
      "Munchen",
      "Ingolstadt",
    ],
    status: true,
    startDate: new Date(),
    endDate: new Date(),
  },
  {
    Tname: "E",
    pic: gif,
    Cities: [
      "Neumarkt",
      "Regensburg",
      "Landshut",
      "Ferising",
      "Munchen",
      "Ingolstadt",
    ],
    status: true,
    startDate: new Date(),
    endDate: new Date(),
  },
  {
    Tname: "F",
    pic: gif,
    Cities: [
      "Neumarkt",
      "Regensburg",
      "Landshut",
      "Ferising",
      "Munchen",
      "Ingolstadt",
    ],
    status: false,
    startDate: new Date(),
    endDate: new Date(),
  },
  {
    Tname: "G",
    pic: gif,
    Cities: [
      "Neumarkt",
      "Regensburg",
      "Landshut",
      "Ferising",
      "Munchen",
      "Ingolstadt",
    ],
    status: false,
    startDate: new Date(),
    endDate: new Date(),
  },
  {
    Tname: "H", // Tour name
    pic: gif, // Tour pic
    Cities: [
      "Neumarkt",
      "Regensburg",
      "Landshut",
      "Ferising",
      "Munchen",
      "Ingolstadt",
    ],
    status: false, //Tour status : False = Stopped ; True = Started
    citiesStatus: 2, //City Status , 2 ==> tour in "Regensburg"
    startDate: new Date(),
    endDate: new Date(),
  },
];

function createData(avatar, name, items, Ddate) {
  return {
    avatar,
    name,
    items,
    Ddate,
  };
}

function createUsers(
  avatar,
  name,
  items,
  status,
  toure,
  phone,
  email,
  adresse
) {
  return {
    avatar,
    name,
    items,
    status,
    toure,
    phone,
    email,
    adresse,
  };
}

export const DataA = [
  createData(man, "user name", 305, "12 - 13 - 2021"),
  createData(man, "user name", 452, "12 - 13 - 2021"),
  createData(man, "user name", 262, "12 - 13 - 2021"),
  createData(man, "user name", 159, "12 - 13 - 2021"),
  createData(man, "user name", 356, "12 - 13 - 2021"),
  createData(man, "user name", 408, "12 - 13 - 2021"),
  createData(man, "user name", 237, "12 - 13 - 2021"),
  createData(man, "user name", 375, "12 - 13 - 2021"),
  createData(man, "user name", 518, "12 - 13 - 2021"),
  createData(man, "user name", 392, "12 - 13 - 2021"),
  createData(man, "user name", 120, "12 - 13 - 2021"),
  createData(man, "user name", 360, "12 - 13 - 2021"),
  createData(man, "user name", 437, "12 - 13 - 2021"),
];

export const DataB = [
  // createData(man, "name", 305, "12 - 13 - 2021"),
  // createData(man, "name", 452, "12 - 13 - 2021"),
  // createData(man, "name", 262, "12 - 13 - 2021"),
  // createData(man, "name", 159, "12 - 13 - 2021"),
  // createData(man, "name", 356, "12 - 13 - 2021"),
  // createData(man, "name", 408, "12 - 13 - 2021"),
  // createData(man, "name", 237, "12 - 13 - 2021"),
  // createData(man, "name", 375, "12 - 13 - 2021"),
  // createData(man, "name", 518, "12 - 13 - 2021"),
  // createData(man, "name", 392, "12 - 13 - 2021"),
  // createData(man, "name", 120, "12 - 13 - 2021"),
  // createData(man, "name", 360, "12 - 13 - 2021"),
  // createData(man, "name", 437, "12 - 13 - 2021"),
];

export const UsersData = [
  createUsers(
    man,
    "name user",
    20,
    "Active",
    "A",
    "+49 30 353744924",
    "User@email.com",
    "Hedemannstasse 65, Hohentengen, Baden-Württemberg"
  ),
  createUsers(
    man,
    "name user",
    15,
    "Active",
    "B",
    "+49 30 353744924",
    "User@email.com",
    "Hedemannstasse 65, Hohentengen, Baden-Württemberg"
  ),
  createUsers(
    man,
    "name user",
    26,
    "Active",
    "C",
    "+49 30 353744924",
    "User@email.com",
    "Hedemannstasse 65, Hohentengen, Baden-Württemberg"
  ),
  createUsers(
    man,
    "name user",
    15,
    "Active",
    "C",
    "+49 30 353744924",
    "User@email.com",
    "Hedemannstasse 65, Hohentengen, Baden-Württemberg"
  ),
  createUsers(
    man,
    "name user",
    35,
    "Active",
    "C",
    "+49 30 353744924",
    "User@email.com",
    "Hedemannstasse 65, Hohentengen, Baden-Württemberg"
  ),
  createUsers(
    man,
    "name user",
    40,
    "Active",
    "C",
    "+49 30 353744924",
    "User@email.com",
    "Hedemannstasse 65, Hohentengen, Baden-Württemberg"
  ),
  createUsers(
    man,
    "name user",
    23,
    "Blocked",
    "C",
    "+49 30 353744924",
    "User@email.com",
    "Hedemannstasse 65, Hohentengen, Baden-Württemberg"
  ),
  createUsers(
    man,
    "name user",
    37,
    "Blocked",
    "C",
    "+49 30 353744924",
    "User@email.com",
    "Hedemannstasse 65, Hohentengen, Baden-Württemberg"
  ),
  createUsers(
    man,
    "name user",
    51,
    "Active",
    "C",
    "+49 30 353744924",
    "User@email.com",
    "Hedemannstasse 65, Hohentengen, Baden-Württemberg"
  ),
  createUsers(
    man,
    "name user",
    39,
    "Active",
    "E",
    "+49 30 353744924",
    "User@email.com",
    "Hedemannstasse 65, Hohentengen, Baden-Württemberg"
  ),
  createUsers(
    man,
    "name user",
    12,
    "Active",
    "E",
    "+49 30 353744924",
    "User@email.com",
    "Hedemannstasse 65, Hohentengen, Baden-Württemberg"
  ),
  createUsers(
    man,
    "name user",
    36,
    "Active",
    "E",
    "+49 30 353744924",
    "User@email.com",
    "Hedemannstasse 65, Hohentengen, Baden-Württemberg"
  ),
  createUsers(
    man,
    "name user",
    43,
    "Active",
    "E",
    "+49 30 353744924",
    "User@email.com",
    "Hedemannstasse 65, Hohentengen, Baden-Württemberg"
  ),
];

export const PrivacyData = [
  {
    title: "Datenschutz auf einen Blick",
    items: [
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle:
          "Wer ist verantwortlich für die Datenerfassung auf dieser Website?",
        desc: "Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.",
      },
      {
        itemTitle: "Wie erfassen wir Ihre Daten?",
        desc: "Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.",
      },
      {
        itemTitle: "Wofür nutzen wir Ihre Daten?",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.",
      },
    ],
  },
  {
    title: "Hosting",
    items: [
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
    ],
  },
  {
    title: "Allgemeine Hinweise und Pflicht­informationen",
    items: [
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
    ],
  },
  {
    title: "Datenerfassung auf dieser Website",
    items: [
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
    ],
  },
  {
    title: "Plugins und Tools ",
    items: [
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
      {
        itemTitle: "Allgemeine Hinweise",
        desc: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. Datenerfassung auf dieser Website ",
      },
    ],
  },
];
