import {
  Document,
  Font,
  Image,
  Line,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import facebook from "../../../assets/pics/facebook.png";
import instagram from "../../../assets/pics/instagram.png";
import logo from "../../../assets/pics/logo.png";
import mail from "../../../assets/pics/mail.png";
import phoneCall from "../../../assets/pics/phone-call.png";
import smartphone from "../../../assets/pics/smartphone.png";
import web from "../../../assets/pics/web.png";
// import * as React from "react";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    margin: 10,
    fontFamily: "Open Sans",
  },
  section: {
    margin: 10,
    padding: 10,
    // flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
  },
  logo: {
    objectFit: "contain",
    width: "150px",
    height: "100px",
  },
  text: {
    fontSize: 10,
  },
  title: {
    paddingVertical: 15,
    fontSize: 14,
  },
  thead: {
    flexDirection: "row",
    width: "80%",
  },
  tbody: {
    flexDirection: "row",
    width: "80%",
  },
  Lcell: {
    flexBasis: "33.33%",
    borderWidth: 1,
    borderRightWidth: 0,
    alignItems: "center",
  },
  Mcell: {
    flexBasis: "33.33%",
    borderWidth: 1,
    borderRightWidth: 0,
    alignItems: "center",
  },
  Rcell: {
    flexBasis: "33.33%",
    borderWidth: 1,
    alignItems: "center",
  },
  CellText: {
    textAlign: "center",
    padding: 5,
    fontSize: 10,
  },
  EmptyBox: {
    width: 15,
    height: 15,
    borderWidth: 1,
    borderRadius: 2,
    marginRight: 5,
  },
  CheckContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexBasis: "33.33%",
  },
});

// const formik = {
//   values: {
//     _id: "",
//     recipient: {
//       name: "",
//       phone: "",
//       address: "",
//     },
//     content: [
//       {
//         desc: "",
//         qty: "0",
//         price: "0",
//       },
//     ],
//     user: {
//       _id: "",
//       name: "",
//       phone: "",
//       address: "",
//       photo: "no-photo",
//       zipcode: "",
//       cityId: "",
//       Tname: "",
//       code: "",
//     },
//     wights: 0,
//     numofpackages: 0,
//     history: false,
//     finish: false,
//     verified: false,
//     withTicket: false,
//     withBill: false,
//     withDeclar: false,
//     commandMode: "facturation",
//     priceTotal: "",
//     priceSub: "",
//     type: 1,
//     payment: 1,
//     createdAt: Date.now(),
//     __v: 0,
//   },
// };

// Create Document Component
const BillPdf = ({ formik }) => (
  <Document>
    <Page
      size="A4"
      style={styles.page}
      children={<BillContent formik={formik} />}
    />
  </Document>
);

const BillContent = ({ formik }) => {
  if (formik?.values?._id !== "")
    return (
      <>
        <View
          style={{
            alignItems: "center",
          }}
        >
          <Image src={logo} style={styles.logo} />
        </View>
        <Text style={styles.title}>Leopoldstr. 11a 90439 Nürnberg</Text>
        <Text
          style={{
            paddingVertical: 5,
            fontSize: 13,
            fontWeight: 900,
          }}
        >
          Rechnung
        </Text>
        <View style={styles.section}>
          <Text style={{ ...styles.text, fontWeight: "600" }}>
            Rechnung-Nr.:
            <Text style={{ fontWeight: 400 }}>
              {formik?.values?.rechnr || " "}
            </Text>
          </Text>
          <Text style={{ ...styles.text, fontWeight: "600" }}>
            Kunden-Nr.:
            <Text style={{ fontWeight: 400 }}>
              {formik?.values?.user?.code || " "}
            </Text>
          </Text>
          <Text style={{ ...styles.text, fontWeight: "600" }}>
            Datum:
            <Text style={{ fontWeight: 400 }}>
              {moment(formik?.values?.createdAt || " ").format("DD/MM/YYYY")}
            </Text>
          </Text>
        </View>
        <TablePdf formik={formik} />
        <PriceTable formik={formik} />
        <View style={{ width: "80%", marginTop: "10pt" }}>
          <Text
            style={{
              ...styles.text,
              fontWeight: "600",
              alignSelf: "flex-start",
            }}
          >
            Anzahl der Pakete/Sendungen:
            <Text style={{ fontWeight: 400 }}>
              {formik?.values?.numofpackages || " "}
            </Text>
          </Text>
        </View>
        <Checkboxs formik={formik} />
        <SocialMedia />
        <View style={{ width: "85%", marginTop: "15pt" }}>
          <Text style={{ fontSize: 8, alignSelf: "flex-start" }}>
            -Alle Preise sind Endpreise und nach UStG umsatzsteuerfrei, soweit
            zu den einzelnen Angaben nichts Abweichendes angegeben ist.
          </Text>
          <Text style={{ fontSize: 8, alignSelf: "flex-start" }}>
            -Es gelten die AGB´s der KAL&ROK GmbH in ihrer aktuellsten Fassung
          </Text>
          <Text style={{ fontSize: 8, alignSelf: "flex-start" }}>
            -USt.-IdNr.: DE344111227 * IBAN:DE62 7605 0101 0014 7721 15 * BIC:
            SSKNDE77XXX
          </Text>
        </View>
      </>
    );
  else
    return (
      <View>
        <Text>Pdf Error</Text>
      </View>
    );
};

const SocialMedia = () => {
  return (
    <>
    <View
        style={{
          width: "85%",
          marginTop: "35pt",
          borderTopWidth: .5,
          borderColor: "red",
          paddingTop: "15pt",
          opacity:.7
        }}
      />
      <View
        style={{
          width: "80%",

          // marginTop: "35pt",
          // borderTopWidth: 1,
          // borderColor: "red",
          // paddingTop: "15pt",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            //   justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <View style={styles.CheckContainer}>
            <Image
              src={web}
              style={{
                width: 15,
                height: 15,
                objectFit: "contain",
                margin: 5,
              }}
            />
            <Text style={styles.text}>www.kal-rok.de</Text>
          </View>
          <View style={styles.CheckContainer}>
            <Image
              src={mail}
              style={{
                width: 15,
                height: 15,
                objectFit: "contain",
                margin: 5,
              }}
            />
            <Text style={styles.text}>info@kal-rok.de</Text>
          </View>
          <View style={styles.CheckContainer}>
            <Image
              src={facebook}
              style={{
                width: 15,
                height: 15,
                objectFit: "contain",
                margin: 5,
              }}
            />
            <Text style={styles.text}>kal&Rok GmbH</Text>
          </View>
        </View>
      </View>

      <View style={{ width: "80%", marginTop: "10pt" }}>
        <View
          style={{
            flexDirection: "row",
            //   justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <View style={styles.CheckContainer}>
            <Image
              src={phoneCall}
              style={{
                width: 15,
                height: 15,
                objectFit: "contain",
                margin: 5,
              }}
            />
            <Text style={styles.text}>+49 911 25391310</Text>
          </View>
          <View style={styles.CheckContainer}>
            <Image
              src={smartphone}
              style={{
                width: 15,
                height: 15,
                objectFit: "contain",
                margin: 5,
              }}
            />
            <Text style={styles.text}>+49 162 8020285</Text>
          </View>
          <View style={styles.CheckContainer}>
            <Image
              src={instagram}
              style={{
                width: 15,
                height: 15,
                objectFit: "contain",
                margin: 5,
              }}
            />
            <Text style={styles.text}>@kalrokgmbh</Text>
          </View>
        </View>
      </View>
    </>
  );
};

const Checkboxs = ({ formik }) => {
  return (
    <>
      <View style={{ width: "80%", marginTop: "15pt" }}>
        <Text
          style={{ ...styles.text, fontWeight: "600", marginBottom: "5pt" }}
        >
          Zahlungsart:
        </Text>
        <View
          style={{
            flexDirection: "row",
            //   justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <View style={styles.CheckContainer}>
            <View
              style={{
                ...styles.EmptyBox,
                // ...(formik.values.payment === 1 && { backgroundColor: "red" }),
              }}
            >
              {formik.values.payment === 1 ? (
                <Svg
                  style={{
                    width: 8,
                    height: 8,
                    display: "block",
                    margin: "auto",
                  }}
                >
                  <Line
                    x1="0"
                    y1="0"
                    x2="8"
                    y2="8"
                    strokeWidth={2}
                    stroke="rgb(0,0,0)"
                  />
                  <Line
                    x1="0"
                    y1="8"
                    x2="8"
                    y2="0"
                    strokeWidth={2}
                    stroke="rgb(0,0,0)"
                  />
                </Svg>
              ) : null}
            </View>
            <Text style={styles.text}>Bar</Text>
          </View>
          <View style={styles.CheckContainer}>
            <View
              style={{
                ...styles.EmptyBox,
                // ...(formik.values.payment === 2 && { backgroundColor: "red" }),
              }}
            >
              {formik.values.payment === 2 ? (
                <Svg
                  style={{
                    width: 8,
                    height: 8,
                    display: "block",
                    margin: "auto",
                  }}
                >
                  <Line
                    x1="0"
                    y1="0"
                    x2="8"
                    y2="8"
                    strokeWidth={2}
                    stroke="rgb(0,0,0)"
                  />
                  <Line
                    x1="0"
                    y1="8"
                    x2="8"
                    y2="0"
                    strokeWidth={2}
                    stroke="rgb(0,0,0)"
                  />
                </Svg>
              ) : null}
            </View>
            <Text style={styles.text}>Überweisung</Text>
          </View>
          <View style={styles.CheckContainer}>
            <View
              style={{
                ...styles.EmptyBox,
                // ...(formik.values.payment === 3 && { backgroundColor: "red" }),
              }}
            >
              {formik.values.payment === 3 ? (
                <Svg
                  style={{
                    width: 8,
                    height: 8,
                    display: "block",
                    margin: "auto",
                  }}
                >
                  <Line
                    x1="0"
                    y1="0"
                    x2="8"
                    y2="8"
                    strokeWidth={2}
                    stroke="rgb(0,0,0)"
                  />
                  <Line
                    x1="0"
                    y1="8"
                    x2="8"
                    y2="0"
                    strokeWidth={2}
                    stroke="rgb(0,0,0)"
                  />
                </Svg>
              ) : null}
            </View>
            <Text style={styles.text}>PayPal</Text>
          </View>
        </View>
      </View>
      <View style={{ width: "80%", marginTop: "15pt" }}>
        <Text
          style={{ ...styles.text, fontWeight: "600", marginBottom: "5pt" }}
        >
          Zustellungsart:
        </Text>
        <View
          style={{
            flexDirection: "row",
            //   justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <View style={styles.CheckContainer}>
            <View
              style={{
                ...styles.EmptyBox,
                // ...(formik.values.type === 1 && { backgroundColor: "red" }),
              }}
            >
              {formik.values.type === 1 ? (
                <Svg
                  style={{
                    width: 8,
                    height: 8,
                    display: "block",
                    margin: "auto",
                  }}
                >
                  <Line
                    x1="0"
                    y1="0"
                    x2="8"
                    y2="8"
                    strokeWidth={2}
                    stroke="rgb(0,0,0)"
                  />
                  <Line
                    x1="0"
                    y1="8"
                    x2="8"
                    y2="0"
                    strokeWidth={2}
                    stroke="rgb(0,0,0)"
                  />
                </Svg>
              ) : null}
            </View>
            <Text style={styles.text}>Übergabe</Text>
          </View>
          <View style={styles.CheckContainer}>
            <View
              style={{
                ...styles.EmptyBox,
                // ...(formik.values.type === 2 && { backgroundColor: "red" }),
              }}
            >
              {formik.values.type === 2 ? (
                <Svg
                  style={{
                    width: 8,
                    height: 8,
                    display: "block",
                    margin: "auto",
                  }}
                >
                  <Line
                    x1="0"
                    y1="0"
                    x2="8"
                    y2="8"
                    strokeWidth={2}
                    stroke="rgb(0,0,0)"
                  />
                  <Line
                    x1="0"
                    y1="8"
                    x2="8"
                    y2="0"
                    strokeWidth={2}
                    stroke="rgb(0,0,0)"
                  />
                </Svg>
              ) : null}
            </View>
            <Text style={styles.text}>Abholung in einer Filiale</Text>
          </View>
          <View style={styles.CheckContainer}>
            <View
              style={{
                ...styles.EmptyBox,
                // ...(formik.values.type === 3 && { backgroundColor: "red" }),
              }}
            >
              {formik.values.type === 3 ? (
                <Svg
                  style={{
                    width: 8,
                    height: 8,
                    display: "block",
                    margin: "auto",
                  }}
                >
                  <Line
                    x1="0"
                    y1="0"
                    x2="8"
                    y2="8"
                    strokeWidth={2}
                    stroke="rgb(0,0,0)"
                  />
                  <Line
                    x1="0"
                    y1="8"
                    x2="8"
                    y2="0"
                    strokeWidth={2}
                    stroke="rgb(0,0,0)"
                  />
                </Svg>
              ) : null}
            </View>
            <Text style={styles.text}>Hauslieferung</Text>
          </View>
        </View>
      </View>
    </>
  );
};

const PriceTable = ({ formik }) => {
  return (
    <>
      <View style={{ ...styles.thead, marginTop: "25pt" }}>
        <View style={styles.Lcell}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Sendung(en)
          </Text>
        </View>
        <View style={styles.Lcell}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Gewicht in Kg
          </Text>
        </View>
        <View style={styles.Rcell}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>Betrag</Text>
        </View>
      </View>
      {formik?.values?.content?.map((c, index) => (
        <View style={styles.tbody} key={index}>
          <View style={{ ...styles.Lcell, borderTopWidth: 0 }}>
            <Text style={{ ...styles.CellText }}>{c?.desc || " "}</Text>
          </View>
          <View style={{ ...styles.Mcell, borderTopWidth: 0 }}>
            <Text style={styles.CellText}>{c?.qty || " "}</Text>
          </View>
          <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
            <Text style={styles.CellText}>
              {c?.price || " "} {c?.price ? "€" : " "}{" "}
            </Text>
          </View>
        </View>
      ))}
      <View style={styles.tbody}>
        <View
          style={{
            ...styles.Lcell,
            borderTopWidth: 0,
            flexBasis: "66.66%",
            alignItems: "flex-start",
          }}
        >
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Weitere Berechnung
          </Text>
        </View>
        <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.priceSub || " "}{" "}
            {formik?.values?.priceSub ? "€" : " "}
          </Text>
        </View>
      </View>
      <View style={styles.tbody}>
        <View
          style={{
            ...styles.Lcell,
            borderTopWidth: 0,
            flexBasis: "66.66%",
            alignItems: "flex-start",
          }}
        >
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Gesamtbetrag
          </Text>
        </View>
        <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.priceTotal || " "}
            {formik?.values?.priceTotal ? "€" : " "}
          </Text>
        </View>
      </View>
    </>
  );
};

const TablePdf = ({ formik }) => {
  return (
    <>
      <View style={styles.thead}>
        <View style={{ flexBasis: "33.33%" }} />
        <View style={styles.Lcell}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Absender in DE
          </Text>
        </View>
        <View style={styles.Rcell}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Empfänger in MA
          </Text>
        </View>
      </View>
      <View style={styles.tbody}>
        <View style={{ ...styles.Lcell }}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Vor-u. Nachname
          </Text>
        </View>
        <View style={{ ...styles.Mcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.user?.name || " "}
          </Text>
        </View>
        <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.recipient?.name || " "}
          </Text>
        </View>
      </View>
      <View style={styles.tbody}>
        <View style={{ ...styles.Lcell, borderTopWidth: 0 }}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>
            Telefon-Nr.
          </Text>
        </View>
        <View style={{ ...styles.Mcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.user?.phone || " "}
          </Text>
        </View>
        <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.recipient?.phone || " "}
          </Text>
        </View>
      </View>
      <View style={styles.tbody}>
        <View style={{ ...styles.Lcell, borderTopWidth: 0 }}>
          <Text style={{ ...styles.CellText, fontWeight: "800" }}>Stadt</Text>
        </View>
        <View style={{ ...styles.Mcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.user?.address || " "}
          </Text>
        </View>
        <View style={{ ...styles.Rcell, borderTopWidth: 0 }}>
          <Text style={styles.CellText}>
            {formik?.values?.recipient?.address || " "}
          </Text>
        </View>
      </View>
    </>
  );
};

export default BillPdf;

const order = {
  user: {
    companieCode: "ABCDEFG",
    firstName: "user ",
    lastName: "testing",
    phone: "+212123456789",
    zipCode: "12345",
    address: "khouribga",
    tourName: "A",
    numofpackages: 5,
    SubsCribedCity: "Beni-Mellal",
  },
  recipient: {
    firstName: "Ayoub",
    lastName: "Test",
    phone: "+212123456789",
    address: "Casablanca",
  },
  contents: [
    {
      desc: "Tv",
      qty: "1",
      unit: "2",
      price: "102.00",
    },

    {
      desc: "Phone",
      qty: "1",
      unit: "2",
      price: "102.00",
    },
    {
      desc: "Boxes",
      qty: "1",
      unit: "2",
      price: "102.00",
    },
  ],
  CreatedAt: Date.now(),
  TrackingStatus: 0,
  verified: false,
  withTicket: false,
  withBill: false,
  withDeclar: false,
  type: 1,
  payment: 2,
  commandMode: "Validation",
  _id: "abcdef",
};
