import { ConfirmationNumber, ConfirmationNumberOutlined, DescriptionRounded, Info } from "@mui/icons-material";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import GavelRoundedIcon from "@mui/icons-material/GavelRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import PhotoLibraryRoundedIcon from "@mui/icons-material/PhotoLibraryRounded";
import QuizIcon from "@mui/icons-material/Quiz";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import TourRoundedIcon from "@mui/icons-material/TourRounded";
import WarehouseRoundedIcon from "@mui/icons-material/WarehouseRounded";
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import {
  Badge,
  Collapse,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useContext, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { LogginContext } from "../../App";
import { ToDu, ToMa } from "../../Tools/IconsFactory";
import { DraweContext } from "./Drawer";
const MyListItemText = styled(ListItemText)(({ theme, active, ischild }) => ({
  "& .MuiListItemText-primary": {
    textTransform: "uppercase",
    color: "rgb(99, 115, 129)",
    fontWeight: "300",
  },
  ...(ischild && {
    "& .MuiListItemText-primary": {
      fontFamily: "Cairo",
      textTransform: "uppercase",
      fontSize: ".8rem",
      color: theme.palette.text.buttonBg,
    },
  }),
  ...(active && {
    "& .MuiListItemText-primary": {
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      fontWeight: "500",
      ...(ischild && {
        fontSize: ".8rem",
        fontFamily: "Cairo",
      }),
    },
  }),
}));

const MyListItemIcon = styled(ListItemIcon)(({ theme, active, isfather }) => ({
  color: theme.palette.text.buttonBg,
  ...(isfather && {
    color: theme.palette.text.primary,
  }),
  ...(active && {
    color: theme.palette.primary.main,
  }),
}));

const DrawerItems = () => {
  const n = useNavigate();
  const { ToggleOpen } = useContext(DraweContext);
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const HandlMacth = (route) => {
    let match = useMatch(`${route}`);
    return match;
  };
  const navigate = (link) => {
    n(link);
    if (matches) ToggleOpen();
  };
  return (
    <List sx={{ py: 0 }}>
      {items.map((item, index) =>
        item.withChilds ? (
          <LinkChild
            item={item}
            key={index}
            HandlMacth={HandlMacth}
            navigate={navigate}
          />
        ) : (
          <NoLinkChild
            item={item}
            key={index}
            HandlMacth={HandlMacth}
            navigate={navigate}
          />
        )
      )}
    </List>
  );
};

const LinkChild = ({ item, HandlMacth, navigate }) => {
  const [open, setopen] = useState(false);
  let match = useMatch(`${item.link}/*`);
  useEffect(() => {
    if (!match) setopen(false);
  }, [match]);
  const handleToggle = () => {
    setopen(!open);
  };

  return (
    <>
      <ListItem
        button
        style={{ paddingRight: 0 }}
        onClick={handleToggle}
        sx={open ? classes.activeBorder : {}}
      >
        <MyListItemIcon active={open ? "true" : undefined} isfather={"true"}>
          {React.cloneElement(item.icon, { active: open ? "true" : undefined })}
        </MyListItemIcon>
        <MyListItemText
          primary={item.label}
          active={open ? "true" : undefined}
        />
        <ArrowDropDownRoundedIcon sx={open ? { color: "primary.main" } : {}} />
      </ListItem>
      <Collapse in={open} sx={classes.shadow}>
        {item.childs.map((child, index) => (
          <ListItem key={index} button onClick={() => navigate(child.link)}>
            <MyListItemIcon
              active={HandlMacth(child.link) ? "true" : undefined}
            >
              {child.isImg ? (
                <MyListItemIcon
                  active={HandlMacth(child.link) ? "true" : undefined}
                >
                  {child.icon}
                </MyListItemIcon>
              ) : (
                <Typography sx={classes.iconChild} variant="span">
                  {child.icon}
                </Typography>
              )}
            </MyListItemIcon>
            <MyListItemText
              ischild={"true"}
              primary={child.label}
              active={HandlMacth(child.link) ? "true" : undefined}
            />
          </ListItem>
        ))}
      </Collapse>
    </>
  );
};

const NoLinkChild = ({ item, HandlMacth, navigate }) => {
  const  {MsgNotif} = useContext(LogginContext)
  return (
    <ListItem
      button
      onClick={() => {
        navigate(item.link);
      }}
      sx={HandlMacth(item.link) ? classes.activeBorder : {}}
    >
      <MyListItemIcon
        active={HandlMacth(item.link) ? "true" : undefined}
        isfather={"true"}
      >
        {item.isBadged && MsgNotif > 0 ? (
          <Badge badgeContent={MsgNotif} color="primary">
            {item.icon}
          </Badge>
        ) : (
          item.icon
        )}
      </MyListItemIcon>
      <MyListItemText primary={item.label} active={HandlMacth(item.link)} />
    </ListItem>
  );
};

export default DrawerItems;

const classes = {
  activeBorder: {
    backgroundColor: "primary.lightBg",
    borderLeft: "2px solid",
    borderColor: "primary.main",
  },
  shadow: {
    boxShadow: "inset 0px 11px 8px -14px #ccc, inset 0px -6px 8px -10px #ccc",
  },
  iconChild: {
    marginLeft: "4px",
    width: "16px",
    height: "16px",
    lineHeight: "0px",
    fontSize: ".6rem",
    border: "2px solid",
    borderRadius: "50%",
    fontWeight: "900",
    display: "grid",
    placeItems: "center",
    fontFamily: "Cairo",
  },
};

const items = [
  {
    label: "Overview",
    icon: <GridViewRoundedIcon />,
    link: "/",
    active: true,
  },
  {
    withChilds: true,
    childs: [
      {
        label: "Validation",
        link: "/ToMa/validation",
        isImg: true,
        icon: <FactCheckIcon sx={{ width: ".8em", height: ".8em" }} />,
      },
      {
        label: "warehouse",
        link: "/ToMa/warehouse",
        isImg: true,
        icon: <WarehouseRoundedIcon sx={{ width: ".8em", height: ".8em" }} />,
      },
      {
        label: "Tracking",
        link: "/ToMa/tracking",
        isImg: true,
        icon: (
          <LocalShippingRoundedIcon sx={{ width: ".8em", height: ".8em" }} />
        ),
      },
    ],
    label: "To Morocco",
    link: "ToMa",
    icon: <ToMa  />,
  },
  {
    label: "From Morocco",
    link: "ToDu",
    icon: <ToDu  />,
  },
  {
    label: "bills",
    link: "bills",
    icon: <InventoryRoundedIcon />,
  },
  {
    label: "Tickets",
    link: "tickets",
    icon: <ConfirmationNumber />,
  },
  {
    label: "Declarations",
    link: "declaration",
    icon: <DescriptionRounded />,
  },
  {
    label: "Appointments",
    link: "appointments",
    icon: <DepartureBoardIcon />,
  },
  {
    label: "Prices",
    link: "prices",
    icon: <MonetizationOnRoundedIcon />,
  },
  {
    label: "Tours",
    link: "tours",
    icon: <TourRoundedIcon />,
  },
  {
    label: "Users",
    link: "users",
    icon: <PeopleRoundedIcon />,
  },
  {
    label: "Chat",
    link: "chat",
    icon: <ForumRoundedIcon />,
    isBadged: true,
  },

  {
    label: "Gallery",
    link: "gallery",
    icon: <PhotoLibraryRoundedIcon />,
  },
  {
    label: "Infos",
    link: "Infos",
    icon: <Info />,
  },
  {
    label: "Advertisement",
    link: "advertisement",
    icon: <CampaignRoundedIcon />,
  },
  {
    label: "Reviews",
    link: "reviews",
    icon: <StarRateRoundedIcon />,
  },
  {
    label: "AGB",
    link: "AGB",
    icon: <AssignmentRoundedIcon />,
  },
  {
    label: "Impressum",
    link: "impressum",
    icon: <GavelRoundedIcon />,
  },
  {
    label: "Privacy",
    link: "privacy",
    icon: <AdminPanelSettingsRoundedIcon />,
  },
  {
    label: "FAQ",
    link: "faq",
    icon: <QuizIcon />,
  },
];
