import {
  Close,
  Download,
  EmailOutlined,
  FacebookOutlined,
  Instagram,
  LanguageRounded,
  LocalPhoneOutlined,
  PhoneAndroidOutlined,
  PreviewRounded,
  Save,
  Send,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import { Button, Checkbox, IconButton, Stack, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { BlobProvider, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useFormik } from "formik";
import moment from "moment";
import * as React from "react";
import { useNavigate, useParams } from "react-router";
import maxios, { CommandsUrl, MessagesUrl } from "../../../api";
import { StatusContext } from "../../../App";
import logo from "../../../assets/pics/logo.png";
import BillPdf from "./BillPdf";
import PriceTable from "./PriceTable";
// Create styles
export const BillContext = React.createContext();
export default function BillGenerator() {
  const { dispatch } = React.useContext(StatusContext);
  const [BillData, setBillData] = React.useState({});
  const [preview, setPreview] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let { bill_id } = useParams();

  const defaultvalues = {
    _id: "",
    recipient: {
      name: "",
      phone: "",
      address: "",
    },
    content: [
      {
        desc: "",
        qty: "",
        price: "",
      },
    ],
    user: {
      _id: "",
      name: "",
      phone: "",
      address: "",
      photo: "no-photo",
      zipcode: "",
      cityId: "",
      Tname: "",
      code: "",
    },
    wights: 0,
    numofpackages: 0,
    history: false,
    finish: false,
    verified: false,
    withTicket: false,
    withBill: false,
    withDeclar: false,
    commandMode: "facturation",
    priceTotal: "",
    priceSub: "",
    type: 1,
    payment: 1,
    createdAt: "",
    rechnr: "",
    __v: 0,
  };

  const formik = useFormik({
    initialValues: defaultvalues,
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  const handleChange = (e) => {
    // e.preventDefault();
    formik.handleChange(e);
  };

  // const PushToFormik=()

  const GetBillData = async () => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const res = await maxios.get(`${CommandsUrl}?_id=${bill_id}`);
      if (res?.data?.data[0]) {
        setBillData({ ...res?.data?.data[0] });
        const content =
          res?.data?.data[0].content.length === 0
            ? [...res?.data?.data[0].content, { desc: "", qty: "", price: "" }]
            : [...res?.data?.data[0].content];
        formik.setValues({
          ...defaultvalues,
          ...res?.data?.data[0],
          user: {
            ...res?.data?.data[0].user,
            name: `${res?.data?.data[0].user.firstName} ${res?.data?.data[0].user.lastName}`,
          },
          recipient: {
            ...res?.data?.data[0].recipient,
            name: `${res?.data?.data[0].recipient.firstName} ${res?.data?.data[0].recipient.lastName}`,
          },
          content: content,
        });
      }

      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: "Bill Fetched" });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Bill not Found" });
    }
  };

  const UpdateOrder = async () => {
    try {
      dispatch({ type: "showLoading", payload: true });
      await maxios.put(`${CommandsUrl}/${formik.values._id}`, {
        ...formik.values,
        withBill: true,
      });
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: `Bill Saved` });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };
  const sendFileMessage = async (blob, id) => {
    try {
      const name = `${BillData.rechnr ?? ""}_${BillData.user.firstName ?? ""}_${
        BillData.user.lastName ?? ""
      }_${moment(formik.values.createdAt).format("DD-MM-YYYY") ?? ""}_Rech.pdf`;
      console.log(name);
      dispatch({ type: "showLoading", payload: true });
      var file = new File([blob], name, { type: blob.type });
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", file.type);
      formData.append("recieverId", id);
      const send = await maxios.post(MessagesUrl, formData);
      // if (send.data) HandleLivechat({ ...Livechat });
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: `Bill Sent` });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: `Server Error` });

      console.log(error);
    }
  };
  React.useEffect(() => {
    if (bill_id) GetBillData();
  }, [bill_id]);

  return (
    <BillContext.Provider
      value={{
        preview,
        setPreview,
        formik,
        handleChange,
        UpdateOrder,
        sendFileMessage,
      }}
    >
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Bill />
        </div>
      </Modal>
    </BillContext.Provider>
  );
}

const Bill = () => {
  const {
    preview,
    setPreview,
    formik,
    handleChange,
    UpdateOrder,
    sendFileMessage,
  } = React.useContext(BillContext);
  const navigation = useNavigate();

  // const SendBill = (blob) => {
  //   var file = new File([blob], "bill", { type: blob.type });
  //   console.log(file);
  // };

  return (
    <Box sx={classes.root}>
      {preview ? (
        <PdfPreview />
      ) : (
        <>
          <Stack direction={"column"} spacing={5} alignItems="center">
            <IconButton
              onClick={() => navigation(-1)}
              sx={{ alignSelf: "flex-end" }}
            >
              <Close />
            </IconButton>
            <Header />
            <InputsHeader />
            <InfosTable />
            <PriceTable />
            <Box width={"80%"}>
              <TextField
                variant="outlined"
                label="Anzahl der Pakete/Sendungen"
                sx={{ width: "40%" }}
                name="numofpackages"
                value={formik.values.numofpackages}
                onChange={handleChange}
              />
            </Box>
            <Checkboxs />
            {/* <SocialMedia /> */}
            {/* <Footer /> */}
          </Stack>
          <Stack
            direction={"row"}
            spacing={4}
            justifyContent="flex-end"
            sx={{ mt: 4, mb: 4 }}
          >
            <Button
              onClick={UpdateOrder}
              variant="contained"
              color="success"
              startIcon={<Save />}
            >
              Speichern
            </Button>
            <Button
              variant="contained"
              color="info"
              startIcon={<PreviewRounded />}
              onClick={() => setPreview(true)}
            >
              Vorschau
            </Button>
            <BlobProvider document={<BillPdf formik={formik} />}>
              {({ blob, url, loading, error }) => (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Send />}
                  onClick={() => sendFileMessage(blob, formik.values.user._id)}
                >
                  {loading ? "Loading Pdf" : "Senden an Kunden"}
                </Button>
              )}
            </BlobProvider>
            <PDFDownloadLink
              document={<BillPdf formik={formik} />}
              fileName={`${formik.values?.rechnr ?? ""}_${
                formik.values.user.name ?? ""
              }_${
                moment(formik.values.createdAt).format("DD-MM-YYYY") ?? ""
              }_Rech.pdf`}
              style={{ textDecoration: "none" }}
            >
              {({ blob, url, loading, error }) => (
                <Button
                  variant="contained"
                  color="warning"
                  startIcon={<Download />}
                  disabled={loading}
                >
                  {loading ? "Loading Pdf" : "Download"}
                </Button>
              )}
            </PDFDownloadLink>

            {/* <Button variant="contained">Preview</Button> */}
          </Stack>
        </>
      )}
    </Box>
  );
};

const PdfPreview = () => {
  const { setPreview, formik } = React.useContext(BillContext);
  return (
    <Stack
      style={{ width: "100%", height: "100vh" }}
      direction="column"
      justifyContent={"flex-end"}
    >
      <IconButton
        onClick={() => setPreview(false)}
        sx={{ alignSelf: "flex-end" }}
      >
        <Close />
      </IconButton>
      <PDFViewer style={{ width: "100%", height: "95%" }}>
        <BillPdf formik={formik} />
      </PDFViewer>
    </Stack>
  );
};

const Footer = () => {
  return (
    <Stack direction="column" spacing={1} sx={{ mb: "50px !important" }}>
      <Typography textAlign={"left"}>
        -Alle Preise sind Endpreise und nach UStG umsatzsteuerfrei, soweit zu
        den einzelnen Angaben nichts Abweichendes angegeben ist.
      </Typography>
      <Typography textAlign={"left"}>
        - Es gelten die AGB´s der KAL&ROK GmbH in ihrer aktuellsten Fassung
      </Typography>
      <Typography>
        -USt.-IdNr.: DE344111227 * IBAN:DE62 7605 0101 0014 7721 15 * BIC:
        SSKNDE77XXX
      </Typography>
    </Stack>
  );
};
const SocialMedia = () => {
  return (
    <Stack
      direction={"row"}
      flexWrap="wrap"
      width={"80%"}
      justifyContent="space-between"
      alignItems={"center"}
      rowGap={4}
      sx={{
        mt: "100px !important",
        // mb: "100px !important",
        pb: 5,
        borderBottom: "2px solid",
        borderColor: "primary.main",
      }}
    >
      <Stack
        direction={"row"}
        spacing={1}
        alignItems="center"
        sx={{ flexBasis: "33%" }}
        justifyContent="center"
      >
        <LanguageRounded sx={{ fontSize: 40, color: "primary.main" }} />
        <Typography sx={{ fontWeight: "bold" }}>www.kal-rok.de</Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={1}
        alignItems="center"
        sx={{ flexBasis: "33%" }}
        justifyContent="center"
      >
        <EmailOutlined sx={{ fontSize: 40, color: "primary.main" }} />
        <Typography sx={{ fontWeight: "bold" }}>info@kal-rok.de</Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={1}
        alignItems="center"
        sx={{ flexBasis: "33%" }}
        justifyContent="center"
      >
        <FacebookOutlined sx={{ fontSize: 40, color: "primary.main" }} />
        <Typography sx={{ fontWeight: "bold" }}>kal&Rok GmbH</Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={1}
        alignItems="center"
        sx={{ flexBasis: "33%" }}
        justifyContent="center"
      >
        <LocalPhoneOutlined sx={{ fontSize: 40, color: "primary.main" }} />
        <Typography sx={{ fontWeight: "bold" }}>+49 911 25391310</Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={1}
        alignItems="center"
        sx={{ flexBasis: "33%" }}
        justifyContent="center"
      >
        <PhoneAndroidOutlined sx={{ fontSize: 40, color: "primary.main" }} />
        <Typography sx={{ fontWeight: "bold" }}>+49 162 8020285</Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={1}
        alignItems="center"
        sx={{ flexBasis: "33%" }}
        justifyContent="center"
      >
        <Instagram sx={{ fontSize: 40, color: "primary.main" }} />
        <Typography sx={{ fontWeight: "bold" }}>@kalrokgmbh</Typography>
      </Stack>
    </Stack>
  );
};

const Checkboxs = () => {
  const { formik } = React.useContext(BillContext);

  return (
    <Stack direction={"column"} spacing={2} sx={{ width: "80%" }}>
      <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
        <Typography sx={{ fontWeight: "bold" }}>Zahlungsart:</Typography>
        <Stack direction={"row"} justifyContent="space-between">
          <Stack
            direction={"row"}
            spacing={0.5}
            alignItems="center"
            sx={{ flexBasis: "33%" }}
          >
            <Checkbox
              inputProps={{ "aria-label": "controlled" }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              checked={formik.values.payment === 1}
              onChange={() => formik.setFieldValue("payment", 1)}
            />
            <Typography variant="span">Bar</Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={0.5}
            alignItems="center"
            sx={{ flexBasis: "33%" }}
          >
            <Checkbox
              inputProps={{ "aria-label": "controlled" }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              checked={formik.values.payment === 2}
              onChange={() => formik.setFieldValue("payment", 2)}
            />
            <Typography variant="span">Überweisung</Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={0.5}
            alignItems="center"
            sx={{ flexBasis: "33%" }}
          >
            <Checkbox
              inputProps={{ "aria-label": "controlled" }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              checked={formik.values.payment === 3}
              onChange={() => formik.setFieldValue("payment", 3)}
            />
            <Typography variant="span">PayPal</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
        <Typography sx={{ fontWeight: "bold" }}>Zustellungsart:</Typography>
        <Stack direction={"row"} justifyContent="space-between">
          <Stack
            direction={"row"}
            spacing={0.5}
            alignItems="center"
            sx={{ flexBasis: "33%" }}
          >
            <Checkbox
              inputProps={{ "aria-label": "controlled" }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              checked={formik.values.type === 1}
              onChange={() => formik.setFieldValue("type", 1)}
            />
            <Typography variant="span">Übergabe</Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={0.5}
            alignItems="center"
            sx={{ flexBasis: "33%" }}
          >
            <Checkbox
              inputProps={{ "aria-label": "controlled" }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              checked={formik.values.type === 2}
              onChange={() => formik.setFieldValue("type", 2)}
            />
            <Typography variant="span">Abholung in einer Filiale</Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={0.5}
            alignItems="center"
            sx={{ flexBasis: "33%" }}
          >
            <Checkbox
              inputProps={{ "aria-label": "controlled" }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              checked={formik.values.type === 3}
              onChange={() => formik.setFieldValue("type", 3)}
            />
            <Typography variant="span">Hauslieferung</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const Header = () => {
  return (
    <>
      <Stack direction={"column"} spacing={0} alignItems="center">
        <img src={logo} style={classes.logo} />
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          style={classes.title}
        >
          Leopoldstr. 11a 90439 Nürnberg
        </Typography>
      </Stack>
      <Typography
        id="modal-modal-description"
        sx={{ mt: 2 }}
        variant="h6"
        component="h2"
      >
        Rechnung
      </Typography>
    </>
  );
};

const InputsHeader = () => {
  const { formik, handleChange } = React.useContext(BillContext);

  return (
    <Stack
      direction={"row"}
      spacing={2}
      justifyContent="space-between"
      sx={{ width: "80%" }}
    >
      <TextField
        id="Rechnung-Nr."
        label="Rechnung-Nr."
        variant="outlined"
        name="billCode"
        value={formik.values?.rechnr ?? ""}
        onChange={handleChange}
        sx={{ flexGrow: 1 }}
      />
      <TextField
        id="Kunden-Nr"
        label="Kunden-Nr."
        variant="outlined"
        sx={{ flexGrow: 1 }}
        value={formik.values.user.code ?? ""}
        onChange={handleChange}
        name="user.code"
      />
      <LocalizationProvider dateAdapter={DateAdapter} sx={{ flexGrow: 1 }}>
        <DatePicker
          label="Datum"
          inputFormat="dd/MM/yyyy"
          sx={{ flexGrow: 1 }}
          value={formik.values.createdAt ?? ""}
          onChange={(value) => formik.setFieldValue("createdAt", value)}
          name="createdAt"
          renderInput={(params) => (
            <TextField {...params} sx={{ flexGrow: 1 }} />
          )}
        />
      </LocalizationProvider>
    </Stack>
  );
};

const InfosTable = () => {
  const { formik, handleChange } = React.useContext(BillContext);

  return (
    <Stack
      direction={"row"}
      spacing={5}
      justifyContent="space-between"
      width={"50%"}
    >
      <Stack direction={"column"} width={"100%"} spacing={3}>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "sans-serif",
            fontWeight: "bold",
          }}
        >
          Absender in DE
        </Typography>
        <TextField
          id="Vor-u. Nachname"
          label="Vor-u. Nachname"
          variant="outlined"
          sx={{ flexGrow: 1 }}
          value={formik.values.user.name ?? ""}
          onChange={handleChange}
          name="user.name"
        />
        <TextField
          id="Telefon-Nr."
          label="Telefon-Nr."
          variant="outlined"
          sx={{ flexGrow: 1 }}
          value={formik.values.user.phone ?? ""}
          onChange={handleChange}
          name="user.phone"
        />
        <TextField
          id="Stadt"
          label="Stadt"
          variant="outlined"
          sx={{ flexGrow: 1 }}
          value={formik.values.user.address ?? ""}
          onChange={handleChange}
          name="user.address"
        />
      </Stack>
      <Stack direction={"column"} width={"100%"} spacing={3}>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "sans-serif",
            fontWeight: "bold",
          }}
        >
          Empfänger in MA
        </Typography>
        <TextField
          id="Vor-u. Nachname"
          label="Vor-u. Nachname"
          variant="outlined"
          sx={{ flexGrow: 1 }}
          value={formik.values.recipient.name ?? ""}
          onChange={handleChange}
          name="recipient.name"
        />
        <TextField
          id="Telefon-Nr."
          label="Telefon-Nr."
          variant="outlined"
          sx={{ flexGrow: 1 }}
          value={formik.values.recipient.phone ?? ""}
          onChange={handleChange}
          name="recipient.phone"
        />
        <TextField
          id="Stadt"
          label="Stadt"
          variant="outlined"
          sx={{ flexGrow: 1 }}
          value={formik.values.recipient.address ?? ""}
          onChange={handleChange}
          name="recipient.address"
        />
      </Stack>
    </Stack>
  );
};

const classes = {
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    minHeight: "100vh",
    height: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  },
  logo: {
    objectFit: "contain",
    width: 200,
    height: 180,
  },
  title: {
    fontWeight: 300,
  },
};
