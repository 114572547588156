import { Stack } from "@mui/material";
import React, { createContext, useContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import axios from "../../../api";
import { StatusContext } from "../../../App";
import MsgsDialog from "../Users/MsgsDialog";
import { CreateGroupButton } from "./GroupsRoot";
import TableOrders from "./TableOrders";
export const GroupAContext = createContext();
export default function Validation({
  GroupData,
  setGroupAData,
  setCreateGroup,
  CreateGroupStatus,
  Switchgroups,
  DeleteCommands,
  isSwitched,
}) {
  const { dispatch } = useContext(StatusContext);
  const [selected, setSelected] = React.useState([]);
  const navigate = useNavigate();
  const fetchGroupA = async () => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const res = await axios.get(`/api/v1/trips?name=A`);
      if (res.data.count > 0) {
        // const id = res.data.data[0]._id;
        const cmds = await axios.get(
          `/api/v1/commands?tripGroup=A&&commandMode=Validation`
        );
        setGroupAData([...cmds.data.data]);
        dispatch({ type: "showLoading", payload: false });
        dispatch({
          type: "showSuccess",
          payload: "CommandsTo Morocco Fetched",
        });
      } else {
        dispatch({ type: "showLoading", payload: false });
        dispatch({ type: "showSuccess", payload: "Create Group" });
        setCreateGroup(true);
      }
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };
  const openChat = () => {
    navigate("sendMessages");
  };
  React.useEffect(() => {
    const fetch = async () => {
      await fetchGroupA();
    };
    fetch();
  }, [CreateGroupStatus, isSwitched]);

  return (
    <GroupAContext.Provider value={{ GroupData, setGroupAData }}>
      {CreateGroupStatus === true ? (
        <CreateGroupButton name="A" setCreateGroup={setCreateGroup} />
      ) : (
        <>
          <Stack direction="column" spacing={3}>
            {/* <TableTimeLine Context={GroupAContext} /> */}

            <TableOrders
              Context={GroupAContext}
              Data={GroupData}
              Switchgroups={Switchgroups}
              gname={"A"}
              toname={"B"}
              label={{ label: "Validation", type: "validation" }}
              DeleteCommands={DeleteCommands}
              isSwitched={isSwitched}
              selected={selected}
              setSelected={setSelected}
              openChat={openChat}
            />
          </Stack>
          <Routes>
            <Route
              path="/sendMessages"
              element={
                <MsgsDialog selected={selected} userStates={GroupData} />
              }
            />
          </Routes>
        </>
      )}
    </GroupAContext.Provider>
  );
}
