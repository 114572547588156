import axios from "axios";
// export const BaseUrl = "https://kalrok.herokuapp.com";
export const BaseUrl = "https://kalandrok.live";
// export const BaseUrl = "http://167.71.61.3";
// const token = localStorage.getItem("token");
// export const BaseUrl = "http://localhost:5000";
const maxios = axios.create({
  baseURL: BaseUrl,
  headers: {
    "Access-Control-Allow-Origin": "https://kalandrok.live",
  },
});
maxios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${
      localStorage.getItem("token") ?? ""
    }`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

maxios.interceptors.response.use(undefined, function (response) {
  if (response?.response?.status === 401) localStorage.clear();
  return response;
});

export default maxios;

export const AGBUrl = "/api/v1/agb";
export const IMPUrl = "/api/v1/impressum";
export const GroupsUrl = "/api/v1/trips";
export const RolesUrl = "/api/v1/roles";
export const FaqsUrl = "/api/v1/faq";
export const ToursUrl = "/api/v1/tours";
export const PricesUrl = "/api/v1/prices";
export const WeightPricesUrl = "/api/v1/weigthprice";
export const UsersUrl = "/api/v1/auth/users";
export const ApUrl = "/api/v1/appointment";
export const GalleryUrl = "/api/v1/gallery";
export const InfosUrl = "/api/v1/info";
export const FilesUrl = "/api/v1/auth/getfile";
export const AdsUrl = "/api/v1/adds";
export const ReviewsScoreUrl = "/api/v1/reviews/rating";
export const ReviewsCommantsUrl = "/api/v1/reviews";
export const authUrl = "/api/v1/auth";
export const MeUrl = "/api/v1/auth/me";
export const CommandsUrl = "/api/v1/commands";
export const ConvsUrl = "/api/v1/conversation";
export const ResetPswdUrl = "/api/v1/auth/resetpassword";
export const ReqPwdUrl = "/api/v1/auth/forgetpassword";
export const MessagesUrl = "/api/v1/message";
