import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import maxios, { authUrl } from "../api";
import { LogginContext, StatusContext } from "../App";
import logo from "../assets/pics/logo.png";
import ForgotPassword from "./ForgotPassword";
export default function LoginForm() {
  const { Loggin, setUserData } = useContext(LogginContext);
  const { dispatch } = useContext(StatusContext);
  const [loading, setLoading] = useState(false);
  const [Fdialog, setFdialog] = useState(false);
  const formik = useFormik({
    initialValues: {
      phone: "+212",
      password: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      dispatch({ type: "showLoading", payload: true });
      await auth(values.phone, values.password);
      dispatch({ type: "showLoading", payload: false });
      setLoading(false);
    },
  });

  const auth = async (phone, password) => {
    try {
      const data = JSON.stringify({ phone: phone, password: password });
      const res = await maxios.post(`${authUrl}/login`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.data.role === "admin") {
        setUserData(res.data.user);
        Loggin(res.data.token);
        localStorage.setItem("id", res.data.user._id);
        dispatch({ type: "showSuccess", payload: "You are Connected" });
      }
    } catch (error) {
      dispatch({ type: "showError", payload: "Phone or Password incorrect" });
    }
  };

  const CheckForNumbers = (e) => {
    const re = /^[+\d](?:.*\d)?$/;
    if (e.target.value === "" || re.test(e.target.value))
      formik.handleChange(e);
  };

  return (
    <div style={classes.root}>
      <Paper sx={classes.LoginWrapper}>
        <img src={logo} alt="logo" style={classes.logo} />
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Stack
            direction="column"
            spacing={3}
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <LoginStyledField
              label="Phone number *"
              variant="outlined"
              fullWidth
              type="text"
              name="phone"
              placeholder="+4200000000"
              onChange={CheckForNumbers}
              // onChange={formik.handleChange}
              value={formik.values.phone}
            />

            <LoginStyledField
              label="Password"
              variant="outlined"
              fullWidth
              type="password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <Typography
              onClick={() => setFdialog(true)}
              sx={{ cursor: "pointer" }}
            >
              Forgot password ?
            </Typography>
            <Button
              sx={classes.LoginButton}
              variant="contained"
              fullWidth
              type="submit"
              disabled={
                formik.values.phone === "" ||
                formik.values.password === "" ||
                loading === true
              }
            >
              Login
            </Button>
          </Stack>
        </form>
      </Paper>
      <ForgotPassword open={Fdialog} setFdialog={setFdialog} />
    </div>
  );
}

export const LoginStyledField = styled(TextField)({
  maxWidth: "400px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "10px",
    },
  },
});

const classes = {
  root: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EDF2F6",
  },
  LoginWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    p: { xs: "1rem", md: "2rem" },
    boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px",
    borderRadius: { xs: "0", md: "10px" },
    width: { xs: "100%", md: "auto" },
    minHeight: { xs: "calc(100vh - 2rem)", md: "500px" },
  },
  loginLabel: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "600",
  },
  logo: {
    width: "clamp(6rem, 50%,15rem)",
    paddingBottom: "4em",
  },
  LoginButton: {
    maxWidth: "200px",
    height: "40px",
    borderRadius: "10px",
  },
};
