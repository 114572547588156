import MenuIcon from "@mui/icons-material/Menu";
import {
  IconButton,
  Slide,
  Stack,
  styled,
  useScrollTrigger,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { default as React, useContext } from "react";
import { useLocation } from "react-router-dom";
import man from "../../assets/pics/logo1.svg";
import { DraweContext, webDrawerWidth } from "./Drawer";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  width: `calc(100%)`,
  // transition: theme.transitions.create(["width", "margin"], {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
  ...(open && {
    marginLeft: webDrawerWidth,
    width: `calc(100% - ${webDrawerWidth}px)`,
    [theme.breakpoints.down("sm")]: {
      width: 0,
      marginLeft: 0,
    },
  }),
}));

function ElevationScroll(props) {
  const { children } = props;
  return (
    <Slide appear={false} direction="down" in={true}>
      {children}
    </Slide>
  );
}

const Header = () => {
  const { open, ToggleOpen } = useContext(DraweContext);
  const location = useLocation();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  return (
    <ElevationScroll>
      <AppBar
        position="fixed"
        elevation={0}
        open={open}
        sx={{
          backgroundColor: "transparent",
          backdropFilter: "blur(4px)",

          transition: "all .2s ease",
          ...(trigger && {
            backgroundColor: "#FFFFFF8a",
            boxShadow:
              "rgb(0 0 0 / 19%) 0px -2px 0px, rgb(0 0 0 / 23%) 0px 3px 6px",
          }),
        }}
      >
        <Toolbar sx={{ paddingLeft: "0" }}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={ToggleOpen}
            sx={{
              mr: 2,
              marginLeft: "8px",
              display: { xs: "flex", sm: "none" },
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Stack direction="column" sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                ...classes.HeadTitle,
                ...(!open && {
                  ml: { xs: 0, sm: 6 },
                }),
              }}
            >
              {location.pathname === "/"
                ? "Overview"
                : location.pathname.split("/", 2) ?? "Overview"}
            </Typography>
          </Stack>
          <Box>
            <IconButton>
              <Avatar
                alt="Remy Sharp"
                src={man}
                sx={{ width: "3.5rem", height: "3.5rem" }}
                imgProps={{
                  style: {
                    objectFit: "contain",
                  },
                }}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
};
const classes = {
  HeadTitle: {
    fontSize: "clamp(.9rem, 4vw,1.5rem)",
    fontWeight: "600",
    color: "primary.main",
    textTransform: "uppercase",
    fontFamily: "Cairo",
  },
  HeadSubTitle: {
    flexGrow: 1,
    fontSize: "clamp(.2rem, 3vw,1rem)",
    fontWeight: "300",
    textTransform: "capitalize",
    color: "secondary.main",
  },
};
export default Header;
