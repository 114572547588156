import * as React from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    background: "linear-gradient(225deg, #92FE9D 0%, #00C9FF 100%)",
    color: "#92FE9D",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function CosAvatar({
  avatar,
  online,
  imgProps,
  isSmall,
  style,
}) {
  return online ? (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      variant="dot"
    >
      <Avatar
        alt="Users"
        src={avatar}
        sx={
          isSmall
            ? { width: "40px", height: "40px" }
            : { width: "60px", height: "60px" }
        }
        imgProps={imgProps}
      />
    </StyledBadge>
  ) : (
    <Avatar
      alt="Users"
      imgProps={imgProps}
      src={avatar}
      sx={
        isSmall
          ? { width: "40px", height: "40px" }
          : { width: "60px", height: "60px" }
      }
      style={style ?? {}}
    />
  );
}
