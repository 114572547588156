import { DeleteOutlineRounded } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, Stack, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useContext } from "react";
import { DecContext } from "./DecGenerator";
export default function DecTable() {
  // const [rowsInputs, setrowsInputs] = React.useState([]);
  const { formik, handleChange } = useContext(DecContext);

  // React.useEffect(() => {
  //   setrowsInputs([...rows]);
  // }, []);

  const AddRow = () => {
    formik.setFieldValue("declarationConten", [
      ...formik.values.declarationConten,
      { desc: "", legal: "", notice: "" },
    ]);
  };
  const DeleteRow = (rindex) => {
    const res = formik.values.declarationConten.filter((e, index) => index !== rindex);
    formik.setFieldValue("declarationConten", [...(res ?? [])]);
  };

  return (
    <Table sx={{ minWidth: 500, width: "80%" }} aria-label="spanning table">
      <TableHead>
        <TableRow sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}>
          <TableCell sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>
          Artikelbezeichnung
          </TableCell>
          <TableCell align="left">Anzahl</TableCell>
          <TableCell
            align="left"
            sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
          >
            Wert
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <RowsMap AddRow={AddRow} DeleteRow={DeleteRow} />
    
      </TableBody>
    </Table>
  );
}

const RowsMap = ({ AddRow, DeleteRow }) => {
  const { formik, handleChange } = useContext(DecContext);
  return formik.values?.declarationConten?.map((row, index) => (
    //   <Stack direction={"row"} sx={{width:"100%"}}>
    <TableRow key={index} sx={{ flexGrow: 1 }}>
      <TableCell sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>
        <TextField
          variant="standard"
          value={formik.values.declarationConten[index].desc}
          name={`declarationConten[${index}].desc`}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell align="left">
        <TextField
          variant="standard"
          value={formik.values.declarationConten[index].legal}
          name={`declarationConten[${index}].legal`}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell
        align="left"
        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
      >
        <TextField
          variant="standard"
          value={formik.values.declarationConten[index].notice}
          name={`declarationConten[${index}].notice`}
          onChange={handleChange}
        />
      </TableCell>
      {/* <TableCell align="left" sx={{ border: "none" }}> */}
        <Stack
          direction={"row"}
          sx={{ position: "absolute", marginLeft: 2 }}
          spacing={2}
        >
          {index < formik.values.declarationConten.length - 1 ? null : (
            <IconButton color="success" onClick={AddRow}>
              <AddIcon />
            </IconButton>
          )}
          {formik.values.declarationConten.length === 1 ? null : (
            <IconButton color="primary" onClick={() => DeleteRow(index)}>
              <DeleteOutlineRounded />
            </IconButton>
          )}
        </Stack>
      {/* </TableCell> */}
    </TableRow>
  ));
};
