import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import React, { useContext, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { DraweContext } from "./Drawer/Drawer";
// import Overview from "./Routes/Overview/Overview";
// import Chat from "./Routes/Chat/chat";
import notfound from "../assets/pics/404.svg";
import Loading from "./Loading";
// import Tours from "./Routes/Tours/Tours";
// import GroupsRoot from "./Routes/Groups/GroupsRoot";
// import NavigationStatus from "./Drawer/Breadcrumbs";
// import Users from "./Routes/Users/Users";
// import Prices from "./Routes/Prices/Prices";
// import Gallery from "./Routes/Gallery/Gallery";
// import AGB from "./Routes/AGB/AGB";
// import Imperssum from "./Routes/Iperssum/Imperssum";
// import Privacy from "./Routes/Privacy/Privacy";
// import FAQ from "./Routes/FAQ/FAQ";
// import Ads from "./Routes/Ads/Ads";
// import Appointments from "./Routes/Appointments/Appointments";
// import Reviews from "./Routes/Reviews/Reviews";
import { useMediaQuery } from "@mui/material";
import Bill from "./Routes/Bills/Bill";
import Doc from "./Routes/Doc/Doc";
import ToDu from "./ToDu/ToDu";
import Tickets from "./Routes/Tickets/Tickets";
const Overview = React.lazy(() => import("./Routes/Overview/Overview"));
const GroupsRoot = React.lazy(() => import("./Routes/Groups/GroupsRoot"));
const Tours = React.lazy(() => import("./Routes/Tours/Tours"));
const Users = React.lazy(() => import("./Routes/Users/Users"));
const Chat = React.lazy(() => import("./Routes/Chat/chat"));
const Prices = React.lazy(() => import("./Routes/Prices/Prices"));
const Ads = React.lazy(() => import("./Routes/Ads/Ads"));
const Imperssum = React.lazy(() => import("./Routes/Iperssum/Imperssum"));
const Gallery = React.lazy(() => import("./Routes/Gallery/Gallery"));
const FAQ = React.lazy(() => import("./Routes/FAQ/FAQ"));
const Privacy = React.lazy(() => import("./Routes/Privacy/Privacy"));
const Appointments = React.lazy(() =>
  import("./Routes/Appointments/Appointments")
);
const Reviews = React.lazy(() => import("./Routes/Reviews/Reviews"));
const AGB = React.lazy(() => import("./Routes/AGB/AGB"));
const Infos = React.lazy(() => import("./Routes/Infos/Infos"));
const MainBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  overflow: "hidden",
  flexGrow: 1,
  ...(open && {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }),
}));

const Main = () => {
  const { open } = useContext(DraweContext);
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Suspense fallback={<Loading />}>
      <MainBox open={open} component="main">
        <Toolbar />
        {/* {matches ? <Toolbar /> : false} */}
        <Container
          maxWidth="xl"
          disableGutters
          sx={{ ...{ p: "16px", mt: "20px" }, ...(matches && { mt: 0 }) }}
        >
          <WebappRoutes />
        </Container>
      </MainBox>
    </Suspense>
  );
};

const WebappRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Overview />} />
      <Route path="ToMa/*" element={<GroupsRoot />} />
      <Route path="ToDu/*" element={<ToDu />} />
      <Route path="bills/*" element={<Bill />} />
      <Route path="declaration/*" element={<Doc />} />
      <Route path="tickets/*" element={<Tickets />} />
      
      <Route path="tours/*" element={<Tours />} />
      <Route path="users/*" element={<Users />} />
      <Route path="chat" element={<Chat />} />
      <Route path="prices/*" element={<Prices />} />
      <Route path="advertisement/*" element={<Ads />} />
      <Route path="impressum/*" element={<Imperssum />} />
      <Route path="gallery/*" element={<Gallery />} />
      <Route path="Infos/*" element={<Infos />} />
      <Route path="agb/*" element={<AGB />} />
      <Route path="faq/*" element={<FAQ />} />
      <Route path="privacy/*" element={<Privacy />} />
      <Route path="appointments" element={<Appointments />} />
      <Route path="reviews" element={<Reviews withAll={true} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const NotFound = () => {
  return <Box sx={classes.notFound} />;
};
const classes = {
  notFound: {
    height: "calc(100vh - 120px)",
    width: "100%",
    background: `url(${notfound}) center no-repeat`,
    backgroundSize: "contain",
  },
};

export default Main;
