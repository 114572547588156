import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import maxios, { CommandsUrl } from "../../../api";
import { StatusContext } from "../../../App";
import GroupA from "./GroupA";
import GroupB from "./GroupB";
import Validation from "./Validation";

export default function GroupsRoot() {
  const [GroupDataA, setGroupAData] = useState([]);
  const [GroupDataB, setGroupBData] = useState({});
  const [isSwitched, setisSwitched] = useState(false);
  const [CreateGroupStatus, setCreateGroup] = useState(false);
  const { dispatch } = useContext(StatusContext);

  const Switchgroups = async (commands, group) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      await commands.map(async (e) => {
        await maxios.put(`${CommandsUrl}/${e}`, {
          commandMode: group,
        });
      });

      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: `Orders Switched` });
      setisSwitched(!isSwitched);
    } catch (error) {
      console.log(error);
      setisSwitched(!isSwitched);
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };

  const Verifygroups = async (commands, group) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      await commands.map(async (e) => {
        await maxios.put(`${CommandsUrl}/${e}`, {
          verified: true,
        });
      });

      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: `Orders Verified` });
      setisSwitched(!isSwitched);
    } catch (error) {
      console.log(error);
      setisSwitched(!isSwitched);
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };

  const DeleteCommands = async (users) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      await users.map(async (e) => {
        await maxios.delete(`${CommandsUrl}/${e}`);
      });
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: `Commands Deleted` });
      setisSwitched(!isSwitched);
    } catch (error) {
      console.log(error);
      setisSwitched(!isSwitched);
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };

  return (
    <Routes>
      <Route
        path="/validation/*"
        element={
          <Validation
            GroupData={GroupDataA}
            setGroupAData={setGroupAData}
            CreateGroupStatus={CreateGroupStatus}
            setCreateGroup={setCreateGroup}
            Switchgroups={Switchgroups}
            isSwitched={isSwitched}
            DeleteCommands={DeleteCommands}
            Verifygroups={Verifygroups}
          />
        }
      />
      <Route
        path="/warehouse/*"
        element={
          <GroupB
            GroupData={GroupDataA}
            setGroupAData={setGroupAData}
            CreateGroupStatus={CreateGroupStatus}
            setCreateGroup={setCreateGroup}
            Switchgroups={Switchgroups}
            isSwitched={isSwitched}
            DeleteCommands={DeleteCommands}
            Verifygroups={Verifygroups}
          />
        }
      />
      <Route
        path="/tracking/*"
        element={
          <GroupA
            GroupData={GroupDataA}
            setGroupAData={setGroupAData}
            CreateGroupStatus={CreateGroupStatus}
            setCreateGroup={setCreateGroup}
            Switchgroups={Switchgroups}
            isSwitched={isSwitched}
            setisSwitched={setisSwitched}
            DeleteCommands={DeleteCommands}
            Verifygroups={Verifygroups}
          />
        }
      />
    </Routes>
  );
}

export const CreateGroupButton = ({ name, setCreateGroup }) => {
  const { dispatch } = useContext(StatusContext);

  const CreateGroup = async (name) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const res = await maxios.post(`/api/v1/trips`, {
        name: name,
        description: `Groupe ${name}`,
      });
      console.log(res.data.count);
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showSuccess", payload: `Groupe ${name} Created` });
      setCreateGroup(false);
    } catch (error) {
      setCreateGroup(true);
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };
  return (
    <Box display={"flex"} justifyContent="flex-end" alignItems={"center"}>
      <Button
        variant="contained"
        endIcon={<Add />}
        onClick={() => CreateGroup(name)}
      >
        Create Group
      </Button>
    </Box>
  );
};
