import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";

import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import maxios, { BaseUrl, FilesUrl, MessagesUrl } from "../../../api";
import { StatusContext } from "../../../App";
import { Gifschat } from "../../../assets/MasterData";
import { InputMessage } from "../Chat/MessagesSide";

export default function MsgsDialog({ MultipleMsgs, selected, userStates }) {
  const [notifiedUsers, setNotifiedUser] = React.useState([]);
  const { dispatch } = React.useContext(StatusContext);
  const [value, setValue] = React.useState("");
  const [openGif, setopenGif] = React.useState(false);

  const Handlchage = (e) => {
    setValue(e.target.value);
  };
  const concatData = (userStates, selected) => {
    let res = [];
    selected?.map((id) => {
      if (userStates.find((e) => e._id === id))
        res = [...res, userStates.find((e) => e._id === id)];
    });
    setNotifiedUser([...res]);
  };

  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  React.useEffect(() => {
    if (selected?.length > 0 || userStates?.length > 0)
      concatData(userStates, selected);
    else handleClose();
  }, [selected, userStates]);

  const detatch = (id, fname, lname) => {
    dispatch({
      type: "showDialog",
      payload: {
        title: "Unselecte User",
        message: `User Name: ${fname} ${lname}`,
        type: "negative",
        buttonLabel: "Unselecte",
        callback: () => {
          let res = [...(notifiedUsers.filter((e) => e._id !== id) ?? [])];
          setNotifiedUser([...res]);
          if (res?.length <= 0) handleClose();
        },
      },
    });
  };

  const sendMessage = async () => {
    if (selected?.length > 0 && value !== "")
      try {
        let ids = selected;
        if (userStates?.filter((e) => e.user)?.length > 0) {
          ids = notifiedUsers.map((u) => u.user._id);
        }

        dispatch({ type: "showLoading", payload: true });
        const res = await maxios.post(`${MessagesUrl}/broadcast`, {
          ids: ids,
          message: {
            type: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
              value?.trim()
            )
              ? "link"
              : "text",
            content: value,
          },
        });
        setValue("");
        dispatch({ type: "showLoading", payload: false });
        dispatch({ type: "showSuccess", payload: "Message Sent" });
      } catch (error) {
        dispatch({ type: "showLoading", payload: false });
        dispatch({ type: "showError", payload: "Server Error Please Reload" });
      }
  };

  const sendGif = async (gifNum) => {
    if (selected?.length > 0)
      try {
        let ids = selected;
        if (userStates?.filter((e) => e.user)?.length > 0) {
          ids = notifiedUsers.map((u) => u.user._id);
        }
        dispatch({ type: "showLoading", payload: true });
        const res = await maxios.post(`${MessagesUrl}/broadcast`, {
          ids: ids,
          message: { type: "gif", content: gifNum },
        });
        dispatch({ type: "showLoading", payload: false });
        dispatch({ type: "showSuccess", payload: "Message Sent" });
      } catch (error) {
        dispatch({ type: "showLoading", payload: false });
        dispatch({ type: "showError", payload: "Server Error Please Reload" });
      }
  };
  const sendAudioMessage = async (file) => {
    if (selected?.length > 0)
      try {
        let ids = selected;
        if (userStates?.filter((e) => e.user)?.length > 0) {
          ids = notifiedUsers.map((u) => u.user._id);
        }
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "audio");
        formData.append("ext", "weba");
        formData.append("ids", ids);
        dispatch({ type: "showLoading", payload: true });
        const res = await maxios.post(`${MessagesUrl}/broadcast`, formData);
        dispatch({ type: "showLoading", payload: false });
        dispatch({ type: "showSuccess", payload: "Message Sent" });
      } catch (error) {
        dispatch({ type: "showLoading", payload: false });
        dispatch({ type: "showError", payload: "Server Error Please Reload" });
      }
  };

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      // Do something with the files
      if (acceptedFiles[0]) sendFileMessage(acceptedFiles[0]);
    },
    [notifiedUsers]
  );

  const DND = useDropzone({
    onDrop: onDrop,
    noClick: true,
    multiple: false,
    accept: "application/pdf, image/*, video/*",
  });

  const sendFileMessage = async (file) => {
    if (selected?.length > 0) {
      try {
        let ids = selected;
        if (userStates?.filter((e) => e.user)?.length > 0) {
          ids = notifiedUsers.map((u) => u.user._id);
          console.log(ids, notifiedUsers);
        }
        dispatch({ type: "showLoading", payload: true });
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", file.type);
        formData.append("ids", ids);
        const res = await maxios.post(`${MessagesUrl}/broadcast`, formData);
        // if (send.data) HandleLivechat({ ...Livechat });
        dispatch({ type: "showLoading", payload: false });
      } catch (error) {
        dispatch({ type: "showLoading", payload: false });
        console.log(error);
      }
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth={true}
      sx={classes.Dialog}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <SendComponent
          value={value}
          Handlchage={Handlchage}
          SendMessage={sendMessage}
          setopenGif={setopenGif}
          DND={DND}
          sendAudioMessage={sendAudioMessage}
          notifiedUsers={notifiedUsers}
          detatch={detatch}
        />
        <GifContainer
          setopenGif={setopenGif}
          openGif={openGif}
          sendGif={sendGif}
        />
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={sendMultiple}>Send</Button>
      </DialogActions> */}
    </Dialog>
  );
}

const GifContainer = ({ openGif, sendGif, setopenGif }) => {
  const HandlGif = (GifNum) => {
    sendGif(GifNum);
    setopenGif(false);
  };
  return (
    <Modal
      open={openGif}
      onClose={() => setopenGif(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "12px",
          p: 4,
        }}
      >
        <IconButton
          sx={{ position: "absolute", right: 0, top: 0 }}
          onClick={() => setopenGif(false)}
        >
          <Close />
        </IconButton>
        {Gifschat.map((e, index) => (
          <Box
            component={"img"}
            onClick={() => HandlGif(e.name)}
            src={e.source}
            key={index}
            sx={{
              width: 100,
              height: 100,
              objectFit: "contain",
              cursor: "pointer",
            }}
          />
        ))}
      </Box>
    </Modal>
  );
};

const SelectedUser = ({ notifiedUsers, detatch }) => {
  return (
    <Stack direction={"column"} sx={{ position: "relative" }}>
      <Stack
        direction={"row"}
        sx={{
          p: 4,
          py: 6,
          borderRadius: "12px",
          boxShadow:
            "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
        }}
      >
        <Stack
          direction={"row"}
          spacing={-4}
          sx={{
            px: 4,
          }}
          rowGap={2}
          alignItems="center"
          flexWrap={"wrap"}
        >
          {notifiedUsers.map((user, index) => (
            <Myavatar
              key={index}
              onClick={() => detatch(user._id, user.firstName, user.lastName)}
              imgProps={{ crossOrigin: "anonymous" }}
              src={
                user.photo !== "no-photo"
                  ? `${BaseUrl}${FilesUrl}/${user?.photo ?? user?.user?.photo}`
                  : "no-photo"
              }
            />
          ))}
        </Stack>
      </Stack>
      <Typography
        sx={{
          fontWeight: "bold",
          position: "absolute",
          top: "-10px",
          left: "25px",
          zIndex: 20,
          px: "2px",
          backgroundColor: "#fff",
        }}
      >
        Selected Users :
      </Typography>
    </Stack>
  );
};

const SendComponent = ({
  value,
  Handlchage,
  SendMessage,
  DND,
  setopenGif,
  sendAudioMessage,
  notifiedUsers,
  detatch,
}) => {
  const { getInputProps } = DND;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectedUser notifiedUsers={notifiedUsers} detatch={detatch} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <input {...getInputProps()} />
          <InputMessage
            value={value}
            Handlchage={Handlchage}
            SendMessage={SendMessage}
            setopenGif={setopenGif}
            DND={DND}
            sendAudioMessage={sendAudioMessage}
            noBorder={true}
            isRed={true}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
const classes = {
  Dialog: {
    "&.MuiDialog-root": {
      backdropFilter: "blur(1px)",
    },
    "& .MuiDialog-paper": {
      borderRadius: { xs: "0", sm: "12px" },
    },
  },
  DialogTitle: {
    fontFamily: "Cairo",
    textTransform: "uppercase",
    fontWeight: "600",
    color: "secondary.main",
  },
  animatedAvatar: {
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    transition: "all .2s ease",
    "&:hover": {
      transform: "translateY(10px)",
      border: "1px solid black",
    },
  },
};

const Myavatar = styled(Avatar)(({}) => ({
  boxShadow:
    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
  transition: "all .2s ease",
  width: "60px",
  height: "60px",
  cursor: "pointer",
  "&:hover": {
    transform: "translateY(-20px)",
  },
}));
