import { Close } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import GifBoxIcon from "@mui/icons-material/GifBox";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {
  IconButton,
  Paper,
  Slide,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { BaseUrl, FilesUrl } from "../../../api";
import { Gifschat } from "../../../assets/MasterData";
import useRecorder from "../../../Tools/useRecorder";
import { ChatContext, Nomsges } from "./chat";
import CosAvatar from "./CosAvatar";
import MessageHandler from "./MessageHandler";
const MessagesSide = ({ CloseMsg }) => {
  const [value, setValue] = useState("");
  const [openGif, setopenGif] = useState(false);

  const { sendMessage, sendFileMessage, sendAudioMessage, sendGif } =
    useContext(ChatContext);

  const ref = useRef(null);
  const { Livechat } = useContext(ChatContext);

  const [LiveMessages, setMessages] = useState(Livechat?.message ?? []);
  const Handlchage = (e) => {
    setValue(e.target.value);
  };
  // DragAndDrop

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      if (acceptedFiles[0] && Livechat) sendFileMessage(acceptedFiles[0]);
    },
    [Livechat]
  );
  const DND = useDropzone({
    onDrop: onDrop,
    noClick: true,
    multiple: false,
    accept: "application/pdf, image/*, video/*",
  });
  ///////////////
  const SendMessage = () => {
    if (value === "") return;
    let msgs = LiveMessages ?? [];
    msgs = [...msgs, { msg: value, who: "support" }];
    setValue("");
    sendMessage(value);
    setTimeout(() => {
      ref?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
    });
  };

  useEffect(() => {
    setMessages(Livechat?.message);
    setTimeout(() => {
      ref?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
    });
  }, [Livechat?.message]);

  return Livechat ? (
    <Box sx={{ position: "relative" }}>
      <MessageHead Livechat={Livechat} CloseMsg={CloseMsg} />
      <Messages LiveMessages={LiveMessages} inref={ref} DND={DND} />
      <GifContainer
        setopenGif={setopenGif}
        openGif={openGif}
        sendGif={sendGif}
      />
      <InputMessage
        value={value}
        Handlchage={Handlchage}
        SendMessage={SendMessage}
        setopenGif={setopenGif}
        DND={DND}
        sendAudioMessage={sendAudioMessage}
      />
    </Box>
  ) : (
    <Nomsges />
  );
};

export const GifContainer = ({ openGif, setopenGif, sendGif, middle }) => {
  const containerRef = useRef(null);
  const HandlGif = (GifNum) => {
    sendGif(GifNum);
    setopenGif(false);
  };
  return (
    <Box
      sx={{
        position: "absolute",
        height: 120,
        width: "100%",
        display: "flex",
        borderRadius: 1,
        bottom: "5rem",
        ...(!openGif && { zIndex: -1 }),
        ...(middle && middle),
      }}
      ref={containerRef}
    >
      <Slide
        direction="up"
        in={openGif}
        mountOnEnter
        container={containerRef.current}
      >
        <Paper
          sx={{
            width: "100%",
            boxShadow: "rgba(0, 0, 0, 0.06) 0px -2px 4px 0px",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: 0 }}
            onClick={() => setopenGif(false)}
          >
            <Close />
          </IconButton>
          <Stack
            sx={{ width: "100%", marginTop: "20px", overflow: "auto" }}
            direction="row"
            spacing={2}
          >
            {Gifschat.map((e, index) => (
              <Box
                component={"img"}
                onClick={() => HandlGif(e.name)}
                src={e.source}
                key={index}
                sx={{
                  width: 100,
                  height: 100,
                  objectFit: "contain",
                  cursor: "pointer",
                }}
              />
            ))}
          </Stack>
        </Paper>
      </Slide>
    </Box>
  );
};

const Messages = ({ LiveMessages, inref, DND }) => {
  const { isDragActive, getRootProps, getInputProps } = DND;

  return (
    <div style={{ position: "relative" }} {...getRootProps()}>
      <DropHere isDragActive={isDragActive} />
      <Box sx={classes.StackMsgs}>
        <input {...getInputProps()} />
        <StackMsgs LiveMessages={LiveMessages} inref={inref} />
      </Box>
    </div>
  );
};

const DropHere = ({ isDragActive }) => {
  return (
    <Slide in={isDragActive} direction="up" timeout={{ enter: 50, exit: 100 }}>
      <Box sx={classes.DropShadow}>
        <Box sx={classes.innerDnd}>
          <Typography
            sx={{ color: "#bdbdbd", fontSize: "clamp(1rem, 5vw, 55px)" }}
          >
            DRAG FILE HERE
          </Typography>
        </Box>
      </Box>
    </Slide>
  );
};

const StackMsgs = ({ LiveMessages, inref }) => {
  const { Livechat } = useContext(ChatContext);

  return (
    <>
      <Stack direction="column" spacing={2}>
        {LiveMessages &&
          LiveMessages.map(
            (m, index) =>
              // <Box className={"boxClick"} key={index}>
              m.recieverId !== Livechat._id ? (
                <RightMsg key={index} m={m} />
              ) : (
                <LeftMsg key={index} m={m} />
              )
            // </Box>
          )}
      </Stack>
      <div ref={inref} style={{ height: "1px" }} />
    </>
  );
};
const RightMsg = ({ m }) => {
  const { deleteMsg } = useContext(ChatContext);
  if (!m?.message?.content || m?.message?.content === "") return null;
  return (
    <Stack direction={"row"} spacing={1}>
      <MessageHandler message={m} isRight={true} />
      <IconButton
        sx={{ alignSelf: "flex-start" }}
        color="primary"
        onClick={() => deleteMsg(m._id)}
      >
        <DeleteIcon sx={{ fontSize: "15px" }} />
      </IconButton>
    </Stack>
  );
};
const LeftMsg = ({ m }) => {
  const { deleteMsg } = useContext(ChatContext);

  if (!m?.message?.content || m?.message?.content === "") return null;
  return (
    <Stack direction={"row"} spacing={1}>
      <IconButton
        sx={{ alignSelf: "flex-start", marginLeft: "auto" }}
        color="primary"
        onClick={() => deleteMsg(m._id)}
      >
        <DeleteIcon sx={{ fontSize: "15px" }} />
      </IconButton>
      <MessageHandler message={m} isRight={false} />
    </Stack>
  );
};

const MessageHead = ({ Livechat, CloseMsg }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  return (
    <Box sx={classes.Head}>
      <Stack direction="row" spacing={1} sx={{ paddingLeft: "8px" }}>
        {matches ? (
          <IconButton onClick={CloseMsg}>
            <ChevronLeftRoundedIcon />
          </IconButton>
        ) : (
          false
        )}
        <Box
          sx={classes.user}
          onClick={() => navigate(`/users/${Livechat._id}`)}
        >
          <CosAvatar
            alt="Client"
            isSmall={true}
            avatar={
              Livechat.photo !== "no-photo"
                ? `${BaseUrl}${FilesUrl}/${Livechat.photo}`
                : "no-photo"
            }
            online={Livechat.isOnline ?? false}
            imgProps={{ crossOrigin: "anonymous" }}
          />
        </Box>
      </Stack>
      <Stack
        paddingLeft="1rem"
        direction="column"
        justifyContent="center"
        spacing={0.5}
      >
        <Typography sx={classes.ClientName}>
          {Livechat.firstName} {Livechat.lastName}
        </Typography>
        {/* <Typography sx={classes.ClientMessage}>
          {Livechat.online ? "Online" : "Offline"}
        </Typography> */}
      </Stack>
      {/* <Divider/> */}
    </Box>
  );
};

export const InputMessage = ({
  value,
  Handlchage,
  SendMessage,
  DND,
  setopenGif,
  sendAudioMessage,
  noBorder,
  isRed,
}) => {
  let [
    audioURL,
    audioBlob,
    isRecording,
    startRecording,
    stopRecording,
    deleteRecording,
  ] = useRecorder();
  const EnterSubmit = (e) => {
    const key = e.which ?? 0;
    if (key === 13) SendMessage();
  };
  const { open } = DND;
  return (
    <Stack
      sx={{ ...classes.inputBox, ...(noBorder && { borderTop: "none" }) }}
      direction="row"
      spacing={2}
      justifyContent="space-between"
    >
      <IconButton onClick={open}>
        <AttachFileIcon />
      </IconButton>
      {audioURL ? (
        <Box style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <audio src={audioURL} controls style={{ flexGrow: 1 }} />
          <IconButton
            size="small"
            onClick={() => sendAudioMessage(audioBlob, deleteRecording)}
          >
            <SendRoundedIcon sx={{ color: "#000000a9" }} />
          </IconButton>
          <IconButton size="small" onClick={deleteRecording}>
            <DeleteIcon sx={{ color: "#000000a9" }} />
          </IconButton>
        </Box>
      ) : (
        <TextField
          placeholder="Send message..."
          id="outlined"
          sx={{
            ...classes.SendInput,
            ...(isRed && { backgroundColor: "#ff56563b" }),
          }}
          value={value}
          disabled={isRecording}
          // onKeyPress={EnterSubmit}
          multiline
          onChange={Handlchage}
          InputProps={{
            startAdornment: (
              <IconButton
                size="small"
                sx={{ marginRight: "8px" }}
                onClick={() => setopenGif(true)}
              >
                <GifBoxIcon sx={{ color: "#000000a9" }} />
              </IconButton>
            ),
            endAdornment:
              value !== "" ? (
                <IconButton size="small" onClick={SendMessage}>
                  <SendRoundedIcon sx={{ color: "#000000a9" }} />
                </IconButton>
              ) : isRecording ? (
                <IconButton size="small" onClick={stopRecording}>
                  <MicOffIcon sx={{ color: "#000000a9" }} />
                </IconButton>
              ) : (
                <IconButton size="small" onClick={startRecording}>
                  <MicIcon sx={{ color: "#000000a9" }} />
                </IconButton>
              ),
          }}
        />
      )}
    </Stack>
  );
};

const classes = {
  Head: {
    height: "82px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
  },
  user: {
    // paddingLeft: "2rem",
    cursor: "pointer",
  },
  DropShadow: {
    backgroundColor: "#fafafa",
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1204,
    display: "grid",
    placeItems: "center",
  },
  innerDnd: {
    width: "90%",
    height: "90%",
    outline: "5px dashed #eeeeee",
    display: "grid",
    placeItems: "center",
  },
  ClientName: {
    fontFamily: "'Roboto'",
    fontSize: { xs: "clamp(8px, 4vw, .9rem)", lg: "16px" },
    fontWeight: "600",
    lineHeight: "18px",
    color: "#000000",
  },
  ClientMessage: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: { xs: ".8rem", lg: "12px" },
    lineHeight: "14px",
    display: "flex",
    alignItems: "flex-end",
    color: "#9A9A9A",
    // display:{xs:"none", md:"block"}
  },
  SendInput: {
    width: "100%",
    backgroundColor: "#dcf8c6",
    borderRadius: "12px",
    "& fieldset": { border: "none", top: 0 },
    "& label , input": {
      fontFamily: "'Roboto'",
      fontSize: "16px",
      letterSpacing: "0",
      lineHeight: "auto",
      color: "#000000",
    },
  },
  inputBox: {
    p: "0 20px",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    minHeight: "5rem",
    overflow: "auto",
  },

  StackMsgs: {
    position: "relative",
    height: { xs: "calc(100vh - 276px)", lg: "calc(100vh - 18rem)" },
    m: { xs: "8px", lg: "16px" },
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: { xs: "0", md: "6px" },
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#969ba0a4",
      //   outline: "1px solid slategrey",
    },
  },
};

export default MessagesSide;
