import { Box, Container, Grid, Paper, Slide } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { debounce } from "lodash";
import React, {
  createContext, useCallback, useContext,
  useEffect,
  useState
} from "react";
import { useLocation } from "react-router-dom";
import maxios, { ConvsUrl, MessagesUrl, UsersUrl } from "../../../api";
import { LogginContext, StatusContext } from "../../../App";
import nomsgs from "../../../assets/pics/noMsgs.svg";
import { ClientsSide } from "./ClientsSide";
import MessagesSide from "./MessagesSide";
export const ChatContext = createContext({});
export default function Chat() {
  const [Livechat, setLivechat] = useState(null);
  const [Allusers, setAllusers] = useState([]);
  const [AllConversations, setAllConversations] = useState([]);
  const [SearchConvs, setSearchConvs] = useState(null);
  const [OpenRoom, setOpenRoom] = useState(false);
  const { dispatch } = useContext(StatusContext);
  const { socket, NotifArray, connectedUser } = useContext(LogginContext);
  const [checked, setchecked] = useState(false);
  const OpenMsg = () => {
    setchecked(true);
  };
  const CloseMsg = () => {
    setchecked(false);
  };
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const HandleLivechat = async (user) => {
    // const user = users?.filter((user) => user.id === id)[0] ?? {};
    try {
      dispatch({ type: "showLoading", payload: true });
      const res = await maxios.get(`${MessagesUrl}/${user._id}`);
      setLivechat({
        message: [...res.data.messages],
        photo: user.photo,
        firstName: user.firstName,
        lastName: user.lastName,
        _id: user._id,
        isOnline: user?.isOnline ?? false,
      });
      if (matches) OpenMsg(true);
      dispatch({ type: "showLoading", payload: false });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      console.log(error);
    }
  };
  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      const { user } = state;
      if (user) HandleLivechat(user);
    }
  }, [state]);

  const sendMessage = async (message) => {
    try {
      const data = {
        recieverId: Livechat._id,
        message: {
          content: message,
          type: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
            message?.trim()
          )
            ? "link"
            : "text",
        },
      };
      const send = await maxios.post(MessagesUrl, data);
      if (send.data)
        setLivechat({
          ...Livechat,
          message: [...Livechat.message, send.data.message],
        });
    } catch (error) {
      console.log(error);
    }
  };

  const sendGif = async (gifNum) => {
    try {
      const data = {
        recieverId: Livechat._id,
        message: { content: gifNum, type: "gif" },
      };
      const send = await maxios.post(MessagesUrl, data);
      if (send.data)
        setLivechat({
          ...Livechat,
          message: [...Livechat.message, send.data.message],
        });
    } catch (error) {
      console.log(error);
    }
  };

  const sendFileMessage = async (file) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", file.type);
      formData.append("recieverId", Livechat._id);
      const send = await maxios.post(MessagesUrl, formData);
      if (send.data) HandleLivechat({ ...Livechat });
      dispatch({ type: "showLoading", payload: false });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      console.log(error);
    }
  };
  const sendAudioMessage = async (file, deleteRecording) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "audio");
      formData.append("ext", "weba");
      formData.append("recieverId", Livechat._id);
      const send = await maxios.post(MessagesUrl, formData);
      if (send.data) {
        deleteRecording();
        HandleLivechat({ ...Livechat });
      }
      dispatch({ type: "showLoading", payload: false });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      console.log(error);
    }
  };

  const deleteConv = async (id) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const convs = await maxios.delete(`${ConvsUrl}/${id}`);
      console.log(convs.data.conversations);
      setAllConversations([...AllConversations.filter((e) => e._id !== id)]);
      setLivechat(null);
      dispatch({ type: "showLoading", payload: false });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      console.log(error);
    }
  };

  const deleteMsg = async (id) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const msg = await maxios.delete(`${MessagesUrl}/${id}`);
      const newmsg = Livechat.message.filter((e) => e._id !== id);
      console.log(Livechat);
      setLivechat({
        ...Livechat,
        message: [...newmsg],
      });
      dispatch({ type: "showLoading", payload: false });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      console.log(error);
    }
  };

  const CreateChatRoom = async () => {
    if (Allusers.length === 0) {
      try {
        dispatch({ type: "showLoading", payload: true });
        const fetchusers = await maxios.get(UsersUrl + '?limit=100');
        setAllusers([
          ...fetchusers.data.data.filter((e) => e.role !== "admin"),
        ]);
        dispatch({ type: "showLoading", payload: false });
      } catch (error) {
        dispatch({ type: "showLoading", payload: false });
        console.log(error);
      }
    }
    setOpenRoom(true);
    setSearchConvs(null);
    // if (Allusers.length === 0) setAllusers([...default_all_users]);
  };

  const fetchConvs = async () => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const convs = await maxios.get(ConvsUrl);
      let res = [];
      let lres = [];
      convs?.data?.conversations?.map((conv) => {
        const isOnline = connectedUser.filter(
          (e) => e.userId === conv.clientId._id
        );
        if (isOnline[0])
          res = [
            ...res,
            {
              ...conv,
              isOnline: true,
            },
          ];
        else res = [...res, conv];
      });
      res.map((conv) => {
        const isOnline = NotifArray.filter((e) => e === conv._id);
        if (isOnline[0])
          lres = [
            ...lres,
            {
              ...conv,
              isNew: true,
            },
          ];
        else lres = [...lres, conv];
      });
      lres.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setAllConversations([...lres]);
      dispatch({ type: "showLoading", payload: false });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      console.log(error);
    }
    // if (Allusers.length === 0) setAllusers([...default_all_users]);
  };

  const ConcatOnline = async (connectedUser) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      let res = [];
      AllConversations?.map((conv) => {
        const isOnline = connectedUser.filter(
          (e) => e.userId === conv.clientId._id
        );
        if (isOnline[0])
          res = [
            ...res,
            {
              ...conv,
              isOnline: true,
            },
          ];
        else
          res = [
            ...res,
            {
              ...conv,
              isOnline: false,
            },
          ];
      });
      setAllConversations([...res]);
      dispatch({ type: "showLoading", payload: false });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      console.log(error);
    }
    // if (Allusers.length === 0) setAllusers([...default_all_users]);
  };

  const ConcatNotif = async (connectedUser) => {
    try {
      dispatch({ type: "showLoading", payload: true });
      let res = [];
      let noMsgs = [];
      AllConversations?.map((conv) => {
        const isOnline = connectedUser.filter((e) => e === conv._id);
        if (isOnline[0])
          res = [
            ...res,
            {
              ...conv,
              isNew: true,
            },
          ];
        else
          noMsgs = [
            ...noMsgs,
            {
              ...conv,
              isNew: false,
            },
          ];
      });
      setAllConversations([...res, ...noMsgs]);
      dispatch({ type: "showLoading", payload: false });
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      console.log(error);
    }
  };
  const CloseChoose = () => {
    setOpenRoom(false);
  };

  const SearchHandler = (event) => {
    if (OpenRoom) {
      if (event?.target?.value === "") setSearchConvs(null);
      else {
        const res = Allusers.filter(
          (e) =>
            e?.firstName?.toLowerCase()?.includes(event?.target?.value) ||
            e?.lastName?.toLowerCase()?.includes(event?.target?.value)
        );
        if (res?.length === 0) setSearchConvs([]);
        setSearchConvs([...res]);
      }
    } else {
      if (event?.target?.value === "") setSearchConvs(null);
      else {
        const res = AllConversations.filter(
          (e) =>
            e.clientId.firstName.toLowerCase().includes(event?.target?.value) ||
            e.clientId.lastName.toLowerCase().includes(event?.target?.value)
        );
        setSearchConvs([...res]);
      }
    }
  };
  const debouncedChangeHandler = useCallback(debounce(SearchHandler, 500), [
    AllConversations,
    Allusers,
    OpenRoom,
  ]);

  useEffect(() => {
    socket?.on("newMessage", (data) => {
      if (Livechat)
        setLivechat({
          ...Livechat,
          message: [...Livechat.message, data.message],
        });
    });
  }, [socket, Livechat]);

  useEffect(() => {
    socket?.on("newConversation", (data) => {
      setAllConversations([
        { ...data.conversation, isOnline: true },
        ...AllConversations,
      ]);
    });
  }, [socket, AllConversations]);

  useEffect(() => {
    fetchConvs();
  }, []);

  useEffect(() => {
    ConcatOnline(connectedUser);
  }, [connectedUser]);
  useEffect(() => {
    ConcatNotif(NotifArray);
  }, [NotifArray]);

  const ViewMessage = (id) => {
    socket?.emit("seenConversation", id);
  };

  return (
    <ChatContext.Provider
      value={{
        checked,
        OpenMsg,
        CloseMsg,
        Livechat,
        HandleLivechat,
        setLivechat,
        users,
        CreateChatRoom,
        OpenRoom,
        Allusers,
        AllConversations,
        setAllConversations,
        CloseChoose,
        sendMessage,
        sendFileMessage,
        sendAudioMessage,
        setOpenRoom,
        deleteConv,
        sendGif,
        ViewMessage,
        debouncedChangeHandler,
        SearchConvs,
        deleteMsg,
      }}
    >
      {/* <Button onClick={disconnectSocket}>SendSeen</Button> */}
      {/* <Button onClick={sendRepley}>send</Button> */}
      {/* <p>It's {sdata}</p> */}
      <Container maxWidth="xl" disableGutters>
        {/* {AllConversations && AllConversations.length > 0 ? ( */}
        <IfMsgs />
        {/* ) : ( */}
        {/* <Nomsges /> */}
        {/* )} */}
      </Container>
    </ChatContext.Provider>
  );
}

const IfMsgs = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <Paper sx={classes.Paper}>
      <Grid container>
        {matches ? (
          <Grid item xs={12}>
            <MobileChat
            // users={users}
            // HandleLivechat={HandleLivechat}
            // setLivechat={setLivechat}
            // Livechat={Livechat}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={5} lg={4} sx={classes.ClientGrid}>
              <ClientsSide />
            </Grid>
            <Grid item xs={12} md={7} lg={8} sx={classes.MsgGrid}>
              <MessagesSide />
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};

const MobileChat = ({ users, HandleLivechat, setLivechat, Livechat }) => {
  const { checked, CloseMsg, OpenMsg } = useContext(ChatContext);

  return (
    <>
      <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
        <div>
          <MessagesSide
            // Livechat={Livechat}
            // setLivechat={setLivechat}
            CloseMsg={CloseMsg}
          />
        </div>
      </Slide>
      <Slide direction="right" in={!checked} mountOnEnter unmountOnExit>
        <div>
          <ClientsSide
            // users={users}
            // HandleLivechat={HandleLivechat}
            OpenMsg={OpenMsg}
          />
        </div>
      </Slide>
    </>
  );
};

export const Nomsges = () => {
  return <Box sx={classes.NomsgRoot}></Box>;
};

const classes = {
  NomsgRoot: {
    height: "calc(100vh - 96px)",
    width: "100%",
    background: `url(${nomsgs}) center no-repeat`,
    backgroundSize: "contain",
  },

  Paper: {
    maxHeight: "calc(100vh - 96px)",
    borderRadius: "12px",
    overflow: "hidden",
    boxShadow:
      "rgb(136 165 191 / 48%) 6px 2px 16px 0px, rgb(255 255 255 / 80%) -6px -2px 16px 0px",
  },
  ClientGrid: {
    height: "auto !important",
    minHeight: "calc(100vh - 96px) !important",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  },
  MsgGrid: {
    height: "auto !important",
    minHeight: "calc(100vh - 96px) !important",
    position: "relative",
  },
};
const users = [
  {
    id: 1,
    name: "User username",
    online: true,
    message: [
      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "client",
      },
      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "support",
      },

      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "client",
      },

      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "support",
      },

      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",

        who: "client",
      },
    ],
    sentat: new Date(2007, 0, 29),
    avatar: "https://picsum.photos/id/247/200/300",
  },
  {
    id: 4,
    name: "User username",
    online: true,
    message: [
      {
        msg: "adipisicing elit. Tempore dignissimos magni ratione",
        who: "client",
      },
      {
        msg: "adipisicing elit. Tempore dignissimos magni ratione",
        who: "support",
      },

      {
        msg: "adipisicing elit. Tempore dignissimos magni ratione",
        who: "client",
      },

      {
        msg: "adipisicing elit. Tempore dignissimos magni ratione",
        who: "support",
      },

      {
        msg: "t. Tempore dignissimos magni ratione",
        who: "client",
      },
    ],
    sentat: new Date(2021, 9, 29),
    avatar: "https://picsum.photos/id/239/200/300",
  },
  {
    id: 3,
    name: "User username",
    online: true,
    message: [
      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "client",
      },
      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "support",
      },

      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "client",
      },

      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "support",
      },

      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "client",
      },
    ],
    sentat: new Date(2021, 9, 29),
    avatar: "https://picsum.photos/id/233/200/300",
  },
  {
    id: 5,
    name: "Biggy",
    online: false,
    message: [
      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "client",
      },
      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "support",
      },

      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "client",
      },

      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "support",
      },

      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "client",
      },
    ],
    sentat: new Date(2021, 9, 29),
    avatar: "https://picsum.photos/id/238/200/300",
  },
  {
    id: 6,
    name: "User username",
    online: true,
    message: [
      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "client",
      },
      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "support",
      },

      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "client",
      },

      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "support",
      },

      {
        msg: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore dignissimos magni ratione",
        who: "client",
      },
    ],
    sentat: new Date(2021, 9, 29),
    avatar: "https://picsum.photos/id/237/200/300",
  },
];
