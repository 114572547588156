import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import MenuIcon from "@mui/icons-material/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Divider, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext, useEffect, useState } from "react";
import { LogginContext } from "../../App";
import logo from "../../assets/pics/logo1.svg";
import Main from "../MainBoard";
import Header from "./Header";
import DrawerItems from "./RooteLinks";
export const webDrawerWidth = 250;
export const mobileDrawerWidth = "100vw";

const openedMixin = (theme) => ({
  width: webDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [theme.breakpoints.down("sm")]: {
    width: mobileDrawerWidth,
  },
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.down("sm")]: {
    width: 0,
  },
});
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: webDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));


export const DraweContext = React.createContext({});
export default function AppDrawer() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(true);
  useEffect(() => {
    setOpen(!matches);
  }, [matches]);
  const ToggleOpen = () => {
    setOpen(!open);
  };
  return (
    <DraweContext.Provider value={{ open, ToggleOpen }}>
      <Box sx={{ display: "flex" }}>
        <Header />
        <MyDrawer />
        <Main />
      </Box>
    </DraweContext.Provider>
  );
}

const MyDrawer = () => {
  const { open, ToggleOpen } = useContext(DraweContext);
  const { Logout } = useContext(LogginContext);
  return (
    <Drawer
      variant={"permanent"}
      open={open}
      sx={{
        position: "relative",
        minHeight:"55px !important",
        zIndex: "1103",
        "& .MuiDrawer-paper": {
          flexDirection:"column",
        },
      }}
    >
      <>
      {open ? (
        <LogoContent ToggleOpen={ToggleOpen} />
      ) : (
        <Toolbar sx={{ display: "flex", justifyContent: "center", px: 0 }}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={ToggleOpen}
            sx={{display:{xs:"none",sm:"flex" }}}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      )}
      {open ? false : <Divider />}
      <div style={{overflow:"overlay", overflowX:"hidden"}}>
        <DrawerItems />
      </div>
      </>
      <IconButton sx={classes.offButton} onClick={Logout}>
        <PowerSettingsNewIcon />
        {!open ? false : <Typography sx={classes.logout}>Logout</Typography>}
      </IconButton>
    </Drawer>
  );
};
const LogoContent = ({ ToggleOpen }) => {
  return (
    <Toolbar sx={{ justifyContent:{xs:"space-between", md:"center"},my:"5px" }}>
      <img style={classes.logo} src={logo} alt="logo" />
      {/* <Typography sx={classes.LogoName}>Kal & Rok</Typography> */}
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={ToggleOpen}
        edge="end"
        sx={{ display: { xs: "inline-flex", md: "none" } }}
      >
        <ArrowBackRoundedIcon />
      </IconButton>
    </Toolbar>
  );
};



const classes = {
  offButton: {
    color: "#FFFFFF",
    marginTop:"auto",
    backgroundColor:"primary.main",
    "&:hover":{
    backgroundColor:"primary.main",
    },
    px: "16px",
    borderRadius: 0,
    width: "100%",
    display: "flex",
    justifyContent: "start",
  },
  HeadTitle: {
    fontSize: "1.5rem",
    fontWeight: "500",
    color: "primary.main",
  },
  HeadSubTitle: {
    flexGrow: 1,
    fontSize: "1rem",
    fontWeight: "300",
    textTransform: "capitalize",
    color: "secondary.main",
  },
  LogoName: {
    fontSize: "1rem",
    letterSpacing: "2px",
    color: "#000000",
    fontWeight: 300,
    textTransform: "uppercase",
    display: { xs: "none", md: "block" },
  },
  logo: {
    width: "92px",
  },
  itemLabel: {
    textTransform: "uppercase",
    "& .MuiListItemText-primary": {
      fontWeight: "300",
    },
  },
  activeBorder: { borderRight: "2px solid", borderColor: "primary.main" },
  logout: {
    textTransform: "uppercase",
    fontWeight: "500",
    flexGrow: 1,
  },
};

