import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate, useParams } from "react-router";
import maxios, { BaseUrl, FilesUrl } from "../../api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "row",
  minWidth: 800,
};

export default function BillChecker({ Data }) {
  const [order, setorder] = React.useState(undefined);
  const { cin_id } = useParams();
  const navigate = useNavigate();
  //   const handleOpen = () => setOpen(true);
  React.useEffect(() => {
    if (cin_id && Data?.filter((e) => e._id === cin_id))
      setorder({ ...Data?.filter((e) => e._id === cin_id)[0] });
  }, [cin_id, Data]);

  React.useEffect(() => {
    if (order) fetchImgs(order);
  }, [order]);

  const fetchImgs = async (order) => {
    try {
      console.log(order);
      const res = await maxios.get(
        `/api/v1/auth/getcommandfile/${order.frontId}`,
        { responseType: "arraybuffer" }
      );
      const resb = await maxios.get(
        `/api/v1/auth/getcommandfile/${order.backId}`,
        { responseType: "arraybuffer" }
      );
      const front = document.getElementById("front");
      const back = document.getElementById("back");
      let f = Buffer.from(res.data).toString("base64");
      let b = Buffer.from(resb.data).toString("base64");
      front.src = `data:image/png;base64, ${f}`;
      back.src = `data:image/png;base64, ${b}`;
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  return order ? (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {!order?.frontId || !order.backId ? null : (
          <>
            <Stack direction={"column"} spacing={2} alignItems="center">
              <Typography>Front Face</Typography>
              <img
                id="front"
                // src={`${BaseUrl}/api/v1/auth/getcommandfile/${order.frontId}`}
                crossOrigin="anonymous"
                style={{
                  objectFit: "contain",
                  width: "400px",
                  height: "300px",
                }}
              />
            </Stack>
            <Stack direction={"column"} spacing={2} alignItems="center">
              <Typography>Back Face</Typography>
              <img
                id="back"
                // src={`${BaseUrl}/api/v1/auth/getcommandfile/${order.backId}`}
                crossOrigin="anonymous"
                style={{
                  objectFit: "contain",
                  width: "400px",
                  height: "300px",
                }}
              />
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  ) : null;
}
