import { Box } from "@mui/system";
const NoData = ({bg}) => {
    const classes = {
      NomsgRoot: {
        height: "50vh",
        width: "100%",
        background: `url(${bg}) center no-repeat`,
        backgroundSize: "contain",
      },
    }  
    return <Box sx={classes.NomsgRoot}></Box>;
  };
  export default NoData;