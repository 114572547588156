import { DeleteOutlined } from "@mui/icons-material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { BaseUrl, FilesUrl } from "../../../api";
import { AddChat } from "../../../Tools/IconsFactory";
import { SearchField } from "../../../Tools/StyledComp";
import { ChatContext } from "./chat";
import CosAvatar from "./CosAvatar";
export const ClientsSide = ({ OpenMsg }) => {
  const { OpenRoom } = useContext(ChatContext);

  return (
    <Box
      sx={{
        height: "100vh",
        maxHeight: "calc(100vh - 96px)",
        position: "relative",
      }}
    >
      <HeaderBox />
      <Box sx={classes.ChatsBox}>
        <Slide direction="left" in={OpenRoom} mountOnEnter unmountOnExit>
          <Stack direction="column" justifyContent="center" spacing={0}>
            <NewRoomBox OpenMsg={OpenMsg} />
          </Stack>
        </Slide>
        <Slide direction="right" in={!OpenRoom} mountOnEnter unmountOnExit>
          <Stack direction="column" justifyContent="center" spacing={0}>
            <ChatBox OpenMsg={OpenMsg} />
          </Stack>
        </Slide>
      </Box>
    </Box>
  );
};
const HeaderBox = () => {
  const { CreateChatRoom, OpenRoom, CloseChoose, debouncedChangeHandler } =
    useContext(ChatContext);

  return (
    <Box sx={classes.HeadBox}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <SearchField
          fullWidth
          placeholder="Search"
          sx={{ flexGrow: 1 }}
          onChange={debouncedChangeHandler}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton sx={{ px: 0 }}>
                  <SearchRoundedIcon sx={{ color: "text.primary" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <IconButton
          sx={{ width: "60px", height: "60px", px: 0 }}
          onClick={OpenRoom ? CloseChoose : CreateChatRoom}
          disableRipple
        >
          {OpenRoom ? (
            <ArrowBackIosRoundedIcon />
          ) : (
            <AddChat sx={{ width: "100%", height: "100%" }} />
          )}
        </IconButton>
      </Stack>
    </Box>
  );
};

const NewRoomBox = () => {
  const { Allusers, HandleLivechat, SearchConvs } = useContext(ChatContext);
  if (SearchConvs) {
    return SearchConvs?.map((user, index) => (
      <div key={index}>
        <Paper
          sx={classes.ClientChatCard}
          onClick={() => {
            HandleLivechat(user);
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2} p="12px">
            <CosAvatar
              imgProps={{ crossOrigin: "anonymous" }}
              avatar={
                user.photo !== "no-photo"
                  ? `${BaseUrl}${FilesUrl}/${user.photo}`
                  : "no-photo"
              }
            />
            <Stack direction="column" justifyContent="center" spacing={1}>
              <Typography sx={classes.ClientName} style={{ fontWeight: "400" }}>
                {user.lastName} {user.firstName}
              </Typography>
            </Stack>
          </Stack>
        </Paper>
        <Divider />
      </div>
    ));
  } else {
    return Allusers?.map((user, index) => (
      <div key={index}>
        <Paper
          sx={classes.ClientChatCard}
          onClick={() => {
            HandleLivechat(user);
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2} p="12px">
            <CosAvatar
              imgProps={{ crossOrigin: "anonymous" }}
              avatar={
                user.photo !== "no-photo"
                  ? `${BaseUrl}${FilesUrl}/${user.photo}`
                  : "no-photo"
              }
            />
            <Stack direction="column" justifyContent="center" spacing={1}>
              <Typography sx={classes.ClientName} style={{ fontWeight: "400" }}>
                {user.lastName} {user.firstName}
              </Typography>
            </Stack>
          </Stack>
        </Paper>
        <Divider />
      </div>
    ));
  }
};

const ChatBox = ({ OpenMsg }) => {
  const { AllConversations, HandleLivechat, ViewMessage, SearchConvs } =
    useContext(ChatContext);
  if (SearchConvs)
    return SearchConvs?.map((user, index) => (
      <div key={index}>
        <ClientChatCard
          user={user}
          HandleLivechat={HandleLivechat}
          OpenMsg={OpenMsg}
          ViewMessage={ViewMessage}
        />
        <Divider />
      </div>
    ));
  else
    return AllConversations?.map((user, index) => (
      <div key={index}>
        <ClientChatCard
          user={user}
          HandleLivechat={HandleLivechat}
          OpenMsg={OpenMsg}
          ViewMessage={ViewMessage}
        />
        <Divider />
      </div>
    ));
};

const ClientChatCard = ({ user, HandleLivechat, OpenMsg, ViewMessage }) => {
  // console.log(user.isOnline)
  const StartChatting = (id, convID) => {
    ViewMessage(convID);
    HandleLivechat(id);
    if (OpenMsg) OpenMsg();
  };
  const { deleteConv } = useContext(ChatContext);
  return (
    <Paper sx={classes.ClientChatCard}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ flexGrow: 1 }}
          p="12px"
          onClick={() =>
            StartChatting(
              {
                ...user.clientId,
                isOnline: user.isOnline ?? false,
              },
              user._id
            )
          }
        >
          <CosAvatar
            avatar={
              user?.clientId?.photo !== "no-photo"
                ? `${BaseUrl}${FilesUrl}/${user?.clientId?.photo}`
                : "no-photo"
            }
            online={user.isOnline ?? false}
            imgProps={{ crossOrigin: "anonymous" }}
          />
          <Stack direction="column" justifyContent="center" spacing={1}>
            <Typography sx={classes.ClientName}>
              {user?.clientId?.firstName} {user?.clientId?.lastName}
            </Typography>

            <Typography sx={classes.ClientMessage}>
              {user?.isNew && user?.isNew ? "You Have New Message" : ""}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          spacing={1}
        >
          <IconButton
            color="primary"
            sx={{ zIndex: 999 }}
            onClick={() => deleteConv(user._id)}
          >
            <DeleteOutlined />
          </IconButton>
        </Stack>
      </Stack>
    </Paper>
  );
};

const classes = {
  HeadBox: {
    position: "sticky",
    backgroundColor: "#FFFFFF",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "1.5rem 0 0 0",
    px: 1.5,
    pr: 0,
    paddingBottom: "0 !important",
    paddingTop: "0 !important",
    height: "82px",
    display: "flex",
  },
  Title: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "37px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  ChatsBox: {
    maxHeight: "calc(100vh - 10.5rem)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#969ba0a4",
      //   outline: "1px solid slategrey",
    },
    // p: { xs: "1.5rem", lg: "3rem" },
  },
  search: {
    marginBottom: "2rem",
    width: "100%",
    backgroundColor: "#F3F3F3",
    borderRadius: "14px",
    "& .MuiOutlinedInput-input:focus ~ fieldset": {
      outline: "2px solid #218EA1",
    },
    "& fieldset": { border: "none", borderRadius: "14px", top: 0 },
    "& label , input": {
      fontFamily: "'Cairo'",
      fontSize: "16px",
      letterSpacing: "0",
      lineHeight: "auto",
    },
  },
  ClientChatCard: {
    height: "84px",
    background: "#FFFFFF",
    boxShadow: "none",
    borderRadius: "0",
    transition: "all .2s",
    cursor: "pointer",
    "&:hover": {
      background: "#ebebeb",
    },
  },
  ClientName: {
    fontFamily: "'Roboto'",
    fontSize: { xs: "clamp(8px, 4vw, .9rem)", lg: "16px" },
    fontWeight: "600",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "capitalize",
  },
  ClientMessage: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: { xs: "clamp(8px, 3vw, .8rem)", lg: "12px" },
    lineHeight: "14px",
    display: "flex",
    alignItems: "flex-end",
    color: "primary.main",
  },
  Time: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    display: "flex",
    alignItems: "flex-end",
    textAlign: "right",
    color: "#9A9A9A",
  },
  notSeenMesseges: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "10px",
    lineHeight: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#20AAE2",
    backgroundColor: "#FFFFFF",
    outline: "1px solid",
    outlineColor: "#20AAE2",
    width: "18px",
    height: "18px",
    borderRadius: "50%",
  },
};
